import ActionFooter from "@/AppComponents/ActionFooter";
import { AlertDialogC } from "@/AppComponents/AlertDialogC";
import LoaderCircle from "@/AppComponents/LoaderCircle";

import PaginationComponent from "@/AppComponents/PaginationComponent";

import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { toast } from "@/components/ui/use-toast";
import { deleteCategory, fetchCategories } from "@/utils/CategoryUtils";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Plus, Trash } from "lucide-react";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";


export default function Categories() {
  const [page, setpage] = useState(1);
  const [categories, setCategories] = useState([]);
  const [pageSize, setpagesize] = useState(8);
  const [filter, setFilter] = useState("");
  const {t}=useTranslation()
  const { data: Categories, isLoading } = useQuery({
    queryKey: ["categories", page, pageSize],
    queryFn: () => fetchCategories(page, pageSize),
  });

  useEffect(() => {
    if (Categories?.data) {
      setCategories(Categories?.data?.categories);
    }
  }, [Categories]);

  useEffect(() => {
    if (Array.isArray(categories) && categories.length > 0 && filter !== "") {
      const filteredItems = categories.filter((item) =>
        item?.name.toLowerCase().includes(filter.toLowerCase()),
      );
      setCategories(filteredItems);
    } else if (Categories?.data) {
      setCategories(Categories.data?.categories);
    }
  }, [filter, Categories]);
  const queryClient = useQueryClient();
  const deleteCategoryMutation = useMutation({
    mutationFn: (id) => deleteCategory(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["categories"] });
      toast({ description:t("alert.confirm_title") });
    },
    onError: () => {
      toast({ title:t("alert.err_title") });
      deleteCategoryMutation.reset();
    },
  });
  const columns = [t("Categories.name"), t("Categories.remove")];
  const pages = Math.ceil(Categories?.data?.countCategories / pageSize);
  // if (isLoading || deleteCategoryMutation.isPending) return <LoaderCircle />;
  return (
    <>
      <div dir="" className="flex flex-col  container">
        <div className="flex-1">
          <div className="p-2">
            <Card className="p-2 my-2">
              <div className="flex flex-col space-y-4 ">
                <Input
                  onChange={(e) => setFilter(e.target.value)}
                  className="font-bold "
                  color="none"
                  placeholder={t("Categories.search")}
                />

                <Table>
                  <TableHeader>
                    <TableRow className="text-start">
                      {columns &&
                        columns.map((col, index) => (
                          <TableHead className="text-start">{col}</TableHead>
                        ))}
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {categories &&
                      categories.map((category, i) => (
                        <TableRow key={i}>
                          <TableCell>{category.name}</TableCell>
                          <TableCell>
                            <AlertDialogC
                              action={() =>
                                deleteCategoryMutation.mutate(category.id)
                              }
                            >
                              <Trash size="20" />
                            </AlertDialogC>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
                <PaginationComponent
                  pages={pages}
                  setpage={setpage}
                  currentpage={page}
                />
              </div>
            </Card>
          </div>
        </div>
      </div>
      <ActionFooter
        text={t("Categories.add")}
        icon={<Plus />}
        type="link"
        to={"/dashboard/categories/new"}
      />
    </>
  );
}
