import { Button } from "@/components/ui/button";
import LoaderCircle from "./LoaderCircle";

export default function SaveChangesButton({
  isPending,
  label,
  action,
  btnlabel,
  
}) {
  return (
    <div className=" mb-4 flex items-center justify-between">
      <h1 className="text-2xl font-bold">{label}</h1>
      <Button  onClick={() => action()}>
        {isPending ? <LoaderCircle /> : btnlabel}
      </Button>
    </div>
  );
}
