import LoaderCircle from "@/AppComponents/LoaderCircle";
import { getAllWilayas, getCommunesByWilayaCode } from "algerian-geo";

import {
  AlertDialogFooter,
  AlertDialogHeader,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { ScrollArea } from "@/components/ui/scroll-area";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Separator } from "@/components/ui/separator";
import { toast } from "@/components/ui/use-toast";
import { updateOrder } from "@/utils/OrderUtils";
import { fetchProducts } from "@/utils/ProductUtils";
import { StoreContext } from "@/utils/StoreContext";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { Pen } from "lucide-react";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function EditOrder({ order }) {
  
  const { storeData } = useContext(StoreContext);
  const {t,i18n:{language}}=useTranslation()
  const [Order, setOrder] = useState({
    productId: null,
    wilayaId: null,
    communeId: null,
    unit_price: 0,
    quantity: 0,
    total_price: 0,
    client_name: "",
    client_phone_number: "",
    description: "",
    strict_address: "",
    client_email: "",
    isHome: false,
    shipping_price: 0,
  });
  const { data: products } = useQuery({
    queryKey: ["products"],
    queryFn: () => fetchProducts(1, 40),
  });
  const handleSelectProduct = (value) => {
    setOrder({ ...Order, productId: value.id, unit_price: value.price });
  };
  const recalculatePrice = () => {
    if (storeData?.Shipping != null) {
      let sp;
      if (Order.isHome) {
        sp = storeData?.Shipping?.rates[Order?.wilayaId + 1]?.home;
      } else {
        sp = storeData?.Shipping?.rates[Order?.wilayaId + 1]?.desk;
      }

      const total = Order.quantity * Order.unit_price + sp;
      setOrder({ ...Order, shipping_price: sp, total_price: total });
    }
  };

  useEffect(() => {
    recalculatePrice();
  }, [Order.quantity, Order.isHome, Order.unit_price, Order.wilayaId]);

  useEffect(() => {
    if (order) {
      setOrder({
        productId: order.productId,
        wilayaId: order.wilayaId,
        communeId: order.communeId,
        unit_price: order.unit_price,
        quantity: order.quantity,
        total_price: order.total_price,
        client_name: order.client_name,
        client_phone_number: order.client_phone_number,
        description: order.description,
        strict_address: order.strict_address,
        client_email: order.client_email,
        isHome: order.isHome,
        shipping_price: order.shipping_price,
      });
    }
  }, [order]);
  const [open, setopen] = useState(false);
  const queryClient = useQueryClient();
  const updateOrderMuatation = useMutation({
    mutationFn: () => updateOrder(order.id, Order),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["orders"] });
      toast({ description:t("alert.confirm_title"),variant:"default"});

      setopen(false);
    },
    onError:()=>{
      toast({ description:t("alert.err_title"),variant:"destructive"});

    }
  });

  return (
    <Dialog   onOpenChange={setopen} open={open}>
    <ScrollArea>
      <DialogTrigger asChild>
        <Button size="icon" variant="ghost">
          <Pen width={20} />
        </Button>
      </DialogTrigger>
      <DialogContent  className={`h-[80%] overflow-scroll min-w-[70%] ${language=="ar"?"font-ar":"font-en"}`}>
        <AlertDialogHeader >
          <DialogTitle>{t("editOrder.editOrder")}</DialogTitle>
          <DialogDescription>{t("editOrder.editDescription")}</DialogDescription>
        </AlertDialogHeader>
        <div className="space-y-6">
          <Card className="p-4 space-y-4">
            <Select onValueChange={(value) => handleSelectProduct(value)}>
              <SelectTrigger>
                <SelectValue
                  aria-label="tst"
                  placeholder={order?.product?.name}
                />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectLabel>{t("editOrder.products")}</SelectLabel> 
                  {products &&
                    products?.products?.map((item, index) => (
                      <SelectItem key={item.id} value={item}>
                        {item.name}
                      </SelectItem>
                    ))}
                </SelectGroup>
              </SelectContent>
            </Select>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
              <div>
                <Label htmlFor="quantity" className="mb-2 block">
                  {t("editOrder.quantity")} 
                </Label>
                <Input
                  id="quantity"
                  placeholder={t("editOrder.quantity")}
                  type="number"
                  onChange={(e) =>
                    setOrder({ ...Order, quantity: e.target.valueAsNumber })
                  }
                  value={Order.quantity}
                />
              </div>
              <div>
                <Label htmlFor="price" className="mb-2 block">
                  {t("editOrder.price")} 
                </Label>
                <Input
                  id="price"
                  value={Order.unit_price}
                  onChange={(e) =>
                    setOrder({ ...Order, unit_price: e.target.valueAsNumber })
                  }
                  placeholder={t("editOrder.price")}
                  type="number"
                />
              </div>
              <div>
                <Label htmlFor="note" className="mb-2 block">
                  {t("editOrder.note")} 
                </Label>
                <Input
                  id="note"
                  value={Order.description}
                  onChange={(e) =>
                    setOrder({ ...Order, description: e.target.value })
                  }
                  placeholder={t("editOrder.note")} 
                  type="text"
                />
              </div>
            </div>
          </Card>

          <Card className="p-4 space-y-4">
            <h2 className="text-xl font-semibold">{t("editOrder.customerInfo")}</h2> 
            <Separator />
            <div className="space-y-4">
              <div>
                <Label htmlFor="email" className="mb-2 block">
                  {t("editOrder.email")} 
                </Label>
                <Input
                  id="email"
                  value={Order.client_email}
                  onChange={(e) =>
                    setOrder({ ...order, client_email: e.target.value })
                  }
                  placeholder={t("editOrder.email")} 
                />
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                <div>
                  <Label htmlFor="name" className="mb-2 block">
                    {t("editOrder.name")} 
                  </Label>
                  <Input
                    id="name"
                    value={Order.client_name}
                    onChange={(e) =>
                      setOrder({ ...order, client_name: e.target.value })
                    }
                    placeholder={t("editOrder.name")} 
                  />
                </div>
                <div>
                  <Label htmlFor="phone" className="mb-2 block">
                    {t("editOrder.phoneNumber")} 
                  </Label>
                  <Input
                    id="phone"
                    value={Order.client_phone_number}
                    onChange={(e) =>
                      setOrder({
                        ...order,
                        client_phone_number: e.target.value,
                      })
                    }
                    placeholder={t("editOrder.phoneNumber")}
                  />
                </div>
                <div>
                  <Label htmlFor="state" className="mb-2 block">
                    {t("editOrder.state")} 
                  </Label>
                  <Select
                    onValueChange={(value) =>
                      setOrder({ ...order, wilayaId: parseInt(value) })
                    }
                  >
                    <SelectTrigger id="state">
                      <SelectValue placeholder={order.wilaya.name} />
                    </SelectTrigger>
                    <SelectContent className="h-[15rem]">
                      <SelectGroup>
                        <SelectLabel>{t("editOrder.state")}</SelectLabel> 
                        {getAllWilayas()?.map((wilaya) => (
                          <SelectItem
                            key={wilaya.code}
                            value={wilaya.code.toString()}
                          >
                            {wilaya.code} {wilaya.name}
                          </SelectItem>
                        ))}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </div>
                <div>
                  <Label htmlFor="city" className="mb-2 block">
                    {t("editOrder.city")} 
                  </Label>
                  <Select
                    onValueChange={(value) =>
                      setOrder({ ...Order, communeId: parseInt(value) })
                    }
                  >
                    <SelectTrigger id="city">
                      <SelectValue placeholder={order.commune.name} />
                    </SelectTrigger>
                    <SelectContent className="h-[15rem]">
                      <SelectGroup>
                        <SelectLabel>{t("editOrder.city")}</SelectLabel> 
                        {getCommunesByWilayaCode(order.wilayaId)?.map(
                          (commune) => (
                            <SelectItem
                              key={commune.id}
                              value={commune.id.toString()}
                            >
                              {commune.name}
                            </SelectItem>
                          ),
                        )}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </div>
                <div>
                  <Label htmlFor="address" className="mb-2 block">
                    {t("editOrder.address")} 
                  </Label>
                  <Input
                    id="address"
                    value={Order.strict_address}
                    onChange={(e) =>
                      setOrder({ ...order, strict_address: e.target.value })
                    }
                    placeholder={t("editOrder.address")} 
                  />
                </div>
                <div>
                  <Label htmlFor="shipping" className="mb-2 block">
                    {t("editOrder.shippingPrice")} 
                  </Label>
                  <Input
                    value={Order.shipping_price}
                    id="shipping"
                    disabled
                    type="number"
                    placeholder={t("editOrder.shippingPrice")} 
                  />
                </div>
                <div>
                  <Label htmlFor="total" className="mb-2 block">
                    {t("editOrder.totalPrice")} 
                  </Label>
                  <Input
                    id="total"
                    value={Order.total_price}
                    onChange={(e) =>
                      setOrder({
                        ...order,
                        total_price: parseFloat(e.target.value),
                      })
                    }
                    type="number"
                    placeholder={t("editOrder.totalPrice")} 
                  />
                </div>
              </div>
              <div className="flex items-center space-x-2">
                <Checkbox
                  checked={Order.isHome}
                  onCheckedChange={(checked) =>
                    setOrder({ ...Order, isHome: checked })
                  }
                  id="exact-address"
                />
                <Label htmlFor="exact-address">{t("editOrder.exactAddress")}</Label>
              </div>
            </div>
          </Card>
        </div>
        <DialogFooter>
          <Button onClick={() => updateOrderMuatation.mutate()} type="submit">
            {updateOrderMuatation.isPending ? (
              <LoaderCircle />
            ) : (
              t("editOrder.saveChanges")
            )}
          </Button>
        </DialogFooter>
      </DialogContent>
    </ScrollArea>
  </Dialog>
  );
}
