import React, { useContext, useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import LoaderCircle from "@/AppComponents/LoaderCircle";
import SaveChangesButton from "@/AppComponents/SaveChangesButton";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import { toast } from "@/components/ui/use-toast";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { fetchStore, updateStore } from "@/utils/StoreUtils";
import { StoreContext } from "@/utils/StoreContext";

enum Country {
  DZA = "DZA",
  MAR = "MAR",
  TUN = "TUN",
  EGY = "EGY",
}

enum Store_category {
  CLOTHES = "CLOTHES",
  ELECTRONICS = "ELECTRONICS",
  GROCERIES = "GROCERIES",
  BOOKS = "BOOKS",
  HOME_DECOR = "HOME_DECOR",
  SPORTS = "SPORTS",
  TOYS = "TOYS",
  BEAUTY = "BEAUTY",
  AUTOMOTIVE = "AUTOMOTIVE",
  JEWELRY = "JEWELRY",
}

export default function General() {
  
  const { t } = useTranslation();
  const {storeData:Store,setStore}=useContext(StoreContext)

  const queryClient = useQueryClient();
  const updateStoreMutation = useMutation({
    mutationFn: () => updateStore(Store),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["store"] });
      toast({ description:t("alert.confirm_title"),variant:"default"});

    },
    onError: () => {
      updateStoreMutation.reset();
      toast({ description:t("alert.err_title"),variant:"destructive"});

    },
  });

  const handleImageUpload = async (
    e: React.ChangeEvent<HTMLInputElement>,
    field: string,
  ) => {
    const file = e.target.files?.[0];
    if (file) {
      // Placeholder for image upload logic
      // This function should upload the image and return a URL
      const imageUrl = await uploadImage(file);
      setStore({ ...Store, [field]: imageUrl });
    }
  };

  // Placeholder function for image upload
  const uploadImage = async (file: File): Promise<string> => {
    // Implement your image upload logic here
    // For now, we'll just return a placeholder URL
    return `https://placehold.co/600x400?text=${file.name}`;
  };

  if(!Store) return <LoaderCircle/>
  return (
    <div className="container mx-auto px-4">
      <SaveChangesButton
        btnlabel={t("btns.save")}
        isPending={updateStoreMutation.isPending }
        action={() => updateStoreMutation.mutate()}
        label={"Store Settings"}
      />
      
      <div className="grid gap-6 md:grid-cols-2">
        <div className="grid gap-2">
          <Label htmlFor="store-name">{t("Settings.inside.store.sn")}</Label>
          <Input
            id="store-name"
            onChange={(e) => setStore({ ...Store, name: e.target.value })}
            value={Store?.name}
          />
          
        </div>
        <div className="grid gap-2">
          <Label htmlFor="store-title">{t("Settings.inside.store.st")}</Label>
          <Input
            id="store-title"
            onChange={(e) => setStore({ ...Store, title: e.target.value })}
            value={Store?.title}
          />
        </div>
        <div className="grid gap-2">
          <Label htmlFor="store-currency">
            {t("Settings.inside.store.cr")}
          </Label>
          <Input
            id="store-currency"
            onChange={(e) => setStore({ ...Store, Currency: e.target.value })}
            value={Store?.Currency}
          />
        </div>
        <div className="grid gap-2">
          <Label htmlFor="store-phone">{t("Settings.inside.store.pn")}</Label>
          <Input
            id="store-phone"
            onChange={(e) =>
              setStore({ ...Store, Phone_number: e.target.value })
            }
            value={Store?.Phone_number}
          />
        </div>
        <div className="grid gap-2">
          <Label htmlFor="store-facebook">
            {t("Settings.inside.store.fp")}
          </Label>
          <Input
            id="store-facebook"
            onChange={(e) =>
              setStore({ ...Store, Facebook_link: e.target.value })
            }
            value={Store?.Facebook_link}
          />
        </div>
        <div className="grid gap-2">
          <Label htmlFor="store-instagram">
            {t("Settings.inside.store.i")}
          </Label>
          <Input
            id="store-instagram"
            onChange={(e) =>
              setStore({ ...Store, Instagram_link: e.target.value })
            }
            value={Store?.Instagram_link}
          />
        </div>
        <div className="grid gap-2">
          <Label htmlFor="store-copyright">
            {t("Settings.inside.store.copyright")}
          </Label>
          <Input
            id="store-copyright"
            onChange={(e) => setStore({ ...Store, copyright: e.target.value })}
            value={Store?.copyright}
          />
        </div>
        <div className="grid gap-2">
          <Label htmlFor="store-ad-one">
            {t("Settings.inside.store.adOne")}
          </Label>
          <Input
            id="store-ad-one"
            onChange={(e) => setStore({ ...Store, storeAdOne: e.target.value })}
            value={Store?.storeAdOne}
          />
        </div>
        <div className="grid gap-2">
          <Label htmlFor="store-ad-two">
            {t("Settings.inside.store.adTwo")}
          </Label>
          <Input
            id="store-ad-two"
            onChange={(e) => setStore({ ...Store, storeAdTwo: e.target.value })}
            value={Store?.storeAdTwo}
          />
        </div>
        <div className="grid gap-2">
          <Label htmlFor="store-ad-three">
            {t("Settings.inside.store.adThree")}
          </Label>
          <Input
            id="store-ad-three"
            onChange={(e) =>
              setStore({ ...Store, storeAdThree: e.target.value })
            }
            value={Store?.storeAdThree}
          />
        </div>
        <div className="grid gap-2 md:col-span-2">
          <Label htmlFor="store-return">
            {t("Settings.inside.store.return")}
          </Label>
          <Textarea
            id="store-return"
            onChange={(e) =>
              setStore({ ...Store, return_agreements: e.target.value })
            }
            value={Store?.return_agreements}
          />
        </div>
        <div className="grid gap-2 md:col-span-2">
          <Label htmlFor="store-payment">
            {t("Settings.inside.store.payment")}
          </Label>
          <Textarea
            id="store-payment"
            onChange={(e) =>
              setStore({ ...Store, paying_agreements: e.target.value })
            }
            value={Store?.paying_agreements}
          />
        </div>
        <div className="grid gap-2 md:col-span-2">
          <Label htmlFor="store-thanks">
            {t("Settings.inside.store.thanks")}
          </Label>
          <Textarea
            id="store-thanks"
            onChange={(e) =>
              setStore({ ...Store, thank_you_page: e.target.value })
            }
            value={Store?.thank_you_page}
          />
        </div>
        <div className="grid gap-2">
          <Label htmlFor="store-country">Country</Label>
          <Select
            onValueChange={(value) => setStore({ ...Store, Country: value })}
            defaultValue={Store?.Country}
          >
            <SelectTrigger id="store-country">
              <SelectValue placeholder="Select a country" />
            </SelectTrigger>
            <SelectContent>
              {Object.values(Country).map((country) => (
                <SelectItem key={country} value={country}>
                  {country}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div className="grid gap-2">
          <Label htmlFor="store-category">Store Category</Label>
          <Select
            onValueChange={(value) =>
              setStore({ ...Store, Store_category: value })
            }
            defaultValue={Store?.Store_category}
          >
            <SelectTrigger id="store-category">
              <SelectValue placeholder="Select a store category" />
            </SelectTrigger>
            <SelectContent>
              {Object.values(Store_category).map((category) => (
                <SelectItem key={category} value={category}>
                  {category.replace(/_/g, " ")}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        {["sliderOne", "sliderTwo", "sliderThree", "logo", "icon"].map(
          (field) => (
            <div key={field} className="grid gap-2">
              <Label htmlFor={`store-${field}`}>{field}</Label>
              <div className="flex items-center gap-4">
                <Input
                  id={`store-${field}`}
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleImageUpload(e, field)}
                />
                {Store[field] && (
                  <div className="relative w-16 h-16">
                    <img
                      src={Store[field]}
                      alt={`${field} preview`}
                      className="w-full h-full object-cover"
                    />
                  </div>
                )}
              </div>
            </div>
          ),
        )}
      </div>
    </div>
  );
}
