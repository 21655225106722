import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
  } from "@/components/ui/alert-dialog"
  import { Button } from "@/components/ui/button"
import { useTranslation } from "react-i18next"
  
  export function AlertDialogC({children,action}) {
    const {t,i18n:{language}}=useTranslation()
    return (
      <AlertDialog  >
        <AlertDialogTrigger asChild>
          <Button variant="ghost" >{children}</Button>
        </AlertDialogTrigger>
        <AlertDialogContent className={language=="ar"?'font-ar':'font-en'}>
          <AlertDialogHeader>
            <AlertDialogTitle className="" >{t("alert.title")}</AlertDialogTitle>
            <AlertDialogDescription className="">
            {t("alert.d")}
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter >
            <AlertDialogCancel className="mx-2 ">{t("alert.c")}</AlertDialogCancel>
            <AlertDialogAction className="" onClick={()=>action()} >{t("alert.co")}</AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    )
  }
  