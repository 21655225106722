import LoaderCircle from "@/AppComponents/LoaderCircle";
import {
  AlertDialogFooter,
  AlertDialogHeader,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import { Card, CardContent } from "@/components/ui/card";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { toast } from "@/components/ui/use-toast";
import { cn } from "@/lib/utils";
import { addCoupon, updateCoupon } from "@/utils/CouponUtils";
import { fetchProducts } from "@/utils/ProductUtils";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { format } from "date-fns";
import { CalendarIcon, Pen } from "lucide-react";
import React, { useEffect, useState, useTransition } from "react";
import { useTranslation } from "react-i18next";

export default function EditCoupons({ current }) {
  const [open, setopen] = useState(false);
  const {t}=useTranslation()
  const [coupon, setCoupon] = useState({
    title: null,
    type: "AMOUNT",
    value: null,
    validUntil: null,
    CouponProduct: [],
  });
  const [checkedProducts, setCheckedproducts] = useState([]);
  const [date, setDate] = React.useState<Date | undefined>(new Date());
  useEffect(() => {
    if (date) {
      setCoupon({ ...coupon, validUntil: date });
    }
  }, [date]);
  useEffect(() => {
    setCoupon({ ...coupon, CouponProduct: checkedProducts });
  }, [checkedProducts]);
  useEffect(() => {
    if (current) {
      setCoupon({
        CouponProduct: current.CouponProduct,
        title: current.Title,
        value: current.value,
        type: current.Type,
        validUntil: current.validUntil,
      });
      setDate(current.validUntil);
      setCheckedproducts(current.CouponProduct);
    }
  }, [current]);
  const { data: products, isLoading } = useQuery({
    queryKey: ["products"],
    queryFn: () => fetchProducts(1, 20),
  });
  const queryClient = useQueryClient();
  const updateCouponMutation = useMutation({
    mutationFn: () => updateCoupon(current.id, coupon),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["coupons"] });
      toast({ description:t("alert.confirm_title"),variant:"default"});

      setopen(false);
    },
    onError: (error) => {
      updateCouponMutation.reset();
      toast({ description:t("alert.err_title"),variant:"destructive"});

    },
  });
  return (
    <Dialog open={open} onOpenChange={setopen}>
      <DialogTrigger className="flex">
        <Button variant="ghost" size="icon">
          <Pen size={20} />
        </Button>
      </DialogTrigger>

      <DialogContent>
        <AlertDialogHeader>
          <DialogTitle>Edit Coupon</DialogTitle>
          <DialogDescription>
            Make changes to your Coupon here. Click save when you're done.
          </DialogDescription>
        </AlertDialogHeader>
        <Card className="p-4">
          <CardContent>
            <div className="grid grid-cols-3 gap-3">
              <div className="grid gap-2">
                <Label htmlFor="store-name">Code</Label>
                <Input
                  disabled
                  className="h-14"
                  onChange={(e) =>
                    setCoupon({ ...coupon, title: e.target.value })
                  }
                  value={coupon?.title}
                />
              </div>
              <div className="grid gap-2">
                <Label htmlFor="store-name">Discount Value</Label>
                <Input
                  type="number"
                  className="h-14"
                  onChange={(e) =>
                    setCoupon({ ...coupon, value: e.target.valueAsNumber })
                  }
                  value={coupon?.value}
                />
              </div>
              <div className="grid gap-2">
                <Label htmlFor="store-name">Discount Type</Label>
                <Select
                  disabled={true}
                  onValueChange={(value) =>
                    setCoupon({ ...coupon, type: value })
                  }
                >
                  <SelectTrigger className=" h-14">
                    <SelectValue
                      placeholder={
                        current.Type === "AMOUNT" ? "FIXED" : "PERCENTAGE"
                      }
                    />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectLabel>Type</SelectLabel>
                      <SelectItem value="AMOUNT">FIXED</SelectItem>
                      <SelectItem value="PERCENT">PERCENTAGE</SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
              <div className="pt-8">
                <div className="grid gap-2">
                  <Label htmlFor="store-name">Validity period</Label>
                  <Popover>
                    <PopoverTrigger asChild>
                      <Button
                        variant={"outline"}
                        className={cn(
                          " h-14 justify-start text-left font-normal",
                          !date && "text-muted-foreground",
                        )}
                      >
                        <CalendarIcon className="mr-2 h-4 w-4" />
                        {date ? format(date, "PPP") : <span>Pick a date</span>}
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0" align="start">
                      <Calendar
                        mode="single"
                        selected={date}
                        onSelect={setDate}
                        initialFocus
                      />
                    </PopoverContent>
                  </Popover>
                </div>
              </div>
            </div>
            <div className="mt-5 p-2">select products</div>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button className="h-14 w-full" variant="outline">
                  chose products
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-full">
                <DropdownMenuLabel>Appearance</DropdownMenuLabel>
                <DropdownMenuSeparator />
                {products &&
                  products?.products?.map((product, index) => (
                    <DropdownMenuCheckboxItem
                      checked={checkedProducts.some(
                        (item) => item.productId === product.id,
                      )}
                      onCheckedChange={
                        !checkedProducts.some(
                          (item) => item.productId === product.id,
                        )
                          ? () =>
                            setCheckedproducts((val) => [
                              ...val,
                              { productId: product.id, couponId: current.id },
                            ])
                          : () =>
                            setCheckedproducts(
                              checkedProducts.filter(
                                (item) => item.productId !== product.id,
                              ),
                            )
                      }
                    >
                      {product.name}
                    </DropdownMenuCheckboxItem>
                  ))}
              </DropdownMenuContent>
            </DropdownMenu>
          </CardContent>
        </Card>
        <AlertDialogFooter>
          <Button onClick={() => updateCouponMutation.mutate()}>
            {updateCouponMutation.isPending ? <LoaderCircle /> : "Save changes"}
          </Button>
        </AlertDialogFooter>
      </DialogContent>
    </Dialog>
  );
}
