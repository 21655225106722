import LoaderCircle from "@/AppComponents/LoaderCircle";
import { StoreSideBar } from "@/AppComponents/StoreSideBar";
import { Button } from "@/components/ui/button";
import { StoreContext } from "@/utils/StoreContext";

import { Menu, X } from "lucide-react";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function ThreeTemplate({ children, isLoading, store, styling,selectedSection,setselectedSection }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  return (
    <div dir="rtl" style={{ fontFamily: styling.font }}>
      <div
        onClick={()=>setselectedSection(selectedSection=='store_ad_three'?'':'store_ad_three')}
        style={{ backgroundColor: styling.mainColor }}
        className={`font-bold text-center p-2  ${selectedSection==="store_ad_three"?'border-ring border-4':''} `}>
        {store?.storeAdThree}
      </div>
      <nav onClick={()=>setselectedSection(selectedSection=="nav"?'':"nav")} className={`bg-white h-20 items-center flex shadow-md sticky top-0 z-20 ${selectedSection==="nav"?'border-2 border-black':''}`}>
        <div className="container mx-auto px-4 py-3 flex justify-center w-full lg:justify-between items-center">
          <div className="hidden   right-5 ">
            <Button
              onClick={toggleMenu}
              className=""
              variant="ghost"
              size="icon">
              {isMenuOpen ? (
                <X className="h-4 w-4" />
              ) : (
                <Menu className="h-4 w-4" />
              )}
            </Button>

            <div
              className={`fixed  left-0 w-full bg-background shadow-md transition-transform duration-300 ease-in-out ${
                isMenuOpen ? "translate-y-0" : "-translate-y-full"
              }`}>
              <Button onClick={toggleMenu} size="icon" variant="ghost">
                <X className="h-4 w-4 " />
              </Button>
              <nav className="container ">
                <ul className="space-y-4">
                  <li>
                    <Link to="/" className="text-foreground ">
                      الرئيسية
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact" className="text-foreground ">
                      اتصل بنا
                    </Link>
                  </li>
                  <li>
                    <Link to="/policy" className="text-foreground ">
                      الحقوق و الاحكام
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <Link
            to="/"
            className="flex absolute  items-center justify-center w-[10rem]">
            <img src={store?.logo} alt="" className="w-1/3" />
          </Link>

          <div className="hidden lg:flex font-bold  self-center justify-center flex-1">
            <Link to="/" className="text-gray-700 px-2 hover:text-blue-500">
              الرئيسية
            </Link>
            <Link
              to="/policy"
              className="text-gray-700 px-2 hover:text-blue-500">
              الحقوق و الأحكام
            </Link>

            <Link
              to="/contact"
              className="text-gray-700 px-2 hover:text-blue-500">
              اتصل بنا
            </Link>
          </div>
        </div>
      </nav>
      {isLoading ? <LoaderCircle /> : children}
      <section className="bg-gray-300 mt-4 flex flex-col p-4">
        <div className="px-20 grid text-blue-600 grid-cols-1 md:grid-cols-3">
          <div>
            <h1 className="font-bold text-xl">عن المتجر</h1>
            <div className="flex flex-col space-y-2 p-2">
              <a href="" className="hover:text-blue-600 text-sm">
                من نحن
              </a>
              <a href="" className="hover:text-blue-600 text-sm">
                طرق الدفع
              </a>
              <a href="" className="hover:text-blue-600 text-sm">
                الشحن والتسليم
              </a>
            </div>
          </div>
          <div>
            <h1 className="font-bold text-xl">تواصل معنا</h1>
            <div className="flex flex-col space-y-2 p-2">
              <a href="" className="hover:text-blue-600 text-sm">
                أسئلة شائعة
              </a>
              <a href="" className="hover:text-blue-600 text-sm">
                اتصل بنا
              </a>
              <a href="" className="hover:text-blue-600 text-sm">
                هاتف : 0643434222560
              </a>
            </div>
          </div>
          <div>
            <h1 className="font-bold text-xl">تابعنا على</h1>
            <div className="flex flex-row  p-2">
              <a href="" className="hover:text-blue-600 text-sm">
                f{" "}
              </a>
            </div>
          </div>
          <div className="">
            <h1 className="font-bold text-2xl">keeprify</h1>
            <div className="flex flex-row ">جميع الحقوق محفوظة © 2024</div>
          </div>
        </div>
      </section>
      <footer className="bg-gray-200 flex px-20 justify-between   w-full items-center font-sm  text-center py-2 ">
        <span className="self-start ">
          كل الحقوق محفوظة <span className="text-blue-600">keeprify</span>
        </span>
        <a className="text-blue-600 cursor-pointer hover:text-blue-500">
          keeprify themes
        </a>
      </footer>
    </div>
  );
}
