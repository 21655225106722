import LoaderCircle from "@/AppComponents/LoaderCircle";
import { Button } from "@/components/ui/button";
import { Card, CardTitle } from "@/components/ui/card";
import { Checkbox } from "@/components/ui/checkbox";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { toast } from "@/components/ui/use-toast";
import { fetchStore, updateStore } from "@/utils/StoreUtils";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function Component() {
  const [provinces, setProvinces] = useState({
    isSamePrice: false,
    deskPrice: null,
    doorPrice: null,
    rates: [
      {
        id: 1,
        name: "Adrar",
        desk: null,
        home: null,
        available: true,
      },
      {
        id: 2,
        name: "Chlef",
        desk: null,
        home: null,
        available: true,
      },
      {
        id: 3,
        name: "Laghouat",
        desk: null,
        home: null,
        available: true,
      },
      {
        id: 4,
        name: "Oum El Bouaghi",
        desk: null,
        home: null,
        available: true,
      },
      {
        id: 5,
        name: "Batna",
        desk: null,
        home: null,
        available: true,
      },
      {
        id: 6,
        name: "Béjaïa",
        desk: null,
        home: null,
        available: true,
      },
      {
        id: 7,
        name: "Biskra",
        desk: null,
        home: null,
        available: true,
      },
      {
        id: 8,
        name: "Béchar",
        desk: null,
        home: null,
        available: true,
      },
      {
        id: 9,
        name: "Blida",
        desk: null,
        home: null,
        available: true,
      },
      {
        id: 10,
        name: "Bouira",
        desk: null,
        home: null,
        available: true,
      },
      {
        id: 11,
        name: "Tamanrasset",
        desk: null,
        home: null,
        available: true,
      },
      {
        id: 12,
        name: "Tébessa",
        desk: null,
        home: null,
        available: true,
      },
      {
        id: 13,
        name: "Tlemcen",
        desk: null,
        home: null,
        available: true,
      },
      {
        id: 14,
        name: "Tiaret",
        desk: null,
        home: null,
        available: true,
      },
      {
        id: 15,
        name: "Tizi Ouzou",
        desk: null,
        home: null,
        available: true,
      },
      {
        id: 16,
        name: "Alger",
        desk: null,
        home: null,
        available: true,
      },
      {
        id: 17,
        name: "Djelfa",
        desk: null,
        home: null,
        available: true,
      },
      {
        id: 18,
        name: "Jijel",
        desk: null,
        home: null,
        available: true,
      },
      {
        id: 19,
        name: "Sétif",
        desk: null,
        home: null,
        available: true,
      },
      {
        id: 20,
        name: "Saïda",
        desk: null,
        home: null,
        available: true,
      },
      {
        id: 21,
        name: "Skikda",
        desk: null,
        home: null,
        available: true,
      },
      {
        id: 22,
        name: "Sidi Bel Abbès",
        desk: null,
        home: null,
        available: true,
      },
      {
        id: 23,
        name: "Annaba",
        desk: null,
        home: null,
        available: true,
      },
      {
        id: 24,
        name: "Guelma",
        desk: null,
        home: null,
        available: true,
      },
      {
        id: 25,
        name: "Constantine",
        desk: null,
        home: null,
        available: true,
      },
      {
        id: 26,
        name: "Médéa",
        desk: null,
        home: null,
        available: true,
      },
      {
        id: 27,
        name: "Mostaganem",
        desk: null,
        home: null,
        available: true,
      },
      {
        id: 28,
        name: "M'Sila",
        desk: null,
        home: null,
        available: true,
      },
      {
        id: 29,
        name: "Mascara",
        desk: null,
        home: null,
        available: true,
      },
      {
        id: 30,
        name: "Ouargla",
        desk: null,
        home: null,
        available: true,
      },
      {
        id: 31,
        name: "Oran",
        desk: null,
        home: null,
        available: true,
      },
      {
        id: 32,
        name: "El Bayadh",
        desk: null,
        home: null,
        available: true,
      },
      {
        id: 33,
        name: "Illizi",
        desk: null,
        home: null,
        available: true,
      },
      {
        id: 34,
        name: "Bordj Bou Arreridj",
        desk: null,
        home: null,
        available: true,
      },
      {
        id: 35,
        name: "Boumerdès",
        desk: null,
        home: null,
        available: true,
      },
      {
        id: 36,
        name: "El Tarf",
        desk: null,
        home: null,
        available: true,
      },
      {
        id: 37,
        name: "Tindouf",
        desk: null,
        home: null,
        available: true,
      },
      {
        id: 38,
        name: "Tissemsilt",
        desk: null,
        home: null,
        available: true,
      },
      {
        id: 39,
        name: "El Oued",
        desk: null,
        home: null,
        available: true,
      },
      {
        id: 40,
        name: "Khenchela",
        desk: null,
        home: null,
        available: true,
      },
      {
        id: 41,
        name: "Souk Ahras",
        desk: null,
        home: null,
        available: true,
      },
      {
        id: 42,
        name: "Tipaza",
        desk: null,
        home: null,
        available: true,
      },
      {
        id: 43,
        name: "Mila",
        desk: null,
        home: null,
        available: true,
      },
      {
        id: 44,
        name: "Aïn Defla",
        desk: null,
        home: null,
        available: true,
      },
      {
        id: 45,
        name: "Naâma",
        desk: null,
        home: null,
        available: true,
      },
      {
        id: 46,
        name: "Aïn Témouchent",
        desk: null,
        home: null,
        available: true,
      },
      {
        id: 47,
        name: "Ghardaïa",
        desk: null,
        home: null,
        available: true,
      },
      {
        id: 48,
        name: "Relizane",
        desk: null,
        home: null,
        available: true,
      },
      {
        id: 49,
        name: "Timimoun",
        desk: null,
        home: null,
        available: true,
      },
      {
        id: 50,
        name: "Bordj Badji Mokhtar",
        desk: null,
        home: null,
        available: true,
      },
      {
        id: 51,
        name: "Ouled Djellal",
        desk: null,
        home: null,
        available: true,
      },
      {
        id: 52,
        name: "Béni Abbès",
        desk: null,
        home: null,
        available: true,
      },
      {
        id: 53,
        name: "In Salah",
        desk: null,
        home: null,
        available: true,
      },
      {
        id: 54,
        name: "In Guezzam",
        desk: null,
        home: null,
        available: true,
      },
      {
        id: 55,
        name: "Touggourt",
        desk: null,
        home: null,
        available: true,
      },
      {
        id: 56,
        name: "Djanet",
        desk: null,
        home: null,
        available: true,
      },
      {
        id: 57,
        name: "El Meghaier",
        desk: null,
        home: null,
        available: true,
      },
      {
        id: 58,
        name: "El Menia",
        desk: null,
        home: null,
        available: true,
      },
    ],
  });
  const [storeData, setStoreData] = useState({});
  const [useDefaultPricing, setUseDefaultPricing] = useState(false);
  const queryClient = useQueryClient();
  const {t}=useTranslation()
  const { data: store, isLoading } = useQuery({
    queryKey: ["store"],
    queryFn: () => fetchStore(),
  });

  const updateStoreMutation = useMutation({
    mutationFn: () => updateStore(storeData),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["store"] });
      toast({ title: "Store data updated" });
    },
    onError: () => {
      updateStoreMutation.reset();
      toast({ title: "Error, please try again" });
    },
  });

  useEffect(() => {
    if (store?.data) {
      setStoreData(store.data);
      setProvinces(store.data.Shipping || provinces);
    }
  }, [store]);

  useEffect(() => {
    setStoreData((prevData) => ({ ...prevData, Shipping: provinces }));
  }, [provinces]);

  // if (isLoading) return <LoaderCircle />;

  // I added !isLoading becuase it shows the content beuase the store still didn't load
  if (store?.data?.Country !== "DZA" && !isLoading) {
    return (
      <div className="text-center p-4">
        This page is not available for non-Algerian citizens.
        <br />
        If you are Algerian, please update your country in the settings page.
      </div>
    );
  }

  const handleProvinceChange = (index, field, value) => {
    setProvinces((prev) => {
      const newRates = [...prev.rates];
      newRates[index] = { ...newRates[index], [field]: value };
      return { ...prev, rates: newRates };
    });
  };

  const setDefaultPricing = (deskPrice, doorPrice) => {
    setProvinces((prev) => ({
      ...prev,
      deskPrice,
      doorPrice,
      rates: prev.rates.map((rate) => ({
        ...rate,
        desk: deskPrice,
        home: doorPrice,
      })),
    }));
  };

  return (
    <div className="container ">
      <Card className="p-4 my-4">
        <div className="flex flex-col sm:flex-row justify-between p-4">
          <h1 className="text-2xl mb-4 sm:mb-0">
            {t("Shipping.ss")}
          </h1>
          <Button
            disabled={isLoading}
            onClick={() => updateStoreMutation.mutate()}
          >
            {updateStoreMutation.isPending ? <LoaderCircle /> : t("btns.save")}
          </Button>
        </div>

        <div className="flex items-center space-x-2 mb-4">
          <Checkbox
            id="useDefaultPricing"
            checked={provinces.isSamePrice}
            onCheckedChange={(state) =>
              setProvinces({ ...provinces, isSamePrice: state })
            }
          />
          <Label htmlFor="useDefaultPricing">
          {t("Shipping.oneforall")}

          </Label>
        </div>

        <div
          className={`grid grid-cols-1 sm:grid-cols-2 gap-4 p-4 ${provinces.isSamePrice ? "" : "opacity-50 pointer-events-none"}`}
        >
          <div>
            <Label htmlFor="desk">
          {t("Shipping.d")}(DZD)

            </Label>
            <Input
              id="desk"
              value={provinces.deskPrice || ""}
              onChange={(e) => {
                const value = e.target.valueAsNumber;
                setProvinces((prev) => ({ ...prev, deskPrice: value }));
                if (provinces.isSamePrice)
                  setDefaultPricing(value, provinces.doorPrice);
              }}
              type="number"
              disabled={!provinces.isSamePrice}
            />
          </div>
          <div>
            <Label htmlFor="home">
          {t("Shipping.h")}(DZD)

            </Label>
            <Input
              id="home"
              value={provinces.doorPrice || ""}
              onChange={(e) => {
                const value = e.target.valueAsNumber;
                setProvinces((prev) => ({ ...prev, doorPrice: value }));
                if (provinces.isSamePrice)
                  setDefaultPricing(provinces.deskPrice, value);
              }}
              type="number"
              disabled={!provinces.isSamePrice}
            />
          </div>
        </div>

        <h3 className="text-2xl mb-4 sm:mb-4">
        {t("Shipping.spfew")}

        </h3>
        <div
          className={`overflow-x-auto ${provinces.isSamePrice ? "opacity-50 pointer-events-none" : ""}`}
        >
          <Table className="text-start">
            <TableCaption>
              {t("Shipping.al")}
            .</TableCaption>
            <TableHeader>
              <TableRow>
                <TableHead className="text-start">
          {t("Shipping.i")}

                </TableHead>
                <TableHead className="text-start">
          {t("Shipping.w")}

                </TableHead>
                <TableHead className="text-start">
          {t("Shipping.d")}
                   (DZD)</TableHead>
                <TableHead className="text-start">
          {t("Shipping.h")}
                   (DZD)</TableHead>
                <TableHead className="text-start">
          {t("Shipping.a")}

                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {provinces.rates.map((p, i) => (
                <TableRow key={p.id}>
                  <TableCell>{p.id}</TableCell>
                  <TableCell>{p.name}</TableCell>
                  <TableCell>
                    <Input
                      value={p.desk || ""}
                      onChange={(e) =>
                        handleProvinceChange(i, "desk", e.target.valueAsNumber)
                      }
                      type="number"
                      className="w-full"
                    />
                  </TableCell>
                  <TableCell>
                    <Input
                      value={p.home || ""}
                      onChange={(e) =>
                        handleProvinceChange(i, "home", e.target.valueAsNumber)
                      }
                      type="number"
                      className="w-full"
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      checked={p.available}
                      onCheckedChange={(checked) =>
                        handleProvinceChange(i, "available", checked)
                      }
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </Card>
    </div>
  );
}
