import { useContext, useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import SaveChangesButton from "@/AppComponents/SaveChangesButton";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { toast } from "@/components/ui/use-toast";
import { fetchPixels, updatePixels } from "@/utils/PixelUtils";
import { Card } from "@/components/ui/card";
import hasAccess from "@/utils/access/hasAccess";
import { StoreContext } from "@/utils/StoreContext";

export default function FacebookPixel() {
  const [Pixels, setPixels] = useState({ old_pixels: [], pixels: [] });
  const { t } = useTranslation();
  const { data: fetchedPixels, isLoading } = useQuery({
    queryKey: ["pixels"],
    queryFn: () => fetchPixels(),
  });

  useEffect(() => {
    if (fetchedPixels) {
      setPixels({ old_pixels: fetchedPixels, pixels: [...fetchedPixels] });
    }
  }, [fetchedPixels]);

  const queryClient = useQueryClient();
  const updatePixelMutation = useMutation({
    mutationFn: () => updatePixels(Pixels),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey:["pixels","store"]})
      toast({ description:t("alert.confirm_title"),variant:"default"});

    },
    onError: () => {
      updatePixelMutation.reset();
      toast({ description:t("alert.err_title"),variant:"destructive"});

    },
  });

  // if (isLoading) return <LoaderCircle />;

  const handlePixelChange = (index, value) => {
    setPixels((prevPixels) => ({
      ...prevPixels,
      pixels: prevPixels.pixels.map((pixel, i) =>
        i === index ? value : pixel,
      ),
    }));
  };

  const addNewPixel = () => {
    setPixels((prevPixels) => ({
      ...prevPixels,
      pixels: [...prevPixels.pixels, ""],
    }));
  };

  const removePixel = (index) => {
    setPixels((prevPixels) => ({
      ...prevPixels,
      pixels: prevPixels.pixels.filter((_, i) => i !== index),
    }));
  };
    const { storeData: store } = useContext(StoreContext);
  
  return (
    <div className="container mx-auto px-4 py-8">
  <Card className="p-8">
    <SaveChangesButton
      btnlabel={t("fbpixel.saveButton")}
      isPending={updatePixelMutation.isPending || isLoading}
      action={() => updatePixelMutation.mutate()}
      label={t("fbpixel.saveButtonLabel")}
    />

    <h2 className="text-xl font-bold mt-6 mb-4">{t("fbpixel.heading")}</h2>
    {Pixels.pixels && Pixels.pixels.length > 0 ? (
      Pixels.pixels.map((pixel, idx) => (
        <div key={idx} className="grid gap-6 md:grid-cols-2 mb-4">
          <div className="grid gap-2">
            <Label htmlFor={`pixel-${idx}`}>{t("fbpixel.labelPrefix")} {idx + 1}</Label>
            <div className="flex items-center">
              <Input
                id={`pixel-${idx}`}
                onChange={(e) => handlePixelChange(idx, e.target.value)}
                value={pixel}
                className="flex-grow"
              />
              <Button
                onClick={() => removePixel(idx)}
                className="ml-2"
                variant="destructive"
              >
                {t("fbpixel.removeButton")}
              </Button>
            </div>
          </div>
        </div>
      ))
    ) : (
      <p>{t("fbpixel.noPixelsMessage")}</p>
    )}

    <Button disabled={!hasAccess(store?.user?.subscriptions[0]?.plan,{action:"addPixels",limit:store?._count.Pixels})}
     onClick={addNewPixel} className="mt-4">
      {t("fbpixel.addNewPixelButton")}
    </Button>
  </Card>
</div>

  );
}
