import React, { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import LoaderCircle from "@/AppComponents/LoaderCircle";
import SaveChangesButton from "@/AppComponents/SaveChangesButton";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import { toast } from "@/components/ui/use-toast";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { fetchStore, updateStore, uploadUrl } from "@/utils/StoreUtils";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";

enum Country {
  DZA = "DZA",
  MAR = "MAR",
  TUN = "TUN",
  EGY = "EGY",
}

enum Store_category {
  CLOTHES = "CLOTHES",
  ELECTRONICS = "ELECTRONICS",
  GROCERIES = "GROCERIES",
  BOOKS = "BOOKS",
  HOME_DECOR = "HOME_DECOR",
  SPORTS = "SPORTS",
  TOYS = "TOYS",
  BEAUTY = "BEAUTY",
  AUTOMOTIVE = "AUTOMOTIVE",
  JEWELRY = "JEWELRY",
}

export default function General() {
  const [Store, setStore] = useState({});
  const { t } = useTranslation();
  const [imagesToEdit, setimagesToEdit] = useState([]);
  const { data: store, isLoading } = useQuery({
    queryKey: ["store"],
    queryFn: () => fetchStore(),
  });
  useEffect(() => {
    if (store) {
      setStore(store?.data);
    }
  }, [store]);
  const queryClient = useQueryClient();
  const updateStoreMutation = useMutation({
    mutationFn: () => updateStore(Store, imagesToEdit),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["store"] });
      toast({ description: t("alert.confirm_title"), variant: "default" });
    },
    onError: () => {
      updateStoreMutation.reset();
      toast({ description: t("alert.err_title"), variant: "destructive" });
    },
  });

  const handleImageUpload = async (file, field: string) => {
    if (file) {
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>, field) => {
    if (e.target.files) {
      const newImage = Object.assign(e.target.files?.[0], {
        preview: URL.createObjectURL(e.target.files?.[0]),
        field: field,
      });

      setimagesToEdit(imagesToEdit.filter((image) => image.field !== field));
      setimagesToEdit((val) => [...val, newImage]);
      setStore({ ...Store, [field]: newImage.preview });
    }
  };

  // Placeholder function for image upload
  const uploadImage = async (file: File): Promise<string> => {
    // Implement your image upload logic here
    // For now, we'll just return a placeholder URL
    return `https://placehold.co/600x400?text=${file.name}`;
  };

  // if (isLoading) return <LoaderCircle />;
  return (
    <div className="container mx-auto py-8">
      <Card className="w-full">
        <CardHeader>
          <CardTitle className="text-2xl font-bold">
            {t("Settings.inside.store.title")}
          </CardTitle>
        </CardHeader>
        <CardContent>
          <SaveChangesButton
            btnlabel={t("btns.save")}
            isPending={updateStoreMutation.isPending || isLoading}
            action={() => updateStoreMutation.mutate()}
            label={t("Settings.inside.store.label")}
            className="mb-6"
          />
          <Tabs defaultValue="general" className="w-full">
            <TabsList className="grid w-full grid-cols-4 mb-6">
              <TabsTrigger value="general">
                {t("Settings.inside.store.gn")}
              </TabsTrigger>
              <TabsTrigger value="contact">
                {t("Settings.inside.store.cn")}
              </TabsTrigger>
              <TabsTrigger value="policies">
                {t("Settings.inside.store.policies")}
              </TabsTrigger>
              <TabsTrigger value="media">
                {t("Settings.inside.store.Media")}
              </TabsTrigger>
            </TabsList>
            <TabsContent value="general">
              <div className="grid gap-6 md:grid-cols-2">
                {[
                  {
                    id: "store-name",
                    label: t("Settings.inside.store.sn"),
                    value: Store?.name,
                  },
                  {
                    id: "store-title",
                    label: t("Settings.inside.store.st"),
                    value: Store?.title,
                  },
                  {
                    id: "store-Currency",
                    label: t("Settings.inside.store.cr"),
                    value: Store?.Currency,
                  },
                  {
                    id: "store-copyright",
                    label: t("Settings.inside.store.copyright"),
                    value: Store?.copyright,
                  },
                ].map((field) => (
                  <div key={field.id} className="space-y-2">
                    <Label htmlFor={field.id}>{field.label}</Label>
                    <Input
                      id={field.id}
                      value={field.value}
                      onChange={(e) =>
                        setStore({
                          ...Store,
                          [field.id.replace("store-", "")]: e.target.value,
                        })
                      }
                    />
                  </div>
                ))}
                <div className="space-y-2">
                  <Label htmlFor="store-country">Country</Label>
                  <Select
                    onValueChange={(value) =>
                      setStore({ ...Store, Country: value })
                    }
                    defaultValue={Store?.Country}>
                    <SelectTrigger id="store-country">
                      <SelectValue placeholder="Select a country" />
                    </SelectTrigger>
                    <SelectContent>
                      {Object.values(Country).map((country) => (
                        <SelectItem key={country} value={country}>
                          {country}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
                <div className="space-y-2">
                  <Label htmlFor="store-category">Store Category</Label>
                  <Select
                    onValueChange={(value) =>
                      setStore({ ...Store, Store_category: value })
                    }
                    defaultValue={Store?.Store_category}>
                    <SelectTrigger id="store-category">
                      <SelectValue placeholder="Select a store category" />
                    </SelectTrigger>
                    <SelectContent>
                      {Object.values(Store_category).map((category) => (
                        <SelectItem key={category} value={category}>
                          {category.replace(/_/g, " ")}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              </div>
              <Card className="p-4 mt-5">
                <div className="row flex justify-between">
                  <div className="col-12 col-sm-6 d-flex flex-column">
                    <label
                      
                      htmlFor="basic-url"
                      >
                       main color
                    </label>
                    <div className="flex gap-4 ">
                      <Input
                        placeholder="Enter color code"
                        type="text"
                        className="w-full inline-flex items-center justify-between ssb2 text-sm text-gray-600 bg-white border rounded-lg shadow-sm outline-none focus:ring-offset-2 focus:ring-indigo-600 focus:ring-2  form-control"
                        defaultValue={Store?.primaryColor}
                        onChange={(e)=>setStore({...Store,primaryColor:e.target.value})}
                        
                      />
                      <input
                        type="color"
                        className="w-full inline-flex items-center ssb justify-between  text-sm text-gray-600 bg-white border rounded-lg shadow-sm outline-none focus:ring-offset-2 focus:ring-indigo-600 focus:ring-2  form-control form-control-color"
                        defaultValue={Store?.primaryColor}
                        onChange={(e)=>setStore({...Store,primaryColor:e.target.value})}
                        
                      />
                    </div>
                    <label
                      
                      htmlFor="basic-url"
                      >
                      secondary color
                    </label>
                    <div className="mb-3 flex gap-4">
                      <Input
                        placeholder="Enter color code"
                        type="text"
                        className="w-full inline-flex items-center justify-between ssb2 text-sm text-gray-600 bg-white border rounded-lg shadow-sm outline-none focus:ring-offset-2 focus:ring-indigo-600 focus:ring-2  form-control"
                        value={Store?.secondaryColor}
                        onChange={(e)=>setStore({...Store,secondaryColor:e.target.value})}
                      />
                      <input
                        type="color"
                        className="w-full inline-flex items-center ssb justify-between  text-sm text-gray-600 bg-white border rounded-lg shadow-sm outline-none focus:ring-offset-2 focus:ring-indigo-600 focus:ring-2  form-control form-control-color"
                        defaultValue={Store?.secondaryColor}
                        onChange={(e)=>setStore({...Store,secondaryColor:e.target.value})}
                        
                      />
                    </div>
                  </div>
                  <div className="col-6 d-flex flex-column align-items-center justify-content-center">
                    <svg
                      width={180}
                      height={239}
                      viewBox="0 0 180 239"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <rect
                        x="1.56572"
                        y="1.38158"
                        width="176.869"
                        height="236.237"
                        rx="10.5132"
                        fill="#F3F3F3"
                        stroke="#E9E9E9"
                        strokeWidth="1.23684"
                      />
                      <rect
                        x="3.1052"
                        y="2.1052"
                        width="172.7393"
                        height="6.18421"
                        rx="3.09211"
                        fill={Store?.primaryColor}
                      />
                      <rect
                        x="78.184"
                        y="13.6055"
                        width="24.7369"
                        height="8.6579"
                        rx="4.32895"
                        fill="#C4C4C4"
                      />
                      <rect
                        x="10.842"
                        y="194.948"
                        width="18.7393"
                        height="6.18421"
                        rx="3.09211"
                        fill={Store?.primaryColor}
                      />
                      <rect
                        x="30.6315"
                        y="194.948"
                        width="18.7393"
                        height="6.18421"
                        rx="3.09211"
                        fill={Store?.secondaryColor}
                      />
                      <rect
                        x="64.8421"
                        y="28.3159"
                        width="51.1316"
                        height="2.47369"
                        rx="1.23684"
                        fill={Store?.primaryColor}
                      />
                      <rect
                        x="134.1578"
                        y="177.3159"
                        width="11.1316"
                        height="2.47369"
                        rx="1.23684"
                        fill={Store?.secondaryColor}
                      />
                      <rect
                        x="83.1578"
                        y="177.3159"
                        width="11.1316"
                        height="2.47369"
                        rx="1.23684"
                        fill={Store?.secondaryColor}
                      />
                      <rect
                        x="30.1578"
                        y="177.3159"
                        width="11.1316"
                        height="2.47369"
                        rx="1.23684"
                        fill={Store?.secondaryColor}
                      />
                      <circle
                        cx="161.6767"
                        cy="225.5525"
                        r="2.47369"
                        fill="#C4C4C4"
                      />
                      <circle
                        cx="154.6767"
                        cy="225.5525"
                        r="2.47369"
                        fill="#C4C4C4"
                      />
                      <rect
                        x="10.842"
                        y="35.3948"
                        width="157.079"
                        height="81.6316"
                        rx="4.94737"
                        fill="#C4C4C4"
                        fillOpacity="0.5"
                      />
                      <rect
                        x="80.1052"
                        y="227.105"
                        width="18.5526"
                        height="2.47369"
                        fill="#E9E9E9"
                      />
                      <rect
                        x="11.2368"
                        y="121.974"
                        width={50}
                        height={63}
                        rx="4.94737"
                        fill="#C4C4C4"
                        fillOpacity="0.5"
                      />
                      <rect
                        x="118.2368"
                        y="121.974"
                        width={50}
                        height={63}
                        rx="4.94737"
                        fill="#C4C4C4"
                        fillOpacity="0.5"
                      />
                      <rect
                        x="65.2368"
                        y="121.974"
                        width={50}
                        height={63}
                        rx="4.94737"
                        fill="#C4C4C4"
                        fillOpacity="0.5"
                      />
                      <circle
                        r="1.57416"
                        transform="matrix(1 0 0 -1 97.0837 111.179)"
                        fill="#C4C4C4"
                      />
                      <circle
                        r="1.57416"
                        transform="matrix(1 0 0 -1 92.3612 111.179)"
                        fill="#C4C4C4"
                      />
                      <circle
                        r="1.57416"
                        transform="matrix(1 0 0 -1 87.6387 111.179)"
                        fill="#ffffff"
                      />
                      <circle
                        r="1.57416"
                        transform="matrix(1 0 0 -1 82.9162 111.179)"
                        fill="#C4C4C4"
                      />
                    </svg>
                  </div>
                </div>
              </Card>
            </TabsContent>
            <TabsContent value="contact">
              <div className="grid gap-6 md:grid-cols-2">
                {[
                  {
                    id: "store-Phone_number",
                    label: t("Settings.inside.store.pn"),
                    value: Store?.Phone_number,
                  },
                  {
                    id: "store-Facebook_link",
                    label: t("Settings.inside.store.fp"),
                    value: Store?.Facebook_link,
                  },
                  {
                    id: "store-Instagram_link",
                    label: t("Settings.inside.store.i"),
                    value: Store?.Instagram_link,
                  },
                ].map((field) => (
                  <div key={field.id} className="space-y-2">
                    <Label htmlFor={field.id}>{field.label}</Label>
                    <Input
                      id={field.id}
                      value={field.value}
                      onChange={(e) =>
                        setStore({
                          ...Store,
                          [field.id.replace("store-", "")]: e.target.value,
                        })
                      }
                    />
                  </div>
                ))}
              </div>
            </TabsContent>
            <TabsContent value="policies">
              <div className="grid gap-6">
                {[
                  {
                    id: "store-return_agreements",
                    label: t("Settings.inside.store.return"),
                    value: Store?.return_agreements,
                  },
                  {
                    id: "store-paying_agreements",
                    label: t("Settings.inside.store.payment"),
                    value: Store?.paying_agreements,
                  },
                  {
                    id: "store-thank_you_page",
                    label: t("Settings.inside.store.thanks"),
                    value: Store?.thank_you_page,
                  },
                ].map((field) => (
                  <div key={field.id} className="space-y-2">
                    <Label htmlFor={field.id}>{field.label}</Label>
                    <Textarea
                      id={field.id}
                      value={field.value}
                      onChange={(e) =>
                        setStore({
                          ...Store,
                          [field.id.replace("store-", "")]: e.target.value,
                        })
                      }
                    />
                  </div>
                ))}
              </div>
            </TabsContent>
            <TabsContent value="media">
              <div className="grid gap-6 md:grid-cols-2">
                {[
                  {
                    id: "store-storeAdOne",
                    label: t("Settings.inside.store.adOne"),
                    value: Store?.storeAdOne,
                  },
                  {
                    id: "store-storeAdTwo",
                    label: t("Settings.inside.store.adTwo"),
                    value: Store?.storeAdTwo,
                  },
                  {
                    id: "store-storeAdThree",
                    label: t("Settings.inside.store.adThree"),
                    value: Store?.storeAdThree,
                  },
                ].map((field) => (
                  <div key={field.id} className="space-y-2">
                    <Label htmlFor={field.id}>{field.label}</Label>
                    <Input
                      id={field.id}
                      value={field.value}
                      onChange={(e) =>
                        setStore({
                          ...Store,
                          [field.id.replace("store-", "")]: e.target.value,
                        })
                      }
                    />
                  </div>
                ))}
                {[
                  {
                    field: "sliderOne",
                    label: t("Settings.inside.store.slider1"),
                  },
                  {
                    field: "sliderTwo",
                    label: t("Settings.inside.store.slider2"),
                  },
                  {
                    field: "sliderThree",
                    label: t("Settings.inside.store.slider3"),
                  },
                  { field: "logo", label: t("Settings.inside.store.logo") },
                  { field: "icon", label: t("Settings.inside.store.icon") },
                ].map((field, index) => (
                  <div key={index} className="space-y-2">
                    <Label htmlFor={`store-${field.field}`}>
                      {field.label}
                    </Label>
                    <div className="flex items-center gap-4">
                      <div className="flex-1">
                        <label
                          htmlFor={`store-${field.field}`}
                          className="cursor-pointer">
                          <div className="flex items-center justify-center w-full h-32 px-4 transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none hover:border-gray-400 focus:outline-none">
                            <span className="flex items-center space-x-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-6 h-6 text-gray-600"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth="2">
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                                />
                              </svg>
                              <span className="font-medium text-gray-600">
                                {Store[field.field]
                                  ? t("btns.RE")
                                  : t("btns.up")}{" "}
                                {field.label}
                              </span>
                            </span>
                          </div>
                          <Input
                            id={`store-${field.field}`}
                            type="file"
                            className="hidden"
                            accept="image/*"
                            onChange={(e) => handleFileChange(e, field.field)}
                          />
                        </label>
                      </div>
                      {Store[field.field] && (
                        <div className="relative w-32 h-32">
                          <img
                            src={Store[field.field]}
                            alt={`${field.label} preview`}
                            className="w-full h-full object-contain rounded-md"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </TabsContent>
          </Tabs>
        </CardContent>
      </Card>
    </div>
  );
}
