import i18n from "i18next";
import { initReactI18next, useTranslation,  } from "react-i18next";
import enJSON from '../locale/en.json'
import arJSON from '../locale/ar.json'
import frJSON from '../locale/fr.json'
import cnJSON from '../locale/cn.json'


const init = localStorage.getItem("language")
i18n.use(initReactI18next).init({
 resources: {
  en:{...enJSON},
  ar:{...arJSON},
  fr:{...frJSON},
  cn:{...cnJSON}

 }, // Where we're gonna put translations' files
 lng: init?init:"en",     // Set the initial language of the App
});