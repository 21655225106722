// Define the structure for subscription permissions
type SubscriptionPermissions = {
    allowedPages: string[];
    allowedFeatures: string[];
    actionLimits: Record<string, number>;
  };
  
  // Permissions configuration object
  const subscriptionPermissions: Record<string, SubscriptionPermissions> = {
    FREE: {
      allowedPages: ["home", "orders","customers","products","coupons","store","settings","shipping","plugins","payment"],
      allowedFeatures: [],
      actionLimits: {
        addProducts: 2,
        addOrders: Infinity,
      },
    },
    BASIC: {
      allowedPages: ["home", "orders","customers","products","coupons","store","settings","shipping","plugins","payment"],
      allowedFeatures: [],
      actionLimits: {
        addProducts: 50,
        addMedia:1000,
        addPixels:2,
        addDomains:1,
        Themes:2,
        addOrders: Infinity,
      },
    },
    PREMIUM: {
      allowedPages: ["home", "orders","customers","products","coupons","store","settings","shipping","plugins","payment"],
      allowedFeatures: [],
      actionLimits: {
        addProducts: 2,
        addOrders: Infinity,
      },
    },
    ENTREPRISE: {
      allowedPages: ["home", "orders","customers","products","coupons","store","settings","shipping","plugins","payment"],
      allowedFeatures: [
        "fakeOrders"
      ],
      actionLimits: {
        addItem: Infinity,
        uploadFiles: Infinity,
      },
    },
  };
  
  // Define the options object type
  type HasAccessOptions = {
    page?: string;
    feature?: string;
    action?: string;
    limit?: number;
  };
  
  /**
   * Function to check if a user has access to a page, feature, or action limit.
   * @param subscription - The user's subscription tier.
   * @param options - Access requirements.
   * @returns boolean | number - Returns true/false for page/feature checks, or the allowed action limit.
   */
  export default function hasAccess(
    subscription: string,
    options: HasAccessOptions = {}
  ): boolean | number {
    const { page, feature, action, limit } = options;
  
    const permissions = subscriptionPermissions[subscription];
    if (!permissions) return true; // Invalid subscription
  
    // Check page access
    if (page && !permissions.allowedPages.includes(page)) return true;
  
    // Check feature access
    if (feature && !permissions.allowedFeatures.includes(feature)) return true;
  
    // Check action limits
    if (action) {
      const allowedLimit = permissions.actionLimits?.[action];
      if (allowedLimit === undefined) return true; // Action not allowed
  
      // If a limit is provided, ensure it does not exceed the allowed limit
      if (limit !== undefined && (limit > allowedLimit || limit == allowedLimit)) return true;
  
      return allowedLimit; // Return the allowed limit if no limit is provided
    }
  
    return true;
  }
  