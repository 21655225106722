import React from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Eye } from "lucide-react";
import { Badge } from "@/components/ui/badge";
import { useTranslation } from "react-i18next";

interface Order {
  id: string;
  client_name: string;
  client_phone_number: string;
  product: { name: string };
  total_price: number;
  order_ai_review: string;
  status: string;
  sale_date: string;
  wilaya: { name: string };
  commune: { name: string };
  extras?: Record<string, any>;
}

interface OrderDetailsDialogProps {
  order: Order;
}

const useOrderDetails = (order: Order) => {
  const { t } = useTranslation();
  console.log(order);
  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString();
  };

  const getStatusTranslation = (status: string) => {
    return t(`Orders.inside.status.${status}`);
  };

  const getExtraLabel = (key: string) => {
    switch (key) {
      case "home":
        return t("orderDetails.home_delivery");
      case "net_price":
        return t("orderDetails.net_price");
      case "shippig_price":
        return t("orderDetails.delivery_price");
      case "coupong_used":
        return t("orderDetails.coupong_used");
      default:
        return key;
    }
  };

  const getExtraValue = (value: any) => {
    if (typeof value === "boolean") {
      return value ? t("btns.yes") : t("btns.no");
    }
    return value;
  };

  return { formatDate, getStatusTranslation, getExtraLabel, getExtraValue };
};

const DetailRow: React.FC<{ label: string; value: React.ReactNode }> = ({
  label,
  value,
}) => (
  <TableRow>
    <TableHead className="font-medium">{label}</TableHead>
    <TableCell>{value}</TableCell>
  </TableRow>
);

export function OrderDetailsDialog({ order }: OrderDetailsDialogProps) {
  const { t } = useTranslation();
  const { formatDate, getStatusTranslation, getExtraLabel, getExtraValue } =
    useOrderDetails(order);

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="outline" className="gap-2">
          {t("orderDetails.details")}
          <Eye className="h-4 w-4" />
        </Button>
      </DialogTrigger>
      <DialogContent className="overflow-y-scroll">
        <div>
          <Table className="">
            <TableBody className="flex-col justify-start flex">
              <DetailRow
                label={t("orderDetails.phoneNumber")}
                value={order.client_phone_number}
              />
              <DetailRow
                label={t("orderDetails.product")}
                value={order?.product?.name}
              />
              <DetailRow
                label={t("orderDetails.totalPrice")}
                value={order.total_price}
              />
              <DetailRow
                label={t("orderDetails.aiReview")}
                value={<Badge>{order.order_ai_review}</Badge>}
              />
              <DetailRow
                label={t("orderDetails.status")}
                value={<Badge>{getStatusTranslation(order.status)}</Badge>}
              />
              <DetailRow
                label={t("orderDetails.saleDate")}
                value={formatDate(order.sale_date)}
              />
              <DetailRow
                label={t("orderDetails.wilaya")}
                value={order.wilaya.name}
              />
              <DetailRow
                label={t("orderDetails.commune")}
                value={order.commune.name}
              />

              {order.extras && Object.keys(order?.extras).length > 0 && (
                <>
                  {Object.entries(order?.extras)?.map(([key, value]) => (
                    <>
                      {(key == "options" ) ? (
                      <>
                        {value?.option&&<>
                          <TableRow className="bg-primary">
                            <TableHead className="font-medium ">
                              {value.option}
                            </TableHead>
                            <TableCell>{value.price}</TableCell>
                            

                          </TableRow>
                        </>}
                      </>
                      ) : key == "attributes" ? (
                        <>
                          {value?.map((item, ia) => (
                            <TableRow>
                              <TableHead className="font-medium">
                                {item.option}
                              </TableHead>
                              <TableCell>{item.value}</TableCell>
                              {item?.price&&<TableCell>
                                
                                <Badge>{+item.price}</Badge>
                                </TableCell>}

                            </TableRow>
                          ))}
                        </>
                      ) : (
                        <DetailRow
                          key={key}
                          label={getExtraLabel(key)}
                          value={getExtraValue(value) || "-"}
                        />
                      )}
                    </>
                  ))}
                </>
              )}
            </TableBody>
          </Table>
        </div>
      </DialogContent>
    </Dialog>
  );
}
