import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ShieldCheck, Plus, Trash, RefreshCw } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { toast } from "@/components/ui/use-toast";
import { StoreContext } from "@/utils/StoreContext";
import { updateStore } from "@/utils/StoreUtils";
import LoaderCircle from "@/AppComponents/LoaderCircle";
import Axios from "@/utils/Axios";

export default function FakeOrders() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [allowedOrders, setAllowedOrders] = useState();
  const [newBlockedIP, setNewBlockedIP] = useState("");
  const { storeData: store } = useContext(StoreContext);
  const [BlockedIPs, setBlockedIPs] = useState();

  const updateAllowedOrdersMutation = useMutation({
    mutationFn: () => updateStore({ orders_per_day: allowedOrders }),
    onSuccess: () => {
      toast({ description:t("alert.confirm_title"),variant:"default"});

      queryClient.invalidateQueries({queryKey:["store"]});
    },
    onError: () => {
      toast({ description:t("alert.err_title"),variant:"destructive"});

      updateAllowedOrdersMutation.reset()
    },
  });

  const addBlockedIPMutation = useMutation({
    mutationFn: () => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await Axios.post("api/ip/restrict", {
            value: newBlockedIP,
          });
          if (res.status === 200) {
            resolve(res.data);
          } else throw new Error(res.data.error);
        } catch (e) {
          reject(e);
        }
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["store"]);
      setNewBlockedIP("");
      toast({ description: "IP blocked successfully" });
    },
    onError: () => {
      toast({ description: "Failed to block IP", variant: "destructive" });
    },
  });

  const removeBlockedIPMutation = useMutation({
    mutationFn: (id) => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await Axios.delete(`api/ip/unrestrict/${id}`);
          if (res.status === 200) {
            resolve(res.data);
          } else throw new Error(res.data.error);
        } catch (e) {
          reject(e);
        }
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["store"]);
      toast({ description: "IP unrestricted successfully" });
    },
    onError: () => {
      queryClient.invalidateQueries(["store"]);
      toast({ description: "Failed to unrestrict IP", variant: "destructive" });
    },
  });

  const handleSaveAllowedOrders = () => {
    updateAllowedOrdersMutation.mutate(allowedOrders);
  };

  const handleAddBlockedIP = (e) => {
    e.preventDefault();
    if (validateIP(newBlockedIP)) {
      addBlockedIPMutation.mutate(newBlockedIP);
    } else {
      toast({
        description: "Invalid IP address format",
        variant: "destructive",
      });
    }
  };

  const validateIP = (ip) => {
    const ipRegex =
      /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    return ipRegex.test(ip);
  };

  useEffect(() => {
    if (store) {
      setAllowedOrders(store?.orders_per_day);
      setBlockedIPs(store?.restricted_ips);
      console.log("store is: ", store);
    }
  }, [store]);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  return (
    <motion.div
  className="container mx-auto px-4 py-8 max-w-4xl"
  variants={containerVariants}
  initial="hidden"
  animate="visible"
>
  <motion.h1
    className="text-3xl font-bold mb-8 flex items-center justify-center"
    variants={itemVariants}
  >
    <ShieldCheck className="mr-2 h-8 w-8 text-primary" />
    {t("fakeorders.pageTitle")}
  </motion.h1>

  <motion.div variants={itemVariants}>
    <Card className="mb-8">
      <CardHeader>
        <CardTitle>{t("fakeorders.allowedOrdersTitle")}</CardTitle>
        <CardDescription>
          {t("fakeorders.allowedOrdersDescription")}
        </CardDescription>
      </CardHeader>
      <CardContent>
        <div className="flex items-center space-x-2">
          <Label htmlFor="allowedOrders" className="flex-shrink-0">
            {t("fakeorders.maxOrdersLabel")}
          </Label>
          <Input
            id="allowedOrders"
            type="number"
            value={allowedOrders}
            onChange={(e) => setAllowedOrders(e.target.value)}
            className="max-w-[100px]"
            min="1"
          />
          <span className="text-sm text-muted-foreground">
            {t("fakeorders.perDayText")}
          </span>
          <Button
            onClick={handleSaveAllowedOrders}
            disabled={updateAllowedOrdersMutation.isPending}
          >
            {updateAllowedOrdersMutation.isPending ? (
              <LoaderCircle />
            ) : (
              t("fakeorders.saveChangesButton")
            )}
          </Button>
        </div>
      </CardContent>
    </Card>
  </motion.div>

  <motion.div variants={itemVariants}>
    <Card>
      <CardHeader>
        <CardTitle>{t("fakeorders.blockedIPTitle")}</CardTitle>
        <CardDescription>
          {t("fakeorders.blockedIPDescription")}
        </CardDescription>
      </CardHeader>
      <CardContent>
        <form onSubmit={handleAddBlockedIP} className="flex space-x-2 mb-4">
          <Input
            placeholder={t("fakeorders.ipPlaceholder")}
            value={newBlockedIP}
            onChange={(e) => setNewBlockedIP(e.target.value)}
          />
          <Button type="submit" disabled={addBlockedIPMutation.isPending}>
            <Plus className="h-4 w-4 mr-2" />
            {addBlockedIPMutation.isPending
              ? t("fakeorders.addingText")
              : t("fakeorders.addButton")}
          </Button>
        </form>
        <div className="rounded-md border">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>{t("fakeorders.ipAddressHeader")}</TableHead>
                <TableHead className="text-right">
                  {t("fakeorders.actionsHeader")}
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {!store ? (
                <TableRow>
                  <TableCell colSpan={2} className="text-center">
                    <RefreshCw className="h-4 w-4 animate-spin mx-auto" />
                  </TableCell>
                </TableRow>
              ) : BlockedIPs && BlockedIPs.length > 0 ? (
                BlockedIPs.map((ip) => (
                  <TableRow key={ip.id}>
                    <TableCell>{ip.value}</TableCell>
                    <TableCell className="text-right">
                      <Button
                        variant="ghost"
                        size="sm"
                        onClick={() =>
                          removeBlockedIPMutation.mutate(ip.id)
                        }
                        disabled={removeBlockedIPMutation.isPending}
                      >
                        <Trash className="h-4 w-4" />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={2} className="text-center">
                    {t("fakeorders.noBlockedIPs")}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </CardContent>
    </Card>
  </motion.div>
</motion.div>

  );
}
