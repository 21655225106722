import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import yalindine from "/yalidine.jpg";
import noest from "/noest-logo.png";
import guepex from "/guepex-logo.png";
import ecotrack from "/ecotrack-logo.jpg";
import procolis from "/procolis-logo.png";
import maystro from "/maystro-logo.png";
import elogista from "/elogistia-logo.jpg";

type DeliveryCompany = {
  name: string;
  imageUrl: string;
  url: string;
};

const deliveryCompanies: DeliveryCompany[] = [
  { name: "Yalidine express", imageUrl: yalindine, url: "yalidine" },
  { name: "Noest", imageUrl: noest, url: "noest" },
  { name: "GuepEx", imageUrl: guepex, url: "guepex" },
  { name: "Ecotrack", imageUrl: ecotrack, url: "ecotrack" },
  { name: "ProColis", imageUrl: procolis, url: "procolis" },
  { name: "Maystro Delivery", imageUrl: maystro, url: "maystro" },
  { name: "Elogistia", imageUrl: elogista, url: "elogistia" },
];

export default function Component() {
  const { t } = useTranslation();

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-8 text-center">
        {t("Settings.inside.delivery.label")}
      </h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
        {deliveryCompanies.map((company) => (
          <Card
            key={company.name}
            className="flex flex-col overflow-hidden transition-all duration-300 hover:shadow-lg hover:-translate-y-1"
          >
            <CardHeader className="p-4">
              <CardTitle className="text-lg font-semibold truncate">
                {company.name}
              </CardTitle>
            </CardHeader>
            <CardContent className="p-0 flex-grow">
              <div className="aspect-video relative overflow-hidden">
                <img
                  src={company.imageUrl}
                  alt={`${company.name} logo`}
                  className="w-full h-full object-cover transition-transform duration-300 hover:scale-105"
                />
              </div>
            </CardContent>
            <CardFooter className="p-4">
              <Button asChild className="w-full">
                <Link to={`/dashboard/plugins/delivery/${company.url}`}>
                  {t("Settings.inside.delivery.sas")}
                </Link>
              </Button>
            </CardFooter>
          </Card>
        ))}
      </div>
    </div>
  );
}
