import React, { useState } from "react";
import { motion } from "framer-motion";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { toast, useToast } from "@/components/ui/use-toast";
import { Mail, Phone, Send, MessageCircle, MapPin, Clock } from "lucide-react";
import { useMutation } from "@tanstack/react-query";
import Axios from "@/utils/Axios";

export default function ContactPage() {
  const [message, setMessage] = useState({
    Subject: "",
    email: "",
    Content: "",
  });
  const { toast } = useToast();

  const sendMessageMutation = useMutation({
    mutationFn: (data) => Axios.post("/api/contact/message", data),
    onSuccess: () => {
      setMessage({
        Subject: "",
        email: "",
        Content: "",
      });
      toast({ title: "Message Sent" });
    },
    onError: () => {
      sendMessageMutation.reset();
      toast({ title: "Error sending message" });
    },
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    sendMessageMutation.mutate(message);
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  return (
    <motion.div
      className="container mx-auto px-4 py-8 max-w-4xl"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <motion.h1
        className="text-4xl font-bold text-center mb-8"
        variants={itemVariants}
      >
        Contact Keeprify
      </motion.h1>

      <motion.p className="text-center text-lg mb-12" variants={itemVariants}>
        We're here to help! Reach out to us through any of the following
        channels.
      </motion.p>

      <motion.div
        className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12"
        variants={containerVariants}
      >
        <motion.div variants={itemVariants}>
          <Card className="h-full">
            <CardHeader>
              <CardTitle className="flex items-center">
                <Mail className="mr-2" />
                Email Us
              </CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-lg mb-4">For general inquiries and support:</p>
              <a
                href="mailto:keeprify@gmail.com"
                className="text-primary hover:underline"
              >
                keeprify@gmail.com
              </a>
            </CardContent>
          </Card>
        </motion.div>

        <motion.div variants={itemVariants}>
          <Card className="h-full">
            <CardHeader>
              <CardTitle className="flex items-center">
                <Phone className="mr-2" />
                Call Us
              </CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-lg mb-4">
                Our support team is available Sat-Tue 24/7
              </p>
              <a
                href="tel:+213777102030"
                className="text-primary hover:underline"
              >
                +213 777 102030
              </a>
            </CardContent>
          </Card>
        </motion.div>

        <motion.div variants={itemVariants}>
          <Card className="h-full">
            <CardHeader>
              <CardTitle className="flex items-center">
                <MessageCircle className="mr-2" />
                Telegram Channel
              </CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-lg mb-4">
                Join our Telegram channel for updates and community discussions:
              </p>
              <a
                href="https://t.me/+FljXmzYXHJ1kYmE8"
                target="_blank"
                rel="noopener noreferrer"
                className="text-primary hover:underline"
              >
                t.me/keeprify
              </a>
            </CardContent>
          </Card>
        </motion.div>
      </motion.div>

      <motion.div variants={itemVariants}>
        <Card>
          <CardHeader>
            <CardTitle>Send Us a Message</CardTitle>
          </CardHeader>
          <CardContent>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label
                  htmlFor="name"
                  className="block text-sm font-medium mb-1"
                >
                  Name
                </label>
                <Input
                  id="name"
                  value={message.Subject}
                  onChange={(e) =>
                    setMessage({ ...message, Subject: e.target.value })
                  }
                  placeholder="Your name"
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium mb-1"
                >
                  Email
                </label>
                <Input
                  id="email"
                  type="email"
                  value={message.email}
                  onChange={(e) =>
                    setMessage({ ...message, email: e.target.value })
                  }
                  placeholder="your.email@example.com"
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="message"
                  className="block text-sm font-medium mb-1"
                >
                  Message
                </label>
                <Textarea
                  id="message"
                  value={message.Content}
                  onChange={(e) =>
                    setMessage({ ...message, Content: e.target.value })
                  }
                  placeholder="How can we help you?"
                  rows={5}
                  required
                />
              </div>
              <Button type="submit" className="w-full text-white">
                <Send className="mr-2 h-4 w-4" />
                Send Message
              </Button>
            </form>
          </CardContent>
        </Card>
      </motion.div>
    </motion.div>
  );
}
