import { Button } from "@/components/ui/button";
import { Download } from "lucide-react";
import { useTranslation } from "react-i18next";

const CSVExportButton = ({ data, filename = "export.csv", prepareData }) => {
  data = prepareData(data);
  const generateCSV = (data) => {
    const headers = Object.keys(data[0]);
    const csvRows = [
      headers.join(","), // CSV header row
      ...data.map((row) =>
        headers.map((fieldName) => JSON.stringify(row[fieldName])).join(","),
      ),
    ];
    return csvRows.join("\n");
  };

  const downloadCSV = () => {
    const csvContent = generateCSV(data);
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  const {t}=useTranslation()
  return (
    <Button
      onClick={downloadCSV}
      className="flex items-center gap-2 text-white"
    >
      <Download size={16} />
      {t("btns.e")}
    </Button>
  );
};

export default CSVExportButton;
