import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Plus, Trash, Search, CircleX } from "lucide-react";
import CSVExportButton from "@/AppComponents/CSVExportButton";

import ActionFooter from "@/AppComponents/ActionFooter";
import { AlertDialogC } from "@/AppComponents/AlertDialogC";
import EmptyTableComponent from "@/AppComponents/EmptyTableComponent";
import LoaderCircle from "@/AppComponents/LoaderCircle";
import PaginationComponent from "@/AppComponents/PaginationComponent";
import EditCoupons from "./EditCoupons";
import { prepareCoupons } from "@/utils/csv/prepare";

import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";
import { Switch } from "@/components/ui/switch";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { toast } from "@/components/ui/use-toast";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";

import { deleteCoupon, fetchCoupons } from "@/utils/CouponUtils";
import Axios from "@/utils/Axios";
import { useTranslation } from "react-i18next";

export default function Coupons() {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(8);
  const [searchTerm, setSearchTerm] = useState("");
  const { t } = useTranslation();
  const {
    data: couponsData,
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: ["coupons", page, pageSize],
    queryFn: () => fetchCoupons(page, pageSize),
  });

  const queryClient = useQueryClient();

  const toggleCouponMutation = useMutation({
    mutationFn: (data) =>
      Axios.put(`/api/coupons/update/${data.id}`, {
        IsActive: !data?.IsActive,
        CouponProduct: data?.CouponProduct,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["coupons"] });
      toast({ title: "Coupon updated " });
    },
    onError: (error) => {
      toast({ description: t("alert.err_title"), variant: "destructive" });
    },
  });
  const deleteCouponMutation = useMutation({
    mutationFn: deleteCoupon,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["coupons"] });
      toast({ description: t("alert.confirm_title"), variant: "default" });
    },
    onError: (error) => {
      toast({ description: t("alert.err_title"), variant: "destructive" });
    },
  });
  // if (isLoading) return <LoaderCircle />;
  if (isError) return <div>Error: {error.message}</div>;
  // if (couponsData?.count === 0)
  //   return <EmptyTableComponent label="Coupon" to="/dashboard/coupons/new" />;

  const pages = Math.ceil(couponsData?.count / pageSize);

  const filteredCoupons = couponsData?.coupons.filter((coupon) =>
    coupon.Title.toLowerCase().includes(searchTerm.toLowerCase()),
  );
  const columns = [
    { key: "code", label: t("coupons.columns.code") },
    { key: "type", label: t("type") },
    { key: "validity", label: t("coupons.columns.validity") },
    { key: "quantity", label: t("coupons.columns.quantity") },
    { key: "active", label: t("coupons.columns.active") },
    { key: "edit", label: t("coupons.columns.edit") },
    { key: "delete", label: t("coupons.columns.delete") },
  ];
  if (couponsData?.count === 0)
    return <EmptyTableComponent to={"/dashboard/coupons/new"} />;

  return (
    <>
      <div className="p-4 space-y-4">
        <Card>
          <CardHeader>
            <CardTitle>{t("coupons.title")}</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="flex gap-2 items-center mb-4">
              <Input
                placeholder={t("coupons.searchPlaceholder")}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="max-w-sm"
              />
              <Button variant="outline" onClick={() => setSearchTerm("")}>
                <CircleX />{" "}
                <span className="pl-2">{t("coupons.clearButton")}</span>
              </Button>

              <CSVExportButton
                data={filteredCoupons}
                filename={t("coupons.csvFilename")}
                prepareData={prepareCoupons}
              />
            </div>
            <div className="overflow-x-auto">
              {isLoading || toggleCouponMutation.isPending ? (
                <LoaderCircle />
              ) : (
                <Table>
                  <TableHeader>
                    <TableRow>
                      {columns.map((col) => (
                        <TableHead key={col.key} className="text-start">
                          {col.label}
                        </TableHead>
                      ))}
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {filteredCoupons?.map((item) => (
                      <TableRow key={item.id}>
                        <TableCell>{item.Title}</TableCell>
                        <TableCell>{item.Type}</TableCell>
                        <TableCell>
                          {new Date(item.validUntil).toLocaleDateString()}
                        </TableCell>
                        <TableCell>{item.value}</TableCell>
                        <TableCell>
                          <Switch
                            dir="ltr"
                            checked={item.IsActive}
                            onCheckedChange={(checked) => {
                              console.log(
                                `Toggle active status for ${item.Title}: ${checked}`,
                              );
                              toggleCouponMutation.mutate(item);
                            }}
                            aria-label={`Toggle active status for ${item.Title}`}
                          />
                        </TableCell>
                        <TableCell>
                          <EditCoupons current={item} />
                        </TableCell>
                        <TableCell>
                          <AlertDialogC
                            action={() => deleteCouponMutation.mutate(item.id)}
                            title={t("coupons.deleteConfirmation.title")}
                            description={t(
                              "coupons.deleteConfirmation.description",
                            ).replace("{couponTitle}", item.Title)}
                          >
                            <Button
                              variant="ghost"
                              size="icon"
                              aria-label={`حذف الكوبون ${item.Title}`}
                            >
                              <Trash className="h-4 w-4" />
                            </Button>
                          </AlertDialogC>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
            </div>
            <div className="mt-4">
              <PaginationComponent
                pages={pages}
                setpage={setPage}
                currentpage={page}
              />
            </div>
          </CardContent>
        </Card>
      </div>
      <ActionFooter
        text={t("coupons.addCouponButton.text")}
        type="link"
        to={t("coupons.addCouponButton.link")}
        icon={<Plus className="h-4 w-4" />}
      />
    </>
  );
}
