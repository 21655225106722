import React, { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import Axios from "@/utils/Axios";
import { Loader2 } from "lucide-react";
import DashboardContent from "./DashboardContent"; // This would be your existing dashboard content
import GetStarted from "./GetStarted";

export default function Dashboard() {
  const {
    data: getStartedTasks,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["getStartedCheck"],
    queryFn: () => Axios.get("/api/get-started/check"),
  });

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Loader2 className="h-8 w-8 animate-spin" />
      </div>
    );
  }

  if (error) {
    return <div>Error loading dashboard: {error.message}</div>;
  }

  if (
    Array.isArray(getStartedTasks?.data?.getStartedTasks) &&
    getStartedTasks?.data?.getStartedTasks.length > 0
  ) {
    return (
      <GetStarted incompleteTasks={getStartedTasks?.data?.getStartedTasks} />
    );
  }

  return <DashboardContent />;
}
