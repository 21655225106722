import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import "./index.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Toaster } from "./components/ui/toaster.tsx";
import Login from "./pages/Login.tsx";
import Register from "./pages/Register.tsx";
import Dashboard from "./pages/Dashboard.tsx";
import Customize from "./pages/store/Customize.tsx";
import Orders from "./pages/orders/Orders.tsx";
import Products from "./pages/products/Products.tsx";
import AuthProvider from "./components/auth/authProvider.tsx";
import LoginAuthProvider from "./components/auth/loginAuthProvider.tsx";
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "@tanstack/react-query";
import { Outlet } from "react-router-dom";
import { DirectionProvider } from "@radix-ui/react-direction";
import Navbar from "./AppComponents/Navbar.tsx";

import Settings from "./pages/Settings.tsx";
import Clients from "./pages/clients/Clients.tsx";
import { SidebarTemp } from "./AppComponents/SidebarTemp.tsx";
import { cn } from "./lib/utils.ts";
import { useStore } from "./hooks/useStore.ts";
import { useSidebarToggle } from "./hooks/useSidebarToggle.ts";

import AddProduct from "./pages/products/AddProduct.tsx";
import Categories from "./pages/categories/Categories.tsx";
import AddCategory from "./pages/categories/AddCategory.tsx";
import AddOrder from "./pages/orders/AddOrder.tsx";
import AddClient from "./pages/clients/AddClient.tsx";
import Coupons from "./pages/coupons/Coupons.tsx";
import "./utils/i18n.ts";
import { useTranslation } from "react-i18next";
import { ThemeProvider } from "./components/theme-provider.tsx";
import General from "./pages/settings/General.tsx";
import Main from "./pages/settings/Main.tsx";
import AddCoupon from "./pages/coupons/AddCoupon.tsx";
import Telegram from "./pages/integration/Telegram.tsx";
import FacebookPixel from "./pages/integration/FacebookPixel.tsx";
import GoogleSheets from "./pages/integration/GoogleSheets.tsx";
import { Themes } from "./pages/settings/Themes.tsx";
import Domains from "./pages/settings/Domains.tsx";
import Shipping from "./pages/Shipping.tsx";
import Delivery from "./pages/integration/Delivery.tsx";
import Inventory from "./pages/stock/Inventory.tsx";
import Affiliate from "./pages/Affiliate.tsx";
import Support from "./pages/Support.tsx";
import Notifications from "./pages/Notifications.tsx";
import TiktokPixel from "./pages/integration/Tiktok.tsx";
import Payment from "./pages/Payment.tsx";
import DeliveryNoest from "./pages/delivery/noest.tsx";
import DeliveryEcotrack from "./pages/delivery/ecotrack.tsx";
import DeliveryMaystro from "./pages/delivery/maystro.tsx";
import DeliveryGuepex from "./pages/delivery/guepex.tsx";
import DeliveryYalidine from "./pages/delivery/yalidine.tsx";
import DeliveryProColis from "./pages/delivery/procolis.tsx";
import DeliveryElogistia from "./pages/delivery/elogistia.tsx";
import { StoreContext } from "./utils/StoreContext.ts";
import { BreadContext } from "./utils/BreadContext.ts";

import { fetchStore } from "./utils/StoreUtils.ts";
import PrivacyPolicy from "./pages/Privacy.tsx";
import HomeNavbar from "./AppComponents/homeNavbar.tsx";
import TermsOfService from "./pages/ToS.tsx";
import AboutUs from "./pages/About.tsx";
import ContactPage from "./pages/Contact.tsx";
import HomeFooter from "./AppComponents/homeFooter.tsx";
import { IntegrationsPage } from "./pages/integration/General.tsx";
import GoogleTag from "./pages/integration/GoogleTag.tsx";
import GoogleAnalyticsTag from "./pages/integration/GoogleAnalytics.tsx";
import FakeOrders from "./pages/integration/FakeOrders.tsx";
import ProductWidget from "./pages/integration/Widget.tsx";
import Account from "./pages/settings/Account.tsx";
import ResetPassword from "./pages/PasswordReset.tsx";
import ForgotPassword from "./pages/PasswordForget.tsx";
import GetStarted from "./pages/GetStarted.tsx";
import Editor from "./pages/editor/Editor.tsx";
import Builder from "./pages/builder/Builder.tsx";

import Chatbot from "./components/Chat.tsx";

import {
  SidebarInset,
  SidebarProvider,
  SidebarTrigger,
} from "./components/ui/sidebar.tsx";
import { AppSidebar } from "./components/app-sidebar.tsx";
import { Separator } from "./components/ui/separator.tsx";
import Bread from "./components/bread-crumb.tsx";
import EmptyTableComponent from "./AppComponents/EmptyTableComponent.tsx";
import NotFound from "./pages/NotFound.tsx";
import LandingMain from "./pages/landing/LandingMain.tsx";
import path from "path";
import NewLanding from "./pages/landing/NewLanding.tsx";
import StatusProvider from "./components/auth/statusProvider.tsx";
import Pricing from "./pages/Pricing.tsx";

const DashboardLayout = () => {
  const [storeData, setStore] = useState();
  const [bread, setBread] = useState({
    title: "Dashboard",
  });
  const sidebar = useStore(useSidebarToggle, (state) => state);
  const {
    i18n: { changeLanguage, language },
  } = useTranslation();
  useEffect(() => {
    const defaultlanguage = localStorage.getItem("language");

    if (defaultlanguage != null) {
      // console.log(defaultlanguage)
      changeLanguage(defaultlanguage);
      if (language === "ar") {
        window.document.dir = "rtl";
      } else {
        window.document.dir = "ltr";
      }
    }
  }, [language]);
  const { data: store } = useQuery({
    queryKey: ["store"],
    queryFn: () => fetchStore(),
  });
  useEffect(() => {
    if (store) {
      setStore(store?.data);
    }
  }, [store]);
  return (
    <DirectionProvider dir={language === "ar" ? "rtl" : "ltr"}>
      <StoreContext.Provider value={{ storeData, setStore }}>
        <BreadContext.Provider value={{ bread, setBread }}>
          <AuthProvider>
            <>
              {/* <SidebarTemp /> */}
              <SidebarProvider>
                <AppSidebar />
                <SidebarInset>
                  <Bread />

                  <main
                    className="w-auto h-full  flex flex-col  dark:bg-background bg-[#f3f4f6] "

                    // className={cn(
                    //   "min-h-[calc(100vh_-_56px)] dark:bg-background bg-[#f3f4f6] transition-[margin-left] ease-in-out duration-300",
                    //   language === "ar"
                    //     ? sidebar?.isOpen === false
                    //       ? "lg:mr-[90px]"
                    //       : "lg:mr-64"
                    //     : sidebar?.isOpen === false
                    //       ? "lg:ml-[90px]"
                    //       : "lg:ml-64",
                    // )}
                  >
                    {/* <Navbar />  */}

                    <Outlet />

                    <Chatbot />
                  </main>
                </SidebarInset>
              </SidebarProvider>
            </>
          </AuthProvider>
        </BreadContext.Provider>
      </StoreContext.Provider>
    </DirectionProvider>
  );
};
const AppWrap = () => {
  const {
    i18n: { changeLanguage, language },
  } = useTranslation();
  useEffect(() => {
    const defaultlanguage = localStorage.getItem("language");

    if (defaultlanguage != null) {
      changeLanguage(defaultlanguage);
      if (language === "ar") {
        window.document.dir = "rtl";
      } else {
        window.document.dir = "ltr";
      }
    }
  }, [language]);
  return (
    <DirectionProvider dir={language === "ar" ? "rtl" : "ltr"}>
      <HomeNavbar />
      <Outlet />
      <HomeFooter />
    </DirectionProvider>
  );
};
const router = createBrowserRouter([
  {
    path: "/",
    element: <AppWrap />,
    children: [
      {
        path: "",
        element: <App />,
      },
      {
        path: "/privacy",
        element: <PrivacyPolicy />,
      },
      {
        path: "/pricing",
        element: <Pricing />,
      },
      {
        path: "/tos",
        element: <TermsOfService />,
      },
      {
        path: "/about",
        element: <AboutUs />,
      },
      {
        path: "/contact",
        element: <ContactPage />,
      },
    ],
  },
  {
    path: "/password-reset",
    element: (
      <LoginAuthProvider>
        <ResetPassword />
      </LoginAuthProvider>
    ),
  },
  {
    path: "/password-forgot",
    element: (
      <LoginAuthProvider>
        <ForgotPassword />
      </LoginAuthProvider>
    ),
  },
  {
    path: "/login",
    element: (
      <LoginAuthProvider>
        <Login />
      </LoginAuthProvider>
    ),
  },
  {
    path: "/register",
    element: (
      <LoginAuthProvider>
        <Register />
      </LoginAuthProvider>
    ),
  },
  {
    path: "/dashboard",
    element: <DashboardLayout />,

    children: [
      {
        path: "",
        element: <Dashboard />,
      },
      {
        path: "builder",
        element: <Builder />,
      },
      {
        path: "editor",
        element: <Editor />,
      },
      {
        path: "customize",
        element: <Customize />,
      },
      {
        path: "notifications",
        element: <Notifications />,
      },
      {
        path: "payment",
        element: <Payment />,
      },
      {
        path: "landingpages",
        children: [
          {
            path: "",
            element: <LandingMain />,
          },
          {
            path: "new",
            element: <NewLanding />,
          },
        ],
      },
      {
        path: "get-started",
        element: <GetStarted />,
      },
      {
        path: "orders",
        element: (
          <StatusProvider>
            <Outlet /> {/* Outlet for nested routes */}
          </StatusProvider>
        ),
        children: [
          {
            path: "",
            element: <Orders />,
          },
          {
            path: "new",
            element: <AddOrder />,
          },
        ],
      },
      {
        path: "ship",

        element: (
          <StatusProvider>
            <Shipping />
          </StatusProvider>
        ),
      },
      {
        path: "products",

        children: [
          {
            path: "",
            element: <Products />,
          },
          {
            path: "new",
            element: <AddProduct />,
          },
          {
            path: "inventory",
            element: <Inventory />,
          },
        ],
      },
      {
        path: "categories",
        children: [
          {
            path: "",
            element: <Categories />,
          },
          {
            path: "new",
            element: <AddCategory />,
          },
        ],
      },
      {
        path: "clients",
        element: (
          <StatusProvider>
            <Outlet /> {/* Outlet for nested routes */}
          </StatusProvider>
        ),
        children: [
          {
            path: "",
            element: <Clients />,
          },
          {
            path: "new",
            element: <AddClient />,
          },
        ],
      },
      {
        path: "coupons",
        children: [
          {
            path: "",
            element: <Coupons />,
          },
          {
            path: "new",
            element: <AddCoupon />,
          },
        ],
      },
      {
        path: "affiliate",
        element: <Affiliate />,
      },
      {
        path: "support",
        element: (
          <StatusProvider>
            <Support />
          </StatusProvider>
        ),
      },

      {
        path: "plugins",
        element: (
          <StatusProvider>
            <IntegrationsPage />,
          </StatusProvider>
        ),
        children: [
          // Google Sheets, Telegram Notification, Facebook Domain, Facebook Pixel
          // messenger message, keep safe Trakcing , and other services paid and not paid
          {
            path: "delivery",
            children: [
              {
                path: "",
                element: <Delivery />,
              },
              {
                path: "ecotrack",
                element: <DeliveryEcotrack />,
              },
              {
                path: "noest",
                element: <DeliveryNoest />,
              },
              {
                path: "guepex",
                element: <DeliveryYalidine />,
              },
              {
                path: "yalidine",
                element: <DeliveryYalidine />,
              },
              {
                path: "procolis",
                element: <DeliveryProColis />,
              },
              {
                path: "maystro",
                element: <DeliveryMaystro />,
              },
              {
                path: "elogistia",
                element: <DeliveryElogistia />,
              },
            ],
          },
          {
            path: "telegram_notifications",
            element: <Telegram />,
          },
          {
            path: "tiktok_pixel",
            element: <TiktokPixel />,
          },
          {
            path: "facebook_pixel",
            element: <FacebookPixel />,
          },
          {
            path: "google_sheets",
            element: <GoogleSheets />,
          },
          {
            path: "google_tag",
            element: <GoogleTag />,
          },

          {
            path: "google_analytics",
            element: <GoogleAnalyticsTag />,
          },
          {
            path: "fake_orders",
            element: <FakeOrders />,
          },
          {
            path: "widget",
            element: <ProductWidget />,
          },
        ],
      },
      {
        path: "settings",
        element: <Settings />,
        children: [
          {
            path: "",
            element: <Main />,
          },
          {
            path: "general",
            element: <General />,
          },
          {
            path: "account",
            element: <Account />,
          },
          {
            path: "themes",
            element: <Themes />,
          },
          {
            path: "domains",
            element: <Domains />,
          },
        ],
      },
    ],
  },
  {
    path: "*",
    element: (
      <div className="h-screen flex flex-col">
        <HomeNavbar />
        <NotFound />
      </div>
    ),
  },
]);
export const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <ThemeProvider defaultTheme="light" storageKey="vite-ui-theme">
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
        <Toaster />
      </QueryClientProvider>
    </ThemeProvider>
  </React.StrictMode>,
);
