import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Plus, Search, Filter, Download, CircleX } from "lucide-react";

import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { toast } from "@/components/ui/use-toast";

import ActionFooter from "@/AppComponents/ActionFooter";
import EmptyTableComponent from "@/AppComponents/EmptyTableComponent";
import PaginationComponent from "@/AppComponents/PaginationComponent";
import LoaderCircle from "@/AppComponents/LoaderCircle";
import EditStock from "./EditStock";

import { fetchProducts } from "@/utils/ProductUtils";
import CSVExportButton from "@/AppComponents/CSVExportButton";
import { prepareStock } from "@/utils/csv/prepare";

export default function Inventory() {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(8);
  const [searchTerm, setSearchTerm] = useState("");
  const [Products, setProducts] = useState(null);

  const columns = [
    { key: "image", label: t("Products.inside.table.p") },
    { key: "name", label: t("Products.inside.table.n") },
    { key: "price", label: `${t("Products.inside.table.pr")} (DZD)` },
    { key: "stock", label: "Current Stock" },
    { key: "actions", label: "Edit Stock" },
  ];

  const { data: products, isLoading } = useQuery({
    queryKey: ["products", page, pageSize],
    queryFn: () => fetchProducts(page, pageSize),
  });

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setPage(1);
  };

  const handleExport = () => {
    // Implement CSV export functionality here
    toast({ title: "Exporting inventory data..." });
  };

  useEffect(() => {
    if (products?.products) {
      setProducts(products?.products);
    }
  }, [products]);

  if (isLoading) return <LoaderCircle />;
  if (products?.count === 0)
    return <EmptyTableComponent label="product" to="/dashboard/products/new" />;

  const pages = Math.ceil(products?.count / pageSize);

  const filteredProducts = products?.products.filter((product) =>
    product?.name.toLowerCase().includes(searchTerm.toLowerCase()),
  );
  return (
    <>
      <div className="container mb-14">
        <Card className="p-4">
          <div className="flex flex-col space-y-4">
            <div className="flex justify-between items-center ">
              <div className="flex space-x-2">
                <div className="relative">
                  <Search className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400" />
                  <Input
                    className="pl-8"
                    placeholder={t("Products.inside.search")}
                    value={searchTerm}
                    onChange={handleSearch}
                  />
                </div>

                <Button variant="outline" onClick={() => setSearchTerm("")}>
                  <CircleX /> <span className="pl-2">Clear</span>
                </Button>
              </div>
              <CSVExportButton
                data={filteredProducts}
                filename="stock.csv"
                prepareData={prepareStock}
              />
            </div>

            <div className="overflow-x-auto">
              <Table>
                <TableHeader>
                  <TableRow>
                    {columns.map((col) => (
                      <TableHead key={col.key} className="text-start">
                        {col.label}
                      </TableHead>
                    ))}
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {filteredProducts?.map((product) => (
                    <TableRow key={product.id}>
                      <TableCell>
                        <img
                          className="w-16 h-16 object-cover rounded-full"
                          src={product.Imageurl}
                          alt={product.name}
                        />
                      </TableCell>
                      <TableCell>{product.name}</TableCell>
                      <TableCell>
                        {product.price.toLocaleString()} DZD
                      </TableCell>
                      <TableCell>
                        <span
                          className={`font-semibold ${product.stock_level <= product.Threshold ? "text-red-500" : "text-green-500"}`}
                        >
                          {product.stock_level}
                        </span>
                      </TableCell>
                      <TableCell>
                        <div className="flex space-x-2">
                          <EditStock currentProduct={product} />
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
            <PaginationComponent
              pages={pages}
              setpage={setPage}
              currentpage={page}
            />
          </div>
        </Card>
      </div>
      <ActionFooter
        text={t("Products.inside.add")}
        type="link"
        to="/dashboard/products/new"
        icon={<Plus />}
      />
    </>
  );
}
