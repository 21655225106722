import Axios from "./Axios";

export const addCoupon = async (coupon) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await Axios.post("api/coupons/create", { ...coupon });
      if (res.status === 200) {
        resolve(res);
      } else throw new Error(res.data.error);
    } catch (e) {
      reject(e);
    }
  });
};
export const fetchCoupons = async (page, pageSize) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await Axios.get("api/coupons", {
        params: {
          page,
          pageSize,
        },
      });
      if (res.status === 200) {
        resolve(res.data);
      } else {
        throw new Error("couldnt fetch coupons");
      }
    } catch (e) {
      reject();
    }
  });
};

export const deleteCoupon = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await Axios.delete(`api/coupons/delete/${id}`);
      if (res.status === 200) {
        resolve(res);
      } else throw new Error(res.data.error);
    } catch (e) {
      reject(e);
    }
  });
};

export const updateCoupon = async (id, coupon) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await Axios.put(`api/coupons/update/${id}`, {
        ...coupon,
      });
      if (res.status === 200) {
        resolve(res);
      } else if (res.status === 230) {
        // Bad request Client error
        throw new Error(res.data.error);
      } else {
        throw new Error(res.data.error);
      }
    } catch (e) {
      reject(e);
    }
  });
};
