import { useEffect, useState } from "react";
import Axios from "@/utils/Axios.ts";
import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import { Checkbox } from "@/components/ui/checkbox";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { CaretDownIcon } from "@radix-ui/react-icons";
import { Loader, Plus, Trash, Filter, RefreshCw } from "lucide-react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  deleteOrder,
  deliverOrder,
  fetchOrders,
  updateOrder,
} from "@/utils/OrderUtils";
import { fetchAllIntegrations } from "@/utils/DeliveryUtils";
import PaginationComponent from "@/AppComponents/PaginationComponent";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import LoaderCircle from "@/AppComponents/LoaderCircle";
import EmptyTableComponent from "@/AppComponents/EmptyTableComponent";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { AlertDialogC } from "@/AppComponents/AlertDialogC";
import { TooltipC } from "@/AppComponents/TooltipC";
import { toast } from "@/components/ui/use-toast";
import EditOrder from "./EditOrder";
import { OrderDetailsDialog } from "./OrderDetailsDialog";
import { useTranslation } from "react-i18next";
import ActionFooter from "@/AppComponents/ActionFooter";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import CSVExportButton from "@/AppComponents/CSVExportButton";
import { prepareOrders } from "@/utils/csv/prepare";
import { motion } from "framer-motion";
import { Badge } from "@/components/ui/badge";

const ORDER_STATUSES = [
  "ABANDONED",
  "NEW",
  "APPROVED",
  "ONDELIVER",
  "COMPLETED",
  "DECLINED",
];

const statusColors = {
  ABANDONED: "bg-gray-200 text-gray-800",
  NEW: "bg-blue-200 text-blue-800",
  APPROVED: "bg-green-200 text-green-800",
  ONDELIVER: "bg-yellow-200 text-yellow-800",
  COMPLETED: "bg-purple-200 text-purple-800",
  DECLINED: "bg-red-200 text-red-800",
};

export default function Orders() {
  const { t, i18n } = useTranslation();
  const columns = [
    t("btns.select"),
    t("Orders.inside.table.o"),
    t("Orders.inside.table.c"),
    t("Orders.inside.table.p"),
    t("Orders.inside.table.d"),
    t("Orders.inside.table.w"),
    t("Orders.inside.table.co"),
    t("Orders.inside.table.s"),
    t("Orders.inside.table.t"),
    t("Orders.inside.table.e"),
    t("Orders.inside.table.ac"),
    t("Orders.inside.table.r"),
  ];
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(8);
  const [orders, setOrders] = useState([]);
  const [filter, setFilter] = useState("");
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [statusFilter, setStatusFilter] = useState("ALL");
  const [DeliveryIntegrations, setDeliveryIntegrations] = useState({});
  const [orderUpdating, setOrderUpdating] = useState(null);
  const { data: Orders, isLoading } = useQuery({
    queryKey: ["orders", page, pageSize, statusFilter],
    queryFn: () => fetchOrders(page, pageSize, statusFilter),
  });

  const { data: deliveryIntegrations, isLoadingIntegrations } = useQuery({
    queryKey: ["integrations"],
    queryFn: () => fetchAllIntegrations(),
  });

  const queryClient = useQueryClient();

  const deleteOrderMutation = useMutation({
    mutationFn: (id) => {
      setOrderUpdating(id);
      deleteOrder(id);
    },
    onSuccess: () => {
      toast({ description: t("alert.confirm_title"), variant: "default" });

      queryClient.invalidateQueries({ queryKey: ["orders"] });
    },
    onError: () => {
      deleteOrderMutation.reset();
      toast({ description: t("alert.err_title"), variant: "destructive" });
    },
  });

  const deleteMultipleOrdersMutation = useMutation({
    mutationFn: (ids) =>
      Axios.delete("/api/order/delete/multi", { data: { ids } }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["orders"] });
      toast({ description: "Selected orders deleted" });
      setSelectedOrders([]);
    },
    onError: () => {
      deleteMultipleOrdersMutation.reset();
      toast({ description: t("alert.err_title"), variant: "destructive" });
    },
  });

  const updateOrderMutation = useMutation({
    mutationFn: (data) => {
      updateOrder(data.id, data.data);
    },
    onSuccess: () => {
      toast({ description: "Order updated" });
      queryClient.invalidateQueries({ queryKey: ["orders"] });
    },
    onError: () => {
      updateOrderMutation.reset();
      toast({ description: t("alert.err_title"), variant: "destructive" });
    },
  });

  const updateOrderMultipleMutation = useMutation({
    mutationFn: (data) => Axios.post("/api/order/update/multi", data),
    onSuccess: () => {
      toast({ description: "Orders updated" });
      queryClient.invalidateQueries({ queryKey: ["orders"] });
    },
    onError: () => {
      updateOrderMultipleMutation.reset();
      toast({ description: t("alert.err_title"), variant: "destructive" });
    },
  });

  const deliverOrderMutation = useMutation({
    mutationFn: (data) => deliverOrder(data.id, data.order),
    onSuccess: (response, variables) => {
      updateOrderMutation.mutate({
        id: variables?.order?.id,
        data: { status: "ONDELIVER" },
      });
      queryClient.invalidateQueries({ queryKey: ["orders"] });
    },
    onError: () => {
      deliverOrderMutation.reset();
      toast({ description: t("alert.err_title"), variant: "destructive" });
    },
  });

  useEffect(() => {
    if (Orders?.data) {
      setOrders(Orders.data);
    }
  }, [Orders]);

  useEffect(() => {
    if (deliveryIntegrations?.deliveryIntegrations) {
      setDeliveryIntegrations(deliveryIntegrations?.deliveryIntegrations);
    }
  }, [deliveryIntegrations]);

  useEffect(() => {
    if (Array.isArray(orders) && orders.length > 0 && filter !== "") {
      const filteredItems = orders.filter(
        (item) =>
          item?.client_name.toLowerCase().includes(filter.toLowerCase()) ||
          item?.client_phone_number.includes(filter.toLowerCase()),
      );
      setOrders(filteredItems);
    } else if (Orders?.data) {
      setOrders(Orders.data);
    }
  }, [filter, Orders]);

  const handleSelectOrder = (orderId) => {
    setSelectedOrders((prev) =>
      prev.includes(orderId)
        ? prev.filter((id) => id !== orderId)
        : [...prev, orderId],
    );
  };

  const handleSelectAll = () => {
    if (orders && orders.length > 0) {
      if (selectedOrders.length === orders.length) {
        setSelectedOrders([]);
      } else {
        setSelectedOrders(orders.map((order) => order.id));
      }
    }
  };

  const handleDeleteSelected = () => {
    if (selectedOrders.length > 0) {
      deleteMultipleOrdersMutation.mutate(selectedOrders);
    }
  };

  const handleChangeSelected = (status) => {
    if (selectedOrders.length > 0) {
      updateOrderMultipleMutation.mutate({
        ids: selectedOrders,
        status: status,
      });
    }
  };

  const handleChangeStatusOrder = (orderId, status) => {
    updateOrderMutation.mutate({ id: orderId, data: { status: status } });
  };

  const handleSendToDelivery = (id, order) => {
    deliverOrderMutation.mutate({ id, order });
  };

  const pages = Orders?.count ? Math.ceil(Orders.count / pageSize) : 0;

  if (orders?.count === 0)
    return <EmptyTableComponent label="order" to="/dashboard/orders/new" />;

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="px-4 py-2 "
      >
        <Card className="">
          <CardContent>
            <div className="space-y-3">
              <div className="flex flex-wrap gap-2 pt-4">
                <Button
                  variant={statusFilter === "ALL" ? "default" : "outline"}
                  onClick={() => setStatusFilter("ALL")}
                  className={statusFilter === "ALL" ? "text-white" : ""}
                >
                  {t("Orders.inside.all")}{" "}
                  {statusFilter === "ALL" ? <>({Orders?.count})</> : null}
                </Button>
                {ORDER_STATUSES.map((status) => (
                  <Button
                    key={status}
                    variant={statusFilter === status ? "default" : "outline"}
                    onClick={() => {
                      setStatusFilter(status);
                      setSelectedOrders([]);
                    }}
                    className={statusFilter === status ? "text-white" : ""}
                  >
                    {t(`Orders.inside.status.${status}`)}{" "}
                    {statusFilter === status
                      ? ` (${Orders?.count || 0})`
                      : null}
                  </Button>
                ))}
              </div>

              <div className="flex flex-wrap gap-4 items-center">
                <Input
                  onChange={(e) => setFilter(e.target.value)}
                  className="max-w-sm"
                  placeholder={t("Orders.inside.search")}
                  icon={<Filter className="h-4 w-4 text-gray-500" />}
                />
                <Select onValueChange={(value) => setPageSize(value * 1)}>
                  <SelectTrigger className="w-[180px]">
                    <SelectValue
                      placeholder={t("btns.items") + " : " + pageSize}
                    />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectLabel>{t("btns.items")}</SelectLabel>
                      {[8, 16, 24, 50, 100].map((size) => (
                        <SelectItem key={size} value={size}>
                          {size}
                        </SelectItem>
                      ))}
                    </SelectGroup>
                  </SelectContent>
                </Select>
                <CSVExportButton
                  data={orders}
                  filename="orders.csv"
                  prepareData={prepareOrders}
                />
                <Button
                  variant="outline"
                  onClick={() =>
                    queryClient.invalidateQueries({ queryKey: ["orders"] })
                  }
                >
                  <RefreshCw className="h-4 w-4 mx-2" />
                  {t("btns.refresh")}
                </Button>
              </div>

              <div className="flex justify-between items-center flex-wrap gap-4">
                <div className="flex flex-wrap gap-2">
                  <Button
                    onClick={handleDeleteSelected}
                    disabled={selectedOrders.length === 0}
                    variant="destructive"
                  >
                    {t("btns.ds")}({selectedOrders.length})
                  </Button>
                  {(statusFilter === "NEW" || statusFilter === "ABANDONED") && (
                    <Button
                      onClick={() => handleChangeSelected("APPROVED")}
                      disabled={selectedOrders.length === 0}
                    >
                      {t("btns.as")}
                    </Button>
                  )}
                  {statusFilter === "ONDELIVER" && (
                    <>
                      <Button
                        onClick={() => handleChangeSelected("COMPLETED")}
                        disabled={selectedOrders.length === 0}
                      >
                        Complete Selected
                      </Button>
                      <Button
                        onClick={() => handleChangeSelected("DECLINED")}
                        disabled={selectedOrders.length === 0}
                      >
                        Decline Selected
                      </Button>
                    </>
                  )}
                </div>
                <div className="flex items-center space-x-2">
                  <Checkbox
                    id="select-all"
                    checked={
                      orders &&
                      orders.length > 0 &&
                      selectedOrders.length === orders.length
                    }
                    onCheckedChange={handleSelectAll}
                  />
                  <label htmlFor="select-all">{t("btns.sa")}</label>
                </div>
              </div>

              {isLoading ? (
                <LoaderCircle />
              ) : (
                <div className="overflow-x-auto  rounded-md">
                  <Table>
                    <TableHeader>
                      <TableRow>
                        {columns.map((col, index) => (
                          <TableHead key={index} className="text-start">
                            {col}
                          </TableHead>
                        ))}
                        <TableHead className="text-start">Details</TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {orders &&
                        orders.map((item) => (
                          <TableRow key={item.id}>
                            <TableCell>
                              <Checkbox
                                checked={selectedOrders.includes(item.id)}
                                onCheckedChange={() =>
                                  handleSelectOrder(item.id)
                                }
                              />
                            </TableCell>
                            <TableCell>
                              <TooltipC content={item?.product?.name}>
                                <Avatar>
                                  <AvatarImage
                                    src={item?.product?.Imageurl}
                                    alt="product image"
                                  />
                                  <AvatarFallback>image</AvatarFallback>
                                </Avatar>
                              </TooltipC>
                            </TableCell>
                            <TableCell>{item.client_name}</TableCell>
                            <TableCell>{item.client_phone_number}</TableCell>
                            <TableCell>
                              {new Date(item?.sale_date).toLocaleDateString()}
                            </TableCell>
                            <TableCell>{item?.wilaya?.name}</TableCell>
                            <TableCell>{item?.commune?.name}</TableCell>
                            <TableCell>
                              {/* {updateOrderMutation.isPending ? ( */}
                              {/*   <LoaderCircle /> */}
                              {/* ) : null} */}
                              <Badge className={statusColors[item.status]}>
                                {t(
                                  `Orders.inside.status.${item.status}`,
                                ).replace("ال", "")}
                              </Badge>
                            </TableCell>
                            <TableCell>{item?.total_price}</TableCell>
                            <TableCell>
                              <EditOrder order={item} />
                            </TableCell>
                            <TableCell>
                              <DropdownMenu>
                                <DropdownMenuTrigger asChild>
                                  <Button
                                    variant="ghost"
                                    className="h-8 w-8 p-0"
                                  >
                                    <CaretDownIcon className="h-4 w-4" />
                                  </Button>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent align="end">
                                  {(item.status === "NEW" ||
                                    item.status === "ABANDONED") && (
                                      <DropdownMenuItem
                                        onClick={() =>
                                          handleChangeStatusOrder(
                                            item.id,
                                            "APPROVED",
                                          )
                                        }
                                      >
                                        {t("btns.approve")}
                                      </DropdownMenuItem>
                                    )}
                                  {item.status === "APPROVED" && (
                                    <>
                                      {DeliveryIntegrations &&
                                        DeliveryIntegrations.length > 0 ? (
                                        DeliveryIntegrations.map(
                                          (integration) => (
                                            <DropdownMenuItem
                                              key={integration.id}
                                              onClick={() =>
                                                handleSendToDelivery(
                                                  integration.id,
                                                  item,
                                                )
                                              }
                                            >
                                              {t("btns.st")} {integration.name}
                                            </DropdownMenuItem>
                                          ),
                                        )
                                      ) : (
                                        <DropdownMenuItem
                                          onClick={() =>
                                            console.log("Configure delivery")
                                          }
                                        >
                                          {t("btns.cd")}
                                        </DropdownMenuItem>
                                      )}
                                    </>
                                  )}
                                  {item.status === "ONDELIVER" && (
                                    <>
                                      <DropdownMenuItem
                                        onClick={() =>
                                          handleChangeStatusOrder(
                                            item.id,
                                            "COMPLETED",
                                          )
                                        }
                                      >
                                        Complete
                                      </DropdownMenuItem>
                                      <DropdownMenuItem
                                        onClick={() =>
                                          handleChangeStatusOrder(
                                            item.id,
                                            "DECLINED",
                                          )
                                        }
                                      >
                                        Decline
                                      </DropdownMenuItem>
                                    </>
                                  )}
                                </DropdownMenuContent>
                              </DropdownMenu>
                            </TableCell>
                            {orderUpdating === item.id ? (
                              <LoaderCircle />
                            ) : (
                              <TableCell>
                                <AlertDialogC
                                  action={() =>
                                    deleteOrderMutation.mutate(item.id)
                                  }
                                >
                                  <Trash size="20" />
                                </AlertDialogC>
                              </TableCell>
                            )}
                            <TableCell>
                              <OrderDetailsDialog order={item} />
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </div>
              )}
              <PaginationComponent
                pages={pages}
                setpage={setPage}
                currentpage={page}
              />
            </div>
          </CardContent>
        </Card>
      </motion.div>
      <ActionFooter
        text={t("Orders.inside.add")}
        type="link"
        to={"/dashboard/orders/new"}
        icon={<Plus />}
      />
    </>
  );
}
