import { useState } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Card,
  CardHeader,
  CardDescription,
  CardContent,
} from "@/components/ui/card";
import { useToast } from "@/components/ui/use-toast";
import { Loader2, CheckCircle } from "lucide-react";
import Axios from "@/utils/Axios";

export default function ResetPassword() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const { toast } = useToast();

  const resetPasswordMutation = useMutation({
    mutationFn: (email) =>
      Axios.post("/auth/password/forgot", { email: email }),
    onSuccess: () => {
      toast({ title: "recovery email sent successfully" });
      navigate("/");
    },
    onError: () => {
      toast({ title: "Error sending reset email", variant: "destructive" });
      navigate("/");
    },
  });

  const onSubmit = (e) => {
    e.preventDefault();
    resetPasswordMutation.mutate(email);
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  return (
    <motion.div
      className="container mx-auto px-4 py-8 max-w-md"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <motion.h1
        className="text-4xl font-bold text-center mb-8"
        variants={itemVariants}
      >
        <img
          src="fullkeeprifyprimarycolor.png"
          alt="Logo"
          className="p-2 my-4"
        />
        Forget Your Password
      </motion.h1>

      <Card>
        <CardHeader>
          <CardDescription>Please enter your email</CardDescription>
        </CardHeader>
        <CardContent>
          <div>
            <label
              htmlFor="newpassword"
              className="block text-sm font-medium mb-1"
            >
              Email
            </label>
            <div className="relative">
              <Input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
          <Button
            type="submit"
            className="w-full mt-4"
            onClick={onSubmit}
            disabled={resetPasswordMutation.isPending}
          >
            {resetPasswordMutation.isPending ? (
              <>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                Sending Email...
              </>
            ) : (
              <>
                <CheckCircle className="mr-2 h-4 w-4" />
                Send Email
              </>
            )}
          </Button>
        </CardContent>
      </Card>

      <motion.div
        variants={itemVariants}
        className="text-center mt-8 space-y-2"
      >
        <p className="text-sm text-muted-foreground">
          By resetting your password, you agree to our{" "}
          <a href="/tos" className="underline hover:text-primary">
            Terms of Service
          </a>{" "}
          and{" "}
          <a href="/privacy" className="underline hover:text-primary">
            Privacy Policy
          </a>
          .
        </p>
        <p className="text-sm text-muted-foreground">
          © 2024 Keeprify. All rights reserved.
        </p>
      </motion.div>
    </motion.div>
  );
}
