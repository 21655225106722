import { toast } from "@/components/ui/use-toast";
import Axios from "./Axios";

export const fetchIntegration = async (company: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await Axios.get("api/delivery/integration", {
        params: {
          company,
        },
      });
      if (res.status === 200) {
        resolve(res.data);
      } else {
        throw new Error("couldnt fetch intergation");
      }
    } catch (e) {
      reject();
    }
  });
};
export const upsertIntegration = async (
  company,
  companyId,
  id,
  integration,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await Axios.post(
        "api/delivery/integration/upsert",
        { ...integration },
        {
          params: {
            company,
            companyId,
            id,
          },
        },
      );
      // console.log("res data is: ", res.data);
      if (res.status === 200) {
        resolve(res.data);
      } else {
        throw new Error("couldnt update intergation");
      }
    } catch (e) {
      reject();
    }
  });
};

export const fetchAllIntegrations = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await Axios.get("api/delivery/integration/all");
      // console.log("from util res data is: ", res.data);
      if (res.status === 200) {
        resolve(res.data);
      } else {
        throw new Error("couldnt fetch intergations");
      }
    } catch (e) {
      reject();
    }
  });
};
