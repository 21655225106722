import { Separator } from "@/components/ui/separator";
import { Ban, Box, Car, Check, CheckCircle, DollarSign } from "lucide-react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import React, { useState, useEffect } from "react";
import {
  Bell,
  ShoppingCart,
  Users,
  Package,
  PlusCircle,
  TrendingUp,
  Map,
  Star,
  Clock,
  ArrowUpRight,
  ArrowDownRight,
  Zap,
  Activity,
} from "lucide-react";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  PieChart,
  Pie,
  Cell,
  Bar,
} from "recharts";
import {
  fetchClientsStats,
  fetchOrdersDiff,
  fetchOrdersStats,
  fetchSales,
  fetchSalesDiff,
  moreStats,
} from "@/utils/StatsUtil";
import { ChartContainer, ChartTooltip, ChartTooltipContent, type ChartConfig } from "@/components/ui/chart"
import { getMenuList } from "@/lib/MenuList";
import LoaderCircle from "@/AppComponents/LoaderCircle";
const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#8884D8"];
const topProducts = [
  { name: "Product A", value: 400 },
  { name: "Product B", value: 300 },
  { name: "Product C", value: 300 },
  { name: "Product D", value: 200 },
  { name: "Product E", value: 100 },
];



export default function DashboardContent() {
  const [clientsStats, setClientsStats] = useState(null);
  const [ordersStats, setOrdersStats] = useState(null);
  const [ordersDiffStats, setOrdersDiffStats] = useState(null);
  const [salesStats, setSalesStats] = useState(null);
  const [salesDiffStats, setSalesDiffStats] = useState(null);
  
  const {
    data: clients_stats,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["clientStats"],
    queryFn: () => fetchClientsStats(),
  });
  // const { data: orders_stats, isLoadingOrders } = useQuery({
  //   queryKey: ["orderStats"],
  //   queryFn: () => fetchOrdersStats(),
  // });
  // const { data: orders_diff_stats, isLoadingOrdersDiff } = useQuery({
  //   queryKey: ["orderDIffStats"],
  //   queryFn: () => fetchOrdersDiff(),
  // });
  // const { data: sales_stats, isLoadingSales } = useQuery({
  //   queryKey: ["salesStats"],
  //   queryFn: () => fetchSales(),
  // });
  // const { data: sales_diff_stats, isLoadingSalesDiff } = useQuery({
  //   queryKey: ["salesDiffStats"],
  //   queryFn: () => fetchSalesDiff(),
  // });
  const { data: more, moreLoading } = useQuery({
    queryKey: ["more"],
    queryFn: () => moreStats(),
  });

  useEffect(() => {
    if (clients_stats) {
      setClientsStats(clients_stats?.data);
    }
  }, [clients_stats]);

  // useEffect(() => {
  //   if (orders_stats) {
  //     setOrdersStats(orders_stats?.data);
  //   }
  // }, [orders_stats]);

  // useEffect(() => {
  //   if (orders_diff_stats) {
  //     setOrdersDiffStats(orders_diff_stats?.data);
  //   }
  // }, [orders_diff_stats]);

  // useEffect(() => {
  //   if (sales_stats) {
  //     setSalesStats(sales_stats?.data?.formattedSales);
  //     // setSalesDiffStats(sales_stats?.data?.total);
  //   }
  // }, [sales_stats]);

  // useEffect(() => {
  //   if (sales_diff_stats) {
  //     // console.log(sales_diff_stats);
  //     setSalesDiffStats(sales_diff_stats?.data);
  //   }
  // }, [sales_diff_stats]);

  const { t } = useTranslation();
  let salesChange=(parseInt(more?.data?.salesThisWeek)-parseInt(more?.data?.salesPrevWeek))/parseInt(more?.data?.salesPrevWeek)
  if(isLoading) return <div className="w-full h-full items-center justify-center"><LoaderCircle/></div>
  return (
    <div className="  min-h-screen p-6">
      
      <div className="max-w-7xl mx-auto space-y-6">
        {/* Statistics */}
        {/* Quick Stats */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <QuickStatCard
            title={t("Dashboard.inside.salles.title")}
            value={more?.data?.totalSales}
            change={salesChange.toPrecision(3)+"%"}
            icon={<DollarSign className="h-4 w-4" />}
            trend={salesChange>0?"up":"down"}
          />
          <QuickStatCard
            title={t("Dashboard.inside.orders.title")}
            value={more?.data?.totalOrders}
            change="+15%"
            icon={<Package className="h-4 w-4" />}
            trend="up"
          />
          <QuickStatCard
            title={t("Dashboard.inside.salles.stw")}
            value={more?.data?.salesThisWeek}
            change={salesChange.toPrecision(3)+"%"}
            icon={<DollarSign className="h-4 w-4" />}
            trend="up"
          />
          <QuickStatCard
            title={t("Dashboard.inside.orders.otw")}
            value={more?.data?.ordersThisWeek}
            change="+15%"
            icon={<Package className="h-4 w-4" />}
            trend="up"
          />
           <QuickStatCard
            title={t("Dashboard.inside.salles.spw")}
            value={more?.data?.salesPrevWeek}
            change="+15%"
            icon={<DollarSign className="h-4 w-4" />}
            trend="up"
          />
           <QuickStatCard
            title={t("Dashboard.inside.orders.opw")}
            value={more?.data?.ordersPrevWeek}
            change="+15%"
            icon={<Package className="h-4 w-4" />}
            trend="up"
          />
          {/* <QuickStatCard */}
          {/*   title={t("Dashboard.inside.satisfaction.title")} */}
          {/*   value="95%" */}
          {/*   change="+5%" */}
          {/*   icon={<Star className="h-4 w-4" />} */}
          {/*   trend="up" */}
          {/* /> */}
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {/* Sales Card */}
          <Card>
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">
                {t("Dashboard.inside.salles.title")}
              </CardTitle>
              <ShoppingCart className="h-4 w-4 text-muted-foreground" />
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">
                {more?.data?.totalSales}<br />
              </div>
              
              <div className="h-[200px] mt-4">
                <ResponsiveContainer width="100%" height="100%">
                  <AreaChart data={more?.data?.salesLast30Days}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Area
                      type="monotone"
                      dataKey="sales"
                      stroke="#581c87"
                      fill="#581c87"
                    />
                  </AreaChart>
                </ResponsiveContainer>
                {/* <div className="mb-2 "> */}
                {/*   {salesDiffStats?.salesLast7Days}{" "} */}
                {/*   {t("Dashboard.inside.salles.stw")} */}
                {/* </div> */}
              </div>
            </CardContent>
          </Card>
          
          {/* Orders Card */}
          <Card>
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">
                {t("Dashboard.inside.orders.title")}
              </CardTitle>
              <Package className="h-4 w-4 text-muted-foreground" />
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">
                +{more?.data?.ordersThisWeek}
              </div>
              <p className="text-xs text-muted-foreground">
                {ordersDiffStats?.ratio} {t("Dashboard.inside.orders.flw")}
              </p>
              <div className="h-[200px] mt-4">
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart data={more?.data?.ordersLast7Days}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="orders" fill="#581c87" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </CardContent>
          </Card>

          {/* Customers Card */}
          <Card>
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">
                {t("Dashboard.inside.Customers.title")}
              </CardTitle>
              <Users className="h-4 w-4 text-muted-foreground" />
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">
                +{clientsStats?.newClientsThisMonth}
              </div>
              <p className="text-xs text-muted-foreground">
                {clientsStats?.ratio} {t("Dashboard.inside.salles.flm")}
              </p>
              <div className="mt-4 space-y-2">
                <div className="flex items-center">
                  <div className="w-full bg-gray-200 rounded-full h-2.5">
                    <div
                      className="bg-purple-800 rounded-full"
                      style={{ width: clientsStats?.ratio }}
                    ></div>
                  </div>
                  <span className="text-sm font-medium text-gray-700 ml-2">
                    {clientsStats?.ratio}
                  </span>
                </div>
                {/* <p className="text-xs text-muted-foreground">Conversion rate</p> */}
              </div>
            </CardContent>
          </Card>
        </div>
        {/* Notifications and New Page Section */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Notifications */}
          <Card>
            <CardHeader>
              <CardTitle>Recent Notifications</CardTitle>
            </CardHeader>
            <CardContent>
              <ul className="space-y-4">
                {[
                  {
                    title: "New Feature: Inventory",
                    description:
                      "manage stock of your products synced with your confirmed orders",
                  },
                  {
                    title: "New Theme Released ",
                    description: "Check out the new theme in the themes page",
                  },
                  {
                    title: "Store Performance Alert",
                    description:
                      "Your store's perfomance has increased by 30% with the new release!",
                  },
                ].map((notification, index) => (
                  <li key={index} className="flex items-start space-x-4">
                    <div className="bg-violet-900 rounded-full p-1">
                      <Bell className="h-4 w-4 text-white" />
                    </div>
                    <div>
                      <p className="font-medium">{notification.title}</p>
                      <p className="text-sm text-gray-500">
                        {notification.description}
                      </p>
                    </div>
                  </li>
                ))}
              </ul>
            </CardContent>
          </Card>

          {/* New Product Section */}
          <Card>
            <CardHeader>
              <CardTitle>{t("Dashboard.inside.products.title")}</CardTitle>
              <CardDescription>
                {t("Dashboard.inside.products.d")}
              </CardDescription>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                <div className="flex flex-col gap-2 sm:flex-row items-start sm:items-center space-y-2 sm:space-y-0 sm:space-x-4">
                  <Link to="/dashboard/products/new">
                    <Button className="w-full sm:w-auto">
                      <PlusCircle className="mr-2 h-4 w-4" />
                      {t("Dashboard.inside.products.c")}
                    </Button>
                  </Link>
                  <Link to="/dashboard/settings/themes">
                    <Button variant="outline" className="w-full sm:w-auto">
                      {t("Dashboard.inside.products.v")}
                    </Button>
                  </Link>
                </div>
                <div className="bg-secondary p-4 rounded-md">
                  <h4 className="font-semibold mb-2">
                    {t("Dashboard.inside.products.qt")}{" "}
                  </h4>
                  <ul className="text-sm text-gray-600 list-disc list-inside space-y-1">
                    <li>Use clear, descriptive titles</li>
                    <li>Include high-quality images</li>
                    <li>Optimize for SEO with relevant keywords</li>
                  </ul>
                </div>
              </div>
            </CardContent>
          </Card>

          {/* Circle Chart*/}
         
        </div>
      </div>
    </div>
  );
}

function QuickStatCard({ title, value, change, icon, trend }) {
  return (
    <Card>
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        <CardTitle className="text-sm font-medium">{title}</CardTitle>
        {icon}
      </CardHeader>
      <CardContent>
        <div className="text-2xl font-bold">{value}</div>
        <p className="text-xs text-muted-foreground flex items-center mt-1">
          {trend === "up" ? (
            <ArrowUpRight className="text-green-500 mr-1 h-4 w-4" />
          ) : (
            <ArrowDownRight className="text-red-500 mr-1 h-4 w-4" />
          )}
          {change}
        </p>
      </CardContent>
    </Card>
  );
}
