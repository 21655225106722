import { Link, Outlet, useHref } from "react-router-dom";
import { PersonIcon } from "@radix-ui/react-icons";
import {
  CloudCog,
  Activity,
  FacebookIcon,
  FileSpreadsheet,
  PaintBucket,
  Send,
  Settings,
  Sheet,
  Store,
  Truck,
  Users,
} from "lucide-react";
import { useTranslation } from "react-i18next";

export function SettingsPage() {
  const { t } = useTranslation();
  const pathname = useHref();
  const links = [
    {
      name: t("RealSett.inside.acc.title"),
      icon: <PersonIcon />,
      to: "/dashboard/settings/account",
    },
    {
      name: t("Settings.inside.themes.title"),
      icon: <PaintBucket size="20" />,
      to: "/dashboard/settings/themes",
    },
    {
      name: t("RealSett.inside.domain.title"),
      icon: <CloudCog size={20} />,
      to: "/dashboard/settings/domains",
    },
    // {
    //   name: "Manage Users",
    //   icon: <Users size="20" />,
    //   to: "/dashboard/settings/users",
    // },
  ];
  return (
    <div className="flex lg:flex-row flex-col min-h-screen w-full  ">
      <div className=" lg:w-64 border-r bg-background p-2 sticky  top-16  md:z-0 z-30 md:block">
        <div className="flex lg:h-full  flex-col gap-4  md:p-6">
          <nav className="flex lg:flex-col flex-row gap-2 overflow-x-auto ">
            {links.map((link, index) => (
              <Link
                key={index}
                to={link.to}
                className={`${pathname === link.to ? "bg-accent" : null}  flex items-center gap-3 rounded-md px-3 py-2 transition-colors text-muted-foreground hover:bg-accent hover:text-accent-foreground `}
              >
                {link.icon}
                <span className="text-sm">{link.name}</span>
              </Link>
            ))}
          </nav>
        </div>
      </div>
      <div className="flex-1 p-6 md:p-8">
        <div className="mx-auto max-w-3xl">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
