import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Axios from "@/utils/Axios";
import LoaderCircle from "@/AppComponents/LoaderCircle";
import HomeLoading from "@/AppComponents/HomeLoading";
import SubscriptionLock from "../SubscriptionLock";

const StatusProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const checkStatus = async () => {
      try {
        const res = await Axios.get("/api/status");
        const data = await res.data;
        console.log("data is: ", data);
        // console.log("data is ", data);
        setIsAuthenticated(data.success ? true : false);
      } catch (error) {
        console.error("Error checking authentication:", error);
        navigate("/login");
      }
    };

    checkStatus();
  }, [navigate]);

  if (isAuthenticated === null) {
    return (
      <div className="h-screen w-full items-center justify-center">
        <LoaderCircle />
      </div>
    );
  }
  if (isAuthenticated === false) {
    return <SubscriptionLock />;
  }

  return <>{children}</>;
};

export default StatusProvider;
