import { toast } from "@/components/ui/use-toast";
import Axios from "./Axios";

export const addOrder = async (Order) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await Axios.post("api/admin_create_order", { ...Order });
      if (res.status === 200) {
        resolve(res);
      } else throw new Error(res.error);
    } catch (e) {
      reject(e);
    }
  });
};
export const fetchOrders = async (page, pageSize, statusFilter) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await Axios.get("api/orders/status", {
        params: {
          page,
          pageSize,
          statusFilter,
        },
      });
      if (res.status === 200) {
        resolve(res.data);
      } else {
        throw new Error("couldnt fetch products");
      }
    } catch (e) {
      reject();
    }
  });
};

export const deleteOrder = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await Axios.delete(`api/delete_order/${id}`);
      if (res.status === 200) {
        resolve(res);
      } else throw new Error(res.data.error);
    } catch (e) {
      reject(e);
    }
  });
};

export const updateOrder = async (id, order) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await Axios.put(`api/update_order/${id}`, {
        ...order,
      });
      if (res.status === 200) {
        resolve(res);
      } else throw new Error(res.data.error);
    } catch (e) {
      reject(e);
    }
  });
};

export const deliverOrder = async (id, order) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await Axios.post(`api/delivery/deliver/${id}`, {
        order: order,
      });
      if (res.status === 200) {
        resolve(res);
      } else throw new Error(res.data.error);
    } catch (e) {
      reject(e);
    }
  });
};
