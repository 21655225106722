import React, { useState } from "react";
import { motion } from "framer-motion";
import ActionFooter from "@/AppComponents/ActionFooter";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Separator } from "@/components/ui/separator";
import { Save } from "lucide-react";
import { addClient } from "@/utils/ClientUtils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "@/components/ui/use-toast";
import { Label } from "@/components/ui/label";
import { useTranslation } from "react-i18next";

export default function ResponsiveAddClient() {
  const [client, setClient] = useState({
    fullname: "",
    email: "",
    phone_number: "",
  });
  const {t}=useTranslation();

  const queryClient = useQueryClient();
  const addClientMutation = useMutation({
    mutationFn: () => addClient(client),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["products"] });
      setClient({
        fullname: "",
        email: "",
        phone_number: "",
      });
      toast({ description:t("alert.confirm_title")});
    },
    onError: () => {
      addClientMutation.reset();
      toast({ description:t("alert.err_title"),variant:"destructive"});


    },
  });

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };
  const handleAddCustomer=()=>{
    if(client.email=="" || client.fullname=="" || client.phone_number==""){
      toast({
        title: t("alert.err_title"),
        description: t("alert.err_d"),
        variant: "destructive",
      });
    }else{
      addClientMutation.mutate(client)
    }
  }
  return (
    <>
      <motion.div
        className=""
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <h1 className="text-2xl font-bold p-4">
          {t("Customers.create.title")}
        </h1>

        <Card className="p-4 sm:p-6">
          <motion.div variants={itemVariants}>
            <h2 className="text-lg font-semibold mb-4">
          {t("Customers.create.co")}

            </h2>
            <Separator className="mb-6" />
            <div className="space-y-6">
              <div className="space-y-2">
                <Label htmlFor="fullname" className="text-sm font-medium">
                {t("Customers.create.fn")}

                </Label>
                <Input
                  id="fullname"
                  className="w-full"
                  value={client.fullname}
                  onChange={(e) =>
                    setClient({ ...client, fullname: e.target.value })
                  }
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="phone_number" className="text-sm font-medium">
                {t("Customers.create.pn")}

                </Label>
                <Input
                  id="phone_number"
                  className="w-full"
                  value={client.phone_number}
                  onChange={(e) =>
                    setClient({ ...client, phone_number: e.target.value })
                  }
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="email" className="text-sm font-medium">
                {t("Customers.create.em")}

                </Label>
                <Input
                  id="email"
                  type="email"
                  className="w-full"
                  value={client.email}
                  onChange={(e) =>
                    setClient({ ...client, email: e.target.value })
                  }
                />
              </div>
            </div>
          </motion.div>
        </Card>
      </motion.div>
      <ActionFooter
        icon={<Save />}
          
        text={t("Customers.create.title")}
        action={handleAddCustomer}
        isPending={addClientMutation.isPending}
      />
    </>
  );
}
