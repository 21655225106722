import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import ActionFooter from "@/AppComponents/ActionFooter";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Save } from "lucide-react";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import { fetchIntegration, upsertIntegration } from "@/utils/DeliveryUtils";
import { toast } from "@/components/ui/use-toast";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";

const company = "Ecotrack";
const companyId = 2;

export default function DeliveryEcotrack() {
  const [integration, setIntegration] = useState({
    apiToken: "",
    url: "",
  });

  const queryClient = useQueryClient();

  const { data: integrationData, isLoading } = useQuery({
    queryKey: ["integration", company],
    queryFn: () => fetchIntegration(company),
  });

  useEffect(() => {
    if (integrationData?.integration) {
      setIntegration({
        apiToken: integrationData.integration.apiToken || "",
        url: integrationData.integration.url || "",
      });
    }
  }, [integrationData]);

  const upsertIntegrationMutation = useMutation({
    mutationFn: () =>
      upsertIntegration(
        company,
        companyId,
        integrationData?.integration?.id || 0,
        integration,
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["integration", company, companyId],
      });
      toast({ title: "Integration saved successfully" });
    },
    onError: (error) => {
      toast({
        title: "Error saving integration",
        description: error.message,
        variant: "destructive",
      });
    },
  });

  const handleSave = () => {
    if (!integration.apiToken || !integration.url) {
      toast({
        title: "Please fill in both Token and URL",
        variant: "destructive",
      });
      return;
    }
    upsertIntegrationMutation.mutate();
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { staggerChildren: 0.1 } },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: { y: 0, opacity: 1 },
  };

  return (
    <motion.div
      className="container pb-20"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <h1 className="text-2xl font-bold mb-6">ECOTRACK Delivery Integration</h1>

      <Card className="p-6 space-y-4">
        <motion.div variants={itemVariants}>
          <Label htmlFor="token">API Token</Label>
          <Input
            id="token"
            value={integration.apiToken}
            onChange={(e) =>
              setIntegration({ ...integration, apiToken: e.target.value })
            }
            placeholder="Enter API Token"
          />
        </motion.div>
        <motion.div variants={itemVariants}>
          <Label htmlFor="url">URL</Label>
          <Input
            id="url"
            value={integration.url}
            onChange={(e) =>
              setIntegration({ ...integration, url: e.target.value })
            }
            placeholder="Enter URL"
          />
        </motion.div>
        <motion.div variants={itemVariants}>
          <Button
            onClick={handleSave}
            disabled={upsertIntegrationMutation.isPending || isLoading}
            className="w-full"
          >
            {upsertIntegrationMutation.isPending
              ? "Saving..."
              : "Save Integration"}
          </Button>
        </motion.div>
      </Card>
    </motion.div>
  );
}
