import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "@tanstack/react-query";
import {
  ClipboardIcon,
  RefreshCw,
  ExternalLink,
  AlertCircle,
} from "lucide-react";
import { StoreContext } from "@/utils/StoreContext";
import Axios from "@/utils/Axios";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";

export default function Component() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { storeData: store } = useContext(StoreContext);

  const handleRegenerate = async () => {
    await Axios.get("/api/telegram/regenerate");
    queryClient.invalidateQueries({queryKey:["store"]});
  };

  return (
    <div className="container mx-auto px-4">
    <Card className="mb-8">
      <CardHeader>
        <CardTitle className="text-2xl font-bold">
          {t("telegramnot.label")}
        </CardTitle>
        <CardDescription>
          {t("telegramnot.description")}
        </CardDescription>
      </CardHeader>
      <CardContent className="space-y-6">
        <div className="space-y-2">
          <Label htmlFor="store-name" className="text-lg font-medium">
            {t("telegramnot.tokenLabel")}
          </Label>
          <div className="flex flex-col sm:flex-row gap-2">
            <div className="relative flex-grow">
              <Input
                id="store-name"
                disabled
                value={store?.telegramToken}
                className="pr-10"
              />
              <Button
                onClick={() =>
                  navigator.clipboard.writeText(store?.telegramToken)
                }
                size="icon"
                variant="ghost"
                className="absolute right-0 top-0 h-full"
                aria-label={t("telegramnot.copyTokenAria")}
              >
                <ClipboardIcon size={20} />
              </Button>
            </div>
            <Button
              onClick={handleRegenerate}
              className="w-full text-white sm:w-auto"
            >
              <RefreshCw className="mr-2 h-4 w-4" />{" "}
              {store?.telegramToken ? (
                <>{t("telegramnot.regenerateToken")}</>
              ) : (
                <>{t("telegramnot.generateToken")}</>
              )}
            </Button>
          </div>
        </div>
      </CardContent>
    </Card>
  
    <Card>
      <CardHeader>
        <CardTitle className="text-xl font-semibold">
          {t("telegramnot.setupInstructionsTitle")}
        </CardTitle>
      </CardHeader>
      <CardContent className="space-y-4">
        <Alert>
          <AlertCircle className="h-4 w-4" />
          <AlertTitle>{t("telegramnot.importantAlertTitle")}</AlertTitle>
          <AlertDescription>
            {t("telegramnot.importantAlertDescription")}
          </AlertDescription>
        </Alert>
        <ol className="list-decimal list-inside space-y-2">
          <li>{t("telegramnot.instruction1")}</li>
          <li>
            {t("telegramnot.instruction2")}{" "}
            <a
              href="https://t.me/larbizyoBot"
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary hover:underline inline-flex items-center"
            >
              {t("telegramnot.clickHere")} <ExternalLink className="ml-1 h-4 w-4" />
            </a>{" "}
            {t("telegramnot.orScanQR")}
          </li>
          <li>{t("telegramnot.instruction3")}</li>
          <li>{t("telegramnot.instruction4")}</li>
        </ol>
        <div className="w-full max-w-xs mx-auto">
          <img
            src="/telegram_bot_qr.jpg"
            alt="Telegram Bot QR Code"
            className="w-full h-auto object-contain rounded-lg shadow-md"
          />
        </div>
      </CardContent>
      <CardFooter>
        <p className="text-sm text-muted-foreground">
          {t("telegramnot.footerText")}
        </p>
      </CardFooter>
    </Card>
  </div>
  
  );
}
