import { toast } from "@/components/ui/use-toast";
import Axios from "./Axios";

export const fetchCategories = async (page, pageSize) => {
  return new Promise((resolve, reject) => {
    Axios.get("api/getcategories", {
      params: {
        page,
        pageSize,
      },
    }).then((res) => {
      if (res.status === 200) {
        resolve(res.data);
      } else {
        reject();
      }
    });
  });
};

export const addCategory = async (category) => {
  return new Promise((resolve, reject) => {
    Axios.post("/api/addcategory", {
      category,
    }).then((value) => {
      if (value.status === 200) {
        toast({ title: "category added successfully" });
        resolve(value);
      } else {
        reject();
      }
    });
  });
};


export const deleteCategory=async(id)=>{
  return new Promise(async(resolve,reject)=>{
    Axios.delete(`api/delete_category/${id}`).then((res)=>{
      resolve(res.data)
    }).catch((err)=>{
      reject(err)
    })
  })
}