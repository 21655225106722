import { useEffect, useState } from "react";

import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";

import ActionFooter from "@/AppComponents/ActionFooter";
import { LoaderCircleIcon, Plus, Trash } from "lucide-react";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import EmptyTableComponent from "@/AppComponents/EmptyTableComponent";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import PaginationComponent from "@/AppComponents/PaginationComponent";

import { Switch } from "@/components/ui/switch";

import {
  deleteProduct,
  fetchProducts,
  updateVisibility,
} from "@/utils/ProductUtils";
import { AlertDialogC } from "@/AppComponents/AlertDialogC";
import { toast } from "@/components/ui/use-toast";
import LoaderCircle from "@/AppComponents/LoaderCircle";
import EditProduct from "./EditProduct";
import { useTranslation } from "react-i18next";
import { prepareProducts } from "@/utils/csv/prepare";
import CSVExportButton from "@/AppComponents/CSVExportButton";
import { Badge } from "@/components/ui/badge";
import { Skeleton } from "@/components/ui/skeleton";
import { Avatar, AvatarImage } from "@/components/ui/avatar";

export default function Products() {
  const { t } = useTranslation();
  const [filter, setfilter] = useState("");
  const [Products, setProducts] = useState([]);
  const columns = [
    t("Products.inside.table.p"),
    t("Products.inside.table.n"),
    t("Products.inside.table.pr"),
    t("Products.inside.table.q"),
    t("Products.inside.table.v"),
    t("Products.inside.table.vi"),
    t("Products.inside.table.e"),
    t("Products.inside.table.r"),
  ];
  const [page, setpage] = useState(1);
  const [pageSize, setpagesize] = useState(8);
  const [isUpdating, setisUpdating] = useState(null);
  const { data: products, isLoading } = useQuery({
    queryKey: ["products", page, pageSize],
    queryFn: () => fetchProducts(page, pageSize),
  });
  const queryClient = useQueryClient();
  const deleteProductMutation = useMutation({
    mutationFn: (id) => deleteProduct(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["products"] });
      toast({ description:t("alert.confirm_title"),variant:"default"});

    },
    onError: () => {
      toast({ description:t("alert.err_title"),variant:"destructive"});

      deleteProductMutation.reset();
    },
  });
  const updateVisibilityMutation = useMutation({
    mutationFn: (variables) => updateVisibility(variables),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["products"] });
      toast({ description:t("alert.confirm_title"),variant:"default"});

      setisUpdating(null);
    },
    onError: () => {
      setisUpdating(null);
      toast({ description:t("alert.err_title"),variant:"destructive"});

      updateVisibilityMutation.reset();
    },
  });
  useEffect(() => {
    if (products?.products) {
      setProducts(products?.products);
      console.log("products are: ", products?.products);
    }
  }, [products]);

  useEffect(() => {
    if (Array.isArray(Products) && Products.length > 0 && filter !== "") {
      const filteredItems = Products.filter((item) =>
        item?.name.toLowerCase().includes(filter.toLowerCase()),
      );
      setProducts(filteredItems);
    } else if (products?.products) {
      setProducts(products.products);
    }
  }, [filter, products]);
  const handleUpdateVisibility = (id, value) => {
    setisUpdating(id);
    updateVisibilityMutation.mutate({
      id: id,
      status: value,
    });
  };
  const pages = Math.ceil(products?.count / pageSize);
  // if(deleteProductMutation.isPending || isLoading) return <LoaderCircle/>
  
  if (products?.count === 0)
    return <EmptyTableComponent label="product" to="/dashboard/products/new" />;

  return (
    <>
      <div className="flex flex-col lg:px-4  ">
        <div className="flex-1">
          <div className="p-2">
            <Card className="p-2 my-2">
              <div className="flex flex-col space-y-4 ">
                <div className="flex space-x-2">
                  <Input
                    className="font-bold   "
                    color="none"
                    placeholder={t("Products.inside.search")}
                    onChange={(e) => setfilter(e.target.value)}
                  />
                  <Select onValueChange={(value) => setpagesize(parseInt(value))}>
                    <SelectTrigger className=" w-fit">
                      <SelectValue placeholder={t("btns.items")} />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        <SelectLabel>{t("btns.items")}</SelectLabel>
                        {[8, 16, 24, 50, 100].map((size) => (
                        <SelectItem key={size} value={size}>
                          {size}
                        </SelectItem>
                      ))}
                      </SelectGroup>
                    </SelectContent>
                  </Select>

                  <CSVExportButton
                    data={products?.products}
                    filename="products.csv"
                    prepareData={prepareProducts}
                  />
                </div>
                {isLoading ? (
                  <LoaderCircle />
                ) : (
                  <Table>
                    <TableHeader>
                      <TableRow className="text-start">
                        {columns &&
                          columns.map((col, index) => (
                            <TableHead className="text-start">{col}</TableHead>
                          ))}
                        <TableHead></TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {Products &&
                        Products?.map((product, i) => (
                          <TableRow>
                            <TableCell className="w-20">
                             <Avatar>
                              <AvatarImage 
                               src={product.Imageurl}
                                alt={product.name}/>
                             </Avatar>
                            </TableCell>
                            <TableCell>{product.name}</TableCell>
                            <TableCell>{product.price}</TableCell>
                            <TableCell>{product.stock_level}</TableCell>

                            <TableCell>
                              {product.id === isUpdating ? (
                                <LoaderCircleIcon className="animate-spin" />
                              ) : (
                                <Switch
                                  dir="ltr"
                                  onCheckedChange={(value) =>
                                    handleUpdateVisibility(product.id, value)
                                  }
                                  checked={product.visibility}
                                />
                              )}
                            </TableCell>
                            <TableCell>
                              <Badge>{product?.visits}</Badge>
                            </TableCell>
                            <TableCell>
                              <EditProduct currentProduct={product} />
                            </TableCell>
                            <TableCell>
                              <AlertDialogC
                                action={() =>
                                  deleteProductMutation.mutate(product.id)
                                }
                              >
                                <Trash size="20" />
                              </AlertDialogC>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                )}
                <PaginationComponent
                  pages={pages}
                  setpage={setpage}
                  currentpage={page}
                />
              </div>
            </Card>
          </div>
        </div>
      </div>
      <ActionFooter
        text={t("Products.inside.add")}
        type="link"
        to={"/dashboard/products/new"}
        icon={<Plus />}
      />
    </>
  );
}
