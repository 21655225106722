import { Button, buttonVariants } from '@/components/ui/button'
import { PackageIcon, PlusCircle, PlusIcon } from 'lucide-react'
import React from 'react'
import { Link } from 'react-router-dom'

export default function EmptyTableComponent({label,to}) {
  return (
    <div className="flex flex-col min-h-screen">
      
      <main className="flex-1">
        <section className="w-full py-12 md:py-24 lg:py-32">
          <div className="container px-4 md:px-6">
            <div className="flex flex-col items-center justify-center space-y-4 text-center">
              <div className="space-y-2">
                <h1 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl lg:text-6xl/none">
                  No Data Available
                </h1>
                <p className="mx-auto max-w-[700px] text-gray-500 md:text-xl dark:text-gray-400">
                  It looks like there's no data to display yet. Start by adding some new items to your collection.
                </p>
              </div>
              <div className="space-x-4">
                <Link className={buttonVariants({variant:"default"})} to={to}>
                  <PlusCircle className="mr-2 h-4 w-4" />
                  Add New Item
                </Link>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  )
}

