import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { format } from "date-fns";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { CalendarIcon } from "lucide-react";

import LoaderCircle from "@/AppComponents/LoaderCircle";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import { Card, CardContent } from "@/components/ui/card";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { toast } from "@/components/ui/use-toast";
import { cn } from "@/lib/utils";
import { addCoupon } from "@/utils/CouponUtils";
import { fetchProducts } from "@/utils/ProductUtils";
import { useTranslation } from "react-i18next";

interface CouponData {
  title: string;
  type: "AMOUNT" | "PERCENT";
  value: number | null;
  validUntil: Date | null;
  productIds: string[];
}

export default function ResponsiveAddCoupon() {
  const [coupon, setCoupon] = useState<CouponData>({
    title: "",
    type: "AMOUNT",
    value: null,
    validUntil: null,
    productIds: [],
  });
  const {t}=useTranslation()
  const [errors, setErrors] = useState<Partial<CouponData>>({});
  const [date, setDate] = useState<Date | undefined>(new Date());

  useEffect(() => {
    if (date) {
      setCoupon((prev) => ({ ...prev, validUntil: date }));
    }
  }, [date]);

  const { data: products, isLoading } = useQuery({
    queryKey: ["products"],
    queryFn: () => fetchProducts(1, 20),
  });

  const queryClient = useQueryClient();
  const addCouponMutation = useMutation({
    mutationFn: () => addCoupon(coupon),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["coupons"] });
      setCoupon({
        title: "",
        type: "AMOUNT",
        value: null,
        validUntil: null,
        productIds: [],
      });
      toast({ description: t("alert.confirm_title") });
      addCouponMutation.reset()
    },
    onError: () => {
      toast({ description:t("alert.err_title"),variant:"destructive"});

      addCouponMutation.reset()
    },
  });

  const validateCoupon = (): boolean => {
    const newErrors: Partial<CouponData> = {};

    if (!coupon.title) {
      newErrors.title = "Coupon code is required";
    } else if (!/^[A-Z0-9]+$/.test(coupon.title)) {
      newErrors.title =
        "Coupon code must contain only uppercase letters and numbers";
    }

    if (coupon.value === null || coupon.value <= 0) {
      newErrors.value = "Discount value must be greater than 0";
    }

    if (!coupon.validUntil) {
      newErrors.validUntil = "Validity period is required";
    }

    if (coupon.productIds.length === 0) {
      newErrors.productIds = "At least one product must be selected";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if(coupon.productIds.length===0||coupon.title==""||coupon.validUntil==null||coupon.value==null){
      toast({
        title: t("alert.err_title"),
        description: t("alert.err_d"),
        variant: "destructive",
      });
    }
    if (validateCoupon()) {
      addCouponMutation.mutate();
    }
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  return (
    <motion.div
    className="p-2"
    variants={containerVariants}
    initial="hidden"
    animate="visible"
  >
    <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between mb-6 p-4">
      <h1 className="text-2xl font-bold mb-4 sm:mb-0">{t("addcoupon.pageTitle")}</h1>
      <Button onClick={handleSubmit} disabled={addCouponMutation.isPending}>
        {addCouponMutation.isPending ? (
          <LoaderCircle className="mr-2" />
        ) : null}
        {t("addcoupon.saveChanges")}
      </Button>
    </div>
    <Card>
      <CardContent className="p-4 sm:p-6">
        <motion.div className="grid gap-6" variants={itemVariants}>
          <div className="grid gap-2">
            <Label htmlFor="code">{t("addcoupon.code")}</Label>
            <Input
              id="code"
              onChange={(e) =>
                setCoupon({ ...coupon, title: e.target.value.toUpperCase() })
              }
              value={coupon.title}
              aria-invalid={!!errors.title}
              aria-describedby="code-error"
            />
            {errors.title && (
              <p id="code-error" className="text-sm text-red-500">
                {errors.title}
              </p>
            )}
          </div>
          <div className="grid gap-2">
            <Label htmlFor="discount-value">{t("addcoupon.discountValue")}</Label>
            <Input
              id="discount-value"
              type="number"
              onChange={(e) =>
                setCoupon({ ...coupon, value: e.target.valueAsNumber })
              }
              value={coupon.value ?? ""}
              min="0"
              step="0.01"
              aria-invalid={!!errors.value}
              aria-describedby="value-error"
            />
            {errors.value && (
              <p id="value-error" className="text-sm text-red-500">
                {errors.value}
              </p>
            )}
          </div>
          <div className="grid gap-2">
            <Label htmlFor="discount-type">{t("addcoupon.discountType")}</Label>
            <Select
              onValueChange={(value) =>
                setCoupon({ ...coupon, type: value as "AMOUNT" | "PERCENT" })
              }
              value={coupon.type}
            >
              <SelectTrigger id="discount-type">
                <SelectValue placeholder={t("addcoupon.selectDiscountType")} />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectLabel>{t("addcoupon.productsLabel")}</SelectLabel>
                  <SelectItem value="AMOUNT">Fixed Amount</SelectItem>
                  <SelectItem value="PERCENT">Percentage</SelectItem>
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
          <div className="grid gap-2">
            <Label htmlFor="validity-period">{t("addcoupon.validityPeriod")}</Label>
            <Popover>
              <PopoverTrigger asChild>
                <Button
                  id="validity-period"
                  variant="outline"
                  className={cn(
                    " justify-start text-left font-normal w-full",
                    !date && "text-muted-foreground"
                  )}
                  aria-invalid={!!errors.validUntil}
                  aria-describedby="validity-error"
                >
                  <CalendarIcon className="mr-2 h-4 w-4" />
                  {date ? format(date, "PPP") : <span>{t("addcoupon.pickDate")}</span>}
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-auto p-0" align="start">
                <Calendar
                  mode="single"
                  selected={date}
                  onSelect={setDate}
                  initialFocus
                />
              </PopoverContent>
            </Popover>
            {errors.validUntil && (
              <p id="validity-error" className="text-sm text-red-500">
                {errors.validUntil}
              </p>
            )}
          </div>
          <div className="grid gap-2">
            <Label>{t("addcoupon.selectProducts")}</Label>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button className=" w-full" variant="outline">
                  {t("addcoupon.selectProducts")} ({coupon.productIds.length} {t("selected")})
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-64 max-h-60 overflow-y-auto">
                <DropdownMenuLabel>{t("addcoupon.productsLabel")}</DropdownMenuLabel>
                <DropdownMenuSeparator />
                {isLoading ? (
                  <div className="p-2 text-center">{t("addcoupon.loadingProducts")}</div>
                ) : (
                  products?.products?.map((product) => (
                    <DropdownMenuCheckboxItem
                      key={product.id}
                      checked={coupon.productIds.includes(product.id)}
                      onCheckedChange={(checked) => {
                        setCoupon((prev) => ({
                          ...prev,
                          productIds: checked
                            ? [...prev.productIds, product.id]
                            : prev.productIds.filter(
                                (id) => id !== product.id
                              ),
                        }));
                      }}
                    >
                      {product.name}
                    </DropdownMenuCheckboxItem>
                  ))
                )}
              </DropdownMenuContent>
            </DropdownMenu>
            {errors.productIds && (
              <p className="text-sm text-red-500">{errors.productIds}</p>
            )}
          </div>
        </motion.div>
      </CardContent>
    </Card>
  </motion.div>
  
  );
}
