import { useState, useEffect, useContext } from "react";
import { PlusCircle, Globe, Trash2, ExternalLink, Copy } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { toast } from "@/components/ui/use-toast";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchDomains } from "@/utils/DomainUtils";
import { Link } from "react-router-dom";
import Axios from "@/utils/Axios";
import { useTranslation } from "react-i18next";
import hasAccess from "@/utils/access/hasAccess";
import { StoreContext } from "@/utils/StoreContext";

type Domain = {
  id: number;
  storeId: number;
  value: string;
};

export default function Domains() {
  const [Domains, setDomains] = useState<Domain[]>([]);
  const [domainToAdd, setDomainToAdd] = useState("");
  const { t } = useTranslation();
  const { data: domains, isLoadingDomains } = useQuery({
    queryKey: ["domains"],
    queryFn: () => fetchDomains(),
  });

  const queryClient = useQueryClient();

  const addDomainMutation = useMutation({
    mutationFn: (name) => Axios.post("/api/domain/add", { value: name }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["domains"] });
      setDomainToAdd("");
      toast({ title: "Domain added successfully" });
    },
    onError: () => {
      toast({ description:t("alert.err_title"),variant:"destructive"});

    },
  });

  const deleteDomainMutation = useMutation({
    mutationFn: (id) => Axios.delete(`/api/domain/delete/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["domains"] });
      setDomainToAdd("");
      toast({ description:t("alert.confirm_title"),variant:"default"});

    },
    onError: () => {
      toast({ description:t("alert.err_title"),variant:"destructive"});

    },
  });

  useEffect(() => {
    if (domains) {
      setDomains(domains?.data);
    }
  }, [domains]);

  const addDomain = () => {
    addDomainMutation.mutate(domainToAdd);
  };

  const removeDomain = (domainId: number) => {
    deleteDomainMutation.mutate(domainId);
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    toast({
      title: "Copied to clipboard",
      description: "The CNAME record has been copied to your clipboard.",
    });
  };
  const { storeData: store } = useContext(StoreContext);

    
  return (
    <div className="container mx-auto px-4 py-8 space-y-8">
      <h1 className="text-4xl font-bold mb-6 text-center">
        {t("RealSett.inside.domain.main_title")}
      </h1>

      <Card>
        <CardHeader>
          <CardTitle>{t("RealSett.inside.domain.add")}</CardTitle>
          <CardDescription>{t("RealSett.inside.domain.add_d")}</CardDescription>
        </CardHeader>
        <CardContent>
          <div className="flex flex-col sm:flex-row gap-4">
            <Input
              type="text"
              placeholder={t("RealSett.inside.domain.add_i_p")}
              value={domainToAdd}
              onChange={(e) => setDomainToAdd(e.target.value)}
              className="flex-grow"
            />
            <Button
              disabled={addDomainMutation.isPending || !hasAccess(store?.user?.subscriptions[0]?.plan,{action:"addDomains",limit:store?._count.domains})}
              onClick={addDomain}
              className="w-full sm:w-auto">
              <PlusCircle className="mr-2 h-4 w-4" />{" "}
              {t("RealSett.inside.domain.btn")}
            </Button>
          </div>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>{t("RealSett.inside.domain.your")}</CardTitle>
          <CardDescription>
            {t("RealSett.inside.domain.your_d")}
          </CardDescription>
        </CardHeader>
        <CardContent>
          {Domains?.length > 0 ? (
            <Table >
              <TableHeader className="">
                <TableRow className="">
                  <TableHead className="flex-1 text-start">
                    {t("RealSett.inside.domain.d")}
                  </TableHead>
                  <TableHead className="flex-1 text-end">
                    {t("RealSett.inside.domain.a")}
                  </TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {Domains?.map((domain) => (
                  <TableRow key={domain.value}>
                    <TableCell className="font-medium">
                      {domain.value}
                    </TableCell>
                    <TableCell className="flex justify-end" >
                      <div className="flex gap-4" >
                        <Button variant="outline" size="sm">
                          <Link to={`https://${domain.value}`} target="_blank">
                            <ExternalLink className="h-4 w-4" />
                          </Link>
                        </Button>
                        <Button
                          variant="destructive"
                          size="sm"
                          onClick={() => removeDomain(domain.id)}>
                          <Trash2 className="h-4 w-4" />
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <Alert>
              <Globe className="h-4 w-4" />
              <AlertTitle>{t("RealSett.inside.domain.domain_no")}</AlertTitle>
              <AlertDescription>
                {t("RealSett.inside.domain.domain_no_d")}
              </AlertDescription>
            </Alert>
          )}
        </CardContent>
      </Card>
      <Card>
        <CardHeader>
          <CardTitle>{t("RealSett.inside.domain.how")}</CardTitle>
          <CardDescription>{t("RealSett.inside.domain.how_d")}</CardDescription>
        </CardHeader>
        <CardContent>
          <div className="space-y-6">
            <div>
              <h3 className="text-lg font-semibold mb-2">
                {t("RealSett.inside.domain.cname")}
              </h3>
              <ol className="list-decimal text-sm text-muted-foreground list-inside space-y-2 mb-4">
                <li>{t("RealSett.inside.domain.cname_d_1")}</li>
                <li>{t("RealSett.inside.domain.cname_d_2")}</li>
                <li>{t("RealSett.inside.domain.cname_d_3")}</li>
              </ol>
              <div className="bg-muted p-4 rounded-md font-mono text-sm mb-4">
                <div className="flex justify-between items-center">
                  <span>Host: www</span>
                  <span>Points to: keeprify.com</span>
                  <span>TTL: 3600</span>
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={() =>
                      copyToClipboard("www CNAME keeprify.com 3600")
                    }>
                    <Copy className="h-4 w-4" />
                  </Button>
                </div>
              </div>
            </div>
            <div>
              <h3 className="text-lg font-semibold mb-2">
                {t("RealSett.inside.domain.ns")}
              </h3>
              <ol className="list-decimal text-sm text-muted-foreground list-inside space-y-2 mb-4">
                <li>{t("RealSett.inside.domain.cname_d_1")}</li>

                <li>{t("RealSett.inside.domain.cname_d_4")}</li>
                <li>{t("RealSett.inside.domain.cname_d_5")}</li>
              </ol>
              <div className="bg-muted p-4 rounded-md font-mono text-sm mb-4">
                <div className="flex flex-col space-y-2">
                  <div className="flex justify-between items-center">
                    <span>ns1.keeprify.com</span>
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={() => copyToClipboard("ns1.keeprify.com")}>
                      <Copy className="h-4 w-4" />
                    </Button>
                  </div>
                  <div className="flex justify-between items-center">
                    <span>ns2.keeprify.com</span>
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={() => copyToClipboard("ns2.keeprify.com")}>
                      <Copy className="h-4 w-4" />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <Alert>
              <AlertTitle className="flex items-center">
                <Globe className="h-4 w-4 mr-2" />
                {t("RealSett.inside.domain.note")}
              </AlertTitle>
              <AlertDescription>
                {t("RealSett.inside.domain.note_d")}
              </AlertDescription>
            </Alert>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
