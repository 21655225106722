import { toast } from "@/components/ui/use-toast";
import Axios from "./Axios";

export const fetchProducts = async (page, pageSize) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await Axios.get("api/admin_products", {
        params: {
          page,
          pageSize,
        },
      });
      if (res.status === 200) {
        resolve(res.data);
      } else {
        throw new Error("couldnt fetch products");
      }
    } catch (e) {
      reject();
    }
  });
};

export const addProduct = async (
  product,
  variantOptions,
  offers,
  images,
  mainImageIndex,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      console.log("main idnex is: ", mainImageIndex);
      const res = await Axios.post("api/create_product", {
        ...product,
        attributes: variantOptions,
        offers: offers,
      });
      if (res.status === 200) {
        console.log("res.data is: ", res.data);

        const formData = new FormData();

        // Add mainImageIndex
        formData.append("mainImageIndex", mainImageIndex);
        formData.append("productId", res.data.product.id);

        // Add all images
        images.forEach((image) => {
          formData.append("files", image);
        });

        console.log("from data is: ", formData);
        const result = await Axios.post("api/upload/image", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log("result is: ", result);
        if (result.status === 200) {
          resolve(res);
        }
      } else throw new Error(res.data.error);
    } catch (e) {
      reject(e);
      toast({ title: "error" });
    }
  });
};
export const deleteProduct = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await Axios.delete(`api/delete_product/${id}`);
      if (res.status === 200) {
        resolve(res);
      } else throw new Error(res.data.error);
    } catch (e) {
      reject(e);
    }
  });
};

export const updateProduct = async (
  id,
  product,
  attributes,
  offers,
  images,
  imagesToAdd,
  imagesToDelete,
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await Axios.put(`api/update_product/${id}`, {
        ...product,
        attributes: attributes,
        offers: offers,
        images: images,
        ToAdd: imagesToAdd,
        ToDelete: imagesToDelete,
      });
      if (res.status === 200) {
        resolve(res);
      } else throw new Error(res.data.error);
    } catch (e) {
      reject(e);
    }
  });
};

export const updateVisibility = async (args) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await Axios.put(`api/product_visibility/${args.id}`, {
        status: args.status,
      });
      if (res.status === 200) {
        resolve(res);
      } else throw new Error(res.data.error);
    } catch (e) {
      reject(e);
    }
  });
};
