import { motion } from "framer-motion";

export default function HomeLoading() {
  return (
    <div className="flex items-center justify-center w-full h-full">
      <svg width="100" height="100" viewBox="0 0 100 100">
        <defs>
          <clipPath id="k-clip">
            <text
              x="50"
              y="75"
              fontSize="80"
              fontFamily="Optima, sans-serif"
              textAnchor="middle"
              fill="white"
            >
              k
            </text>
          </clipPath>
        </defs>
        <text
          x="50"
          y="75"
          fontSize="80"
          fontFamily="Optima, sans-serif"
          textAnchor="middle"
          fill="white"
          stroke="#581c87"
          strokeWidth="2"
        >
          k
        </text>
        <motion.rect
          x="0"
          y="0"
          width="100"
          height="100"
          fill="#581c87"
          clipPath="url(#k-clip)"
          initial={{ y: 100 }}
          animate={{ y: 0 }}
          transition={{
            duration: 1.5,
            ease: "easeInOut",
            repeat: Infinity,
            repeatType: "reverse",
          }}
        />
      </svg>
    </div>
  );
}
