import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Axios from "@/utils/Axios";
import LoaderCircle from "@/AppComponents/LoaderCircle";
import HomeLoading from "@/AppComponents/HomeLoading";

const AuthProvider = ({ nextRoute, children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const res = await Axios.get("/auth/checkauth");
        const data = await res.data;
        // console.log("data is ", data);
        if (data.isAuthenticated) {
          setIsAuthenticated(true);
          if (nextRoute) navigate(`${nextRoute}`);
        } else {
          navigate("/login");
        }
      } catch (error) {
        console.error("Error checking authentication:", error);
        navigate("/login");
      }
    };

    checkAuth();
  }, [navigate, nextRoute]);

  if (!isAuthenticated) {
    return (
      <div className="h-screen w-full items-center justify-center">
        <HomeLoading />
      </div>
    );
  }

  return <>{children}</>;
};

export default AuthProvider;
