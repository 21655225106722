import { useState, useEffect } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Pen } from "lucide-react";
import { AlertDialogHeader } from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { toast } from "@/components/ui/use-toast";
import { updateProduct } from "@/utils/ProductUtils";
import LoaderCircle from "@/AppComponents/LoaderCircle";

export default function EditStock({ currentProduct }) {
  const [product, setProduct] = useState({
    stock_level: 0,
  });
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (currentProduct) {
      setProduct({
        stock_level: parseInt(currentProduct.stock_level),
      });
    }
  }, [currentProduct]);

  const queryClient = useQueryClient();
  const updateProductMutation = useMutation({
    mutationFn: () => updateProduct(currentProduct.id, product, null),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["products"] });
      toast({ title: "Product updated" });
      setOpen(false);
    },
  });

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button size="icon" variant="ghost">
          <Pen className="h-5 w-5" />
          <span className="sr-only">Edit stock level</span>
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <AlertDialogHeader>
          <DialogTitle>Edit Stock Level</DialogTitle>
          <DialogDescription>
            Set the stock level for this product.
          </DialogDescription>
        </AlertDialogHeader>
        <div className="grid gap-4 py-4">
          <Card className="p-4">
            <Input
              id="stock-level"
              value={product.stock_level}
              onChange={(e) =>
                setProduct({
                  ...product,
                  stock_level: parseInt(e.target.value) || 0,
                })
              }
              className="h-14"
              placeholder="New stock level (e.g., 12, 14)"
              type="number"
              min="0"
            />
          </Card>
        </div>
        <DialogFooter>
          <Button
            onClick={() => updateProductMutation.mutate()}
            disabled={updateProductMutation.isPending}
          >
            {updateProductMutation.isPending ? (
              <>
                <LoaderCircle className="mr-2 h-4 w-4 animate-spin" />
                Saving...
              </>
            ) : (
              "Save changes"
            )}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
