import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import { motion } from "framer-motion";
import { User, KeyRound, Save, Loader2, Eye, EyeOff } from "lucide-react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { StoreContext } from "@/utils/StoreContext";
import Axios from "@/utils/Axios";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { toast } from "@/components/ui/use-toast";
import { fetchUser } from "@/utils/UserUtils";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import LoaderCircle from "@/AppComponents/LoaderCircle";

const accountInfoSchema = z.object({
  fullname: z.string().min(2, "Full name must be at least 2 characters"),
  email: z.string().email("Invalid email address"),
  phone_number: z.string().optional(),
});

const passwordChangeSchema = z
  .object({
    oldpassword: z.string().min(1, "Old password is required"),
    newpassword: z
      .string()
      .min(8, "Password must be at least 8 characters")
      .regex(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character",
      ),
    confirmPassword: z.string().min(1, "Please confirm your password"),
  })
  .refine((data) => data.newpassword === data.confirmPassword, {
    message: "Passwords don't match",
    path: ["confirmPassword"],
  })
  .refine((data) => data.oldpassword !== data.newpassword, {
    message: "New password cannot be the same as the old password",
    path: ["newpassword"],
  });

export default function Account() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(true);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showPersonalInfoDialog, setShowPersonalInfoDialog] = useState(false);
  const [showPasswordDialog, setShowPasswordDialog] = useState(false);

  const { data: account } = useQuery({
    queryKey: ["user"],
    queryFn: () => fetchUser(),
  });

  const accountInfoForm = useForm({
    resolver: zodResolver(accountInfoSchema),
    defaultValues: {
      fullname: "",
      email: "",
      phone_number: "",
    },
  });

  const passwordChangeForm = useForm({
    resolver: zodResolver(passwordChangeSchema),
    defaultValues: {
      oldpassword: "",
      newpassword: "",
      confirmPassword: "",
    },
  });

  useEffect(() => {
    if (account?.user) {
      accountInfoForm.reset({
        fullname: account.user.fullname || "",
        email: account.user.email || "",
        phone_number: account.user.phone_number || "",
      });
      setIsLoading(false);
    }
  }, [account, accountInfoForm]);

  const updateAccountMutation = useMutation({
    mutationFn: (data) => Axios.put("/api/update_user", data),
    onSuccess: (data) => {
      queryClient.invalidateQueries({queryKey:["user"]});
      toast({ description:t("alert.confirm_title"),variant:"default"});

      setShowPersonalInfoDialog(false);
    },
    onError: () => {
      toast({ description:t("alert.err_title"),variant:"destructive"});

    },
  });

  const changePasswordMutation = useMutation({
    mutationFn: (data) => Axios.post("/api/user/change_password", data),
    onSuccess: () => {
      passwordChangeForm.reset();
      toast({ title: t("password changed Successfully") });
      setShowPasswordDialog(false);
    },
    onError: () => {
      toast({ description:t("alert.err_title"),variant:"destructive"});

    },
  });

  const handleAccountUpdate = (data) => {
    updateAccountMutation.mutate(data);
  };

  const handlePasswordChange = (data) => {
    changePasswordMutation.mutate({
      oldpassword: data.oldpassword,
      newpassword: data.newpassword,
    });
  };

  const fadeInUp = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.5 },
  };

  if (isLoading) {
    return <LoaderCircle/>;
  }

  return (
    <div className="container mx-auto px-4 py-8 max-w-4xl">
      <motion.div {...fadeInUp}>
        <Card className="mb-8">
          <CardHeader>
            <CardTitle className="text-2xl font-bold flex items-center">
              <User className="mr-2" />
              {t("RealSett.inside.acc.d")}
            </CardTitle>
            <CardDescription>              {t("RealSett.inside.acc.ld")}
            </CardDescription>
          </CardHeader>
          <CardContent>
            <Form {...accountInfoForm}>
              <form
                onSubmit={accountInfoForm.handleSubmit(() =>
                  setShowPersonalInfoDialog(true),
                )}
                className="space-y-4"
              >
                <FormField
                  control={accountInfoForm.control}
                  name="fullname"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t("RealSett.inside.acc.accname")}</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={accountInfoForm.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t("RealSett.inside.acc.accem")}</FormLabel>
                      <FormControl>
                        <Input {...field} type="email" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={accountInfoForm.control}
                  name="phone_number"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t("RealSett.inside.acc.accphone")}</FormLabel>
                      <FormControl>
                        <Input {...field} type="tel" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <Button
                  type="submit"
                  className="w-full sm:w-auto"
                  disabled={updateAccountMutation.isPending}
                >
                  {updateAccountMutation.isPending ? (
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  ) : (
                    <Save className="mr-2 h-4 w-4" />
                  )}
                  {t("btns.save")}
                </Button>
              </form>
            </Form>
          </CardContent>
        </Card>
      </motion.div>

      <motion.div {...fadeInUp}>
        <Card>
          <CardHeader>
            <CardTitle className="text-2xl font-bold flex items-center">
              <KeyRound className="mr-2" />
              {t("RealSett.inside.acc.passd")}
              
            </CardTitle>
          </CardHeader>
          <CardContent>
            <Form {...passwordChangeForm}>
              <form
                onSubmit={passwordChangeForm.handleSubmit(() =>
                  setShowPasswordDialog(true),
                )}
                className="space-y-4"
              >
                <FormField
                  control={passwordChangeForm.control}
                  name="oldpassword"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>              {t("RealSett.inside.acc.current")}
                      </FormLabel>
                      <FormControl>
                        <div className="relative">
                          <Input
                            {...field}
                            type={showOldPassword ? "text" : "password"}
                          />
                          <Button
                            type="button"
                            variant="ghost"
                            size="sm"
                            className="absolute right-0 top-0 h-full px-3 py-2 hover:bg-transparent"
                            onClick={() => setShowOldPassword(!showOldPassword)}
                            aria-label={
                              showOldPassword
                                ? "Hide old password"
                                : "Show old password"
                            }
                          >
                            {showOldPassword ? (
                              <EyeOff className="h-4 w-4" />
                            ) : (
                              <Eye className="h-4 w-4" />
                            )}
                          </Button>
                        </div>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={passwordChangeForm.control}
                  name="newpassword"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t("RealSett.inside.acc.new")}</FormLabel>
                      <FormControl>
                        <div className="relative">
                          <Input
                            {...field}
                            type={showNewPassword ? "text" : "password"}
                          />
                          <Button
                            type="button"
                            variant="ghost"
                            size="sm"
                            className="absolute right-0 top-0 h-full px-3 py-2 hover:bg-transparent"
                            onClick={() => setShowNewPassword(!showNewPassword)}
                            aria-label={
                              showNewPassword
                                ? "Hide new password"
                                : "Show new password"
                            }
                          >
                            {showNewPassword ? (
                              <EyeOff className="h-4 w-4" />
                            ) : (
                              <Eye className="h-4 w-4" />
                            )}
                          </Button>
                        </div>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={passwordChangeForm.control}
                  name="confirmPassword"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t("RealSett.inside.acc.confirm")}</FormLabel>
                      <FormControl>
                        <Input {...field} type="password" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <Button
                  type="submit"
                  className="w-full sm:w-auto"
                  disabled={changePasswordMutation.isPending}
                >
                  {changePasswordMutation.isPending ? (
                    <Loader2 className="mx-2 h-4 w-4 animate-spin" />
                  ) : (
                    <KeyRound className="mx-2 h-4 w-4" />
                  )}
                  {t("RealSett.inside.acc.cp")}
                </Button>
              </form>
            </Form>
          </CardContent>
        </Card>
      </motion.div>

      <AlertDialog
        open={showPersonalInfoDialog}
        onOpenChange={setShowPersonalInfoDialog}
      >
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>
              {t("Confirm Personal Info Update")}
            </AlertDialogTitle>
            <AlertDialogDescription>
              {t("Are you sure you want to update your personal information?")}
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>{t("Cancel")}</AlertDialogCancel>
            <AlertDialogAction
              onClick={() => handleAccountUpdate(accountInfoForm.getValues())}
            >
              {t("Confirm")}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <AlertDialog
        open={showPasswordDialog}
        onOpenChange={setShowPasswordDialog}
      >
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>{t("Confirm Password Change")}</AlertDialogTitle>
            <AlertDialogDescription>
              {t("Are you sure you want to change your password?")}
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>{t("Cancel")}</AlertDialogCancel>
            <AlertDialogAction
              onClick={() =>
                handlePasswordChange(passwordChangeForm.getValues())
              }
            >
              {t("Confirm")}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
}
