import LoaderCircle from "@/AppComponents/LoaderCircle";
import SaveChangesButton from "@/AppComponents/SaveChangesButton";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { toast } from "@/components/ui/use-toast";
import { StoreContext } from "@/utils/StoreContext";
import { updateStore } from "@/utils/StoreUtils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

export default function GoogleAnalyticsTag() {
  const { t } = useTranslation();

  const { storeData: Store, setStore } = useContext(StoreContext);
  const queryClient = useQueryClient();

  const updateStoreMutation = useMutation({
    mutationFn: () => updateStore(Store),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["store"] });
      toast({ description:t("alert.confirm_title"),variant:"default"});

    },
    onError: () => {
      updateStoreMutation.reset();
      toast({ description:t("alert.err_title"),variant:"destructive"});

    },
  });
  if (!Store) return <LoaderCircle />;
  return (
    <Card className="max-w-4xl mx-auto p-4 space-y-8">
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
        <h2 className="text-2xl font-bold">Google Analytics Tag</h2>
        <SaveChangesButton
          isPending={updateStoreMutation.isPending}
          action={() => updateStoreMutation.mutate()}
          btnlabel={t("btns.save")}
        />
      </div>

      <div className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="store-name" className="text-lg font-medium">
            Google Analytics
          </Label>
          <Input
            id="store-name"
            value={Store?.googleAnalyticsTag}
            onChange={(e) =>
              setStore({ ...Store, googleAnalyticsTag: e.target.value })
            }
            placeholder="Google Analytics Id ex: G-XXXXXXXXXX"
            className="w-full"
          />
        </div>
      </div>
    </Card>
  );
}
