import React from "react";
import { motion } from "framer-motion";
import {
  CheckCircle,
  Circle,
  ArrowRight,
  ShoppingBag,
  Rocket,
} from "lucide-react";
import { Button } from "@/components/ui/button";
import { Progress } from "@/components/ui/progress";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { useNavigate } from "react-router-dom";

const tasks = [
  {
    id: 1,
    title: "Set up your store profile",
    description: "Add your store name, logo, and basic information.",
    url: "/dashboard/customize",
    icon: <ShoppingBag className="h-6 w-6" />,
  },
  {
    id: 2,
    title: "Add your first product",
    description: "Create a product listing with details, price, and images.",
    url: "/dashboard/products/new",
    icon: <Rocket className="h-6 w-6" />,
  },
  {
    id: 3,
    title: "Set up Delivery integration",
    description: "Define Delivery company",
    url: "/dashboard/plugins/delivery",
    icon: <ArrowRight className="h-6 w-6" />,
  },
  {
    id: 4,
    title: "Setup Telegram Notifications",
    description: "Setup telegram and get orders instantly",
    url: "/dashboard/plugins/telegram_notifications",
    icon: <Circle className="h-6 w-6" />,
  },
];

interface GetStartedPageProps {
  incompleteTasks: number[];
}

export default function GetStarted({ incompleteTasks }: GetStartedPageProps) {
  const navigate = useNavigate();

  const completedTasks = tasks.filter(
    (task) => !incompleteTasks?.includes(task.id),
  );
  const progress = (completedTasks.length / tasks.length) * 100;

  const handleTaskClick = (url: string, id: number) => {
    if (incompleteTasks.includes(id)) navigate(url);
  };

  return (
    <div className="min-h-screen ">
      <div className="container mx-auto px-4 py-12 max-w-4xl">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <h1 className="text-4xl font-bold text-center mb-8 text-purple-800">
            Get Started with Your Online Store
          </h1>
          <Card className="mb-8 shadow-lg">
            <CardHeader>
              <CardTitle className="flex items-center justify-between">
                <span className="text-2xl text-purple-700">Your Progress</span>
                <ShoppingBag className="h-8 w-8 text-purple-600" />
              </CardTitle>
            </CardHeader>
            <CardContent>
              <Progress value={progress} className="mb-4 h-3 bg-purple-100" />
              <p className="text-lg font-semibold text-purple-800">
                {completedTasks.length} of {tasks.length} tasks completed
              </p>
            </CardContent>
          </Card>
          <div className="space-y-6">
            {tasks.map((task) => (
              <motion.div
                key={task.id}
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.3, delay: task.id * 0.1 }}
              >
                <Card
                  className={`cursor-pointer transition-all duration-300 transform hover:scale-105 ${!incompleteTasks.includes(task.id)
                    ? "bg-green-50 border-green-200"
                    : "hover:bg-purple-50 hover:border-purple-200"
                    }`}
                  onClick={() => handleTaskClick(task.url, task.id)}
                >
                  <CardContent className="flex items-center p-6">
                    <div className="flex-shrink-0 mr-6">
                      {!incompleteTasks.includes(task.id) ? (
                        <div className="bg-green-100 p-3 rounded-full">
                          <CheckCircle className="h-8 w-8 text-green-500" />
                        </div>
                      ) : (
                        <div className="bg-purple-100 p-3 rounded-full">
                          {React.cloneElement(task.icon, {
                            className: "h-8 w-8 text-purple-500",
                          })}
                        </div>
                      )}
                    </div>
                    <div className="flex-grow">
                      <h3 className="text-xl font-semibold mb-2 text-purple-800">
                        {task.title}
                      </h3>
                      <p className="text-gray-600 text-lg">
                        {task.description}
                      </p>
                    </div>
                    <ArrowRight className="h-6 w-6 text-purple-400" />
                  </CardContent>
                </Card>
              </motion.div>
            ))}
          </div>
          <div className="mt-12 text-center">
            <Button
              size="lg"
              className="bg-purple-600 hover:bg-purple-700 text-white text-lg px-8 py-3 rounded-full transition-all duration-300 transform hover:scale-105"
              onClick={() => navigate("/dashboard")}
              disabled={incompleteTasks.length > 0}
            >
              {incompleteTasks.length === 0
                ? "Go to Dashboard"
                : "Complete All Tasks to Continue"}
            </Button>
          </div>
        </motion.div>
      </div>
    </div>
  );
}
