import React, { useState } from "react";
import { motion } from "framer-motion";
import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { Users, Zap, Globe, ChevronUp } from "lucide-react";
import { Link } from "react-router-dom";

const milestones = [
  { year: "2024-01", event: "Keeprify founded" },
  { year: "2024-04", event: "Launched first version of the platform" },
  { year: "2024-06", event: "Reached 1,000 active users" },
  { year: "2024-09", event: "Expanded to international markets" },
  { year: "2024-10", event: "Introduced AI-powered features" },
  // { year: 2023, event: "Surpassed 1 million transactions processed" },
];

export default function AboutUs() {
  const [showBackToTop, setShowBackToTop] = useState(false);
  const [hoveredMember, setHoveredMember] = useState<number | null>(null);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement;
    setShowBackToTop(target.scrollTop > 300);
  };

  const scrollToTop = () => {
    const scrollArea = document.querySelector(".scroll-area");
    if (scrollArea) {
      scrollArea.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  return (
    <motion.div
      className="container pt-40 font-circular mx-auto px-4 py-8 max-w-4xl"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <motion.div variants={itemVariants} className="mb-12">
        <Card className="overflow-hidden">
          <CardContent className="p-0">
            <div className="relative h-64 bg-[url('/keeprifysimple.png')] bg-center bg-no-repeat"></div>
            <motion.h1
              className="text-5xl font-bold text-center my-8"
              variants={itemVariants}
            >
              Empowering E-commerce with Keeprify
            </motion.h1>
            <div className="p-6">
              <p className="text-lg text-center">
                Keeprify is on a mission to revolutionize the way businesses
                manage their online presence. We provide cutting-edge e-commerce
                solutions that empower entrepreneurs and established businesses
                alike to thrive in the digital marketplace.
              </p>
            </div>
          </CardContent>
        </Card>
      </motion.div>

      <motion.div variants={itemVariants} className="mb-12">
        <h2 className="text-3xl font-semibold mb-6 text-center">
          Our Core Values
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {[
            {
              icon: <Zap className="h-8 w-8 mb-2" />,
              title: "Innovation",
              description:
                "Constantly pushing the boundaries of what's possible in e- commerce",
            },
            {
              icon: <Users className="h-8 w-8 mb-2" />,
              title: "Customer-Centric",
              description:
                "Putting our users' needs at the heart of everything we do",
            },
            {
              icon: <Globe className="h-8 w-8 mb-2" />,
              title: "Global Mindset",
              description:
                "Building solutions that work for businesses worldwide",
            },
          ].map((value, index) => (
            <Card
              key={index}
              className="text-center p-6 hover:shadow-lg transition-shadow"
            >
              <CardContent>
                {value.icon}
                <h3 className="text-xl font-semibold mb-2">{value.title}</h3>
                <p className="text-muted-foreground">{value.description}</p>
              </CardContent>
            </Card>
          ))}
        </div>
      </motion.div>

      <motion.div variants={itemVariants} className="mb-12">
        <h2 className="text-3xl font-semibold mb-6 text-center">Our Journey</h2>
        <div className="relative">
          <div className="absolute left-1/2 transform -translate-x-1/2 h-full w-1 bg-primary"></div>
          {milestones.map((milestone, index) => (
            <div
              key={index}
              className={`flex items-center mb-8 ${index % 2 === 0 ? "justify-start" : "justify-end"}`}
            >
              <motion.div
                className={`w-1/2 ${index % 2 === 0 ? "pr-8 text-right" : "pl-8"}`}
                initial={{ opacity: 0, x: index % 2 === 0 ? -50 : 50 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5 }}
              >
                <Badge variant="outline" className="mb-2">
                  {milestone.year}
                </Badge>
                <p className="text-lg">{milestone.event}</p>
              </motion.div>
              {/* {index % 2 === 0 ? ( */}
              {/*   <div className="w-4 h-4 bg-primary rounded-full z-10"></div> */}
              {/* ) : ( */}
              {/*   <div className="w-4 h-4 bg-primary rounded-full z-10"></div> */}
              {/* )} */}
            </div>
          ))}
        </div>
      </motion.div>

      <motion.div variants={itemVariants} className="text-center">
        <h2 className="text-3xl font-semibold mb-4">Get in Touch</h2>
        <p className="mb-6">
          Have questions or want to learn more about Keeprify? We'd love to hear
          from you!
        </p>
        <Button variant="outline" className="mr-4">
          <Link to={"/contact"}>Contact Us</Link>
        </Button>
      </motion.div>

      {showBackToTop && (
        <motion.div
          className="fixed bottom-8 right-8"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 20 }}
        >
          <Button onClick={scrollToTop} size="icon" variant="secondary">
            <ChevronUp className="h-4 w-4" />
          </Button>
        </motion.div>
      )}
    </motion.div>
  );
}
