import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { useQuery } from "@tanstack/react-query";
import { Code, Copy, ExternalLink } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { toast } from "@/components/ui/use-toast";
import LoaderCircle from "@/AppComponents/LoaderCircle";
import { fetchProducts } from "@/utils/ProductUtils";

// Placeholder for code generation
const generateWidgetCode = (productId) => {
  // return `<script src="https://keeprify.com/widget.js?product=${productId}"></script>`;
  return `<script src="http://94.74.103.64/embed/widget.js" data-product-id='${productId}'></script>`;
};

export default function ProductWidget() {
  const { t } = useTranslation();
  const [selectedProduct, setSelectedProduct] = useState("");
  const [generatedCode, setGeneratedCode] = useState("");
  const [Products, setProducts] = useState([]);
  const page = 1;
  const pageSize = 40;

  const { data: products, isLoading } = useQuery({
    queryKey: ["products", page, pageSize],
    queryFn: () => fetchProducts(page, pageSize),
  });

  useEffect(() => {
    if (products?.products) {
      setProducts(products?.products);
      console.log("products are: ", products?.products);
    }
  }, [products]);

  const handleGenerateCode = () => {
    if (selectedProduct) {
      const code = generateWidgetCode(selectedProduct);
      setGeneratedCode(code);
    } else {
      toast({ description:t("alert.err_title"),variant:"destructive"});

    }
  };

  const handleCopyCode = () => {
    navigator.clipboard.writeText(generatedCode);
    toast({ description: "Code copied to clipboard" });
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };
  if (isLoading) return <LoaderCircle />;
  return (
    <motion.div
    className="container mx-auto px-4 py-8 max-w-4xl"
    variants={containerVariants}
    initial="hidden"
    animate="visible"
  >
    <motion.h1
      className="text-3xl font-bold mb-8 flex items-center justify-center"
      variants={itemVariants}
    >
      <Code className="mr-2 h-8 w-8 text-primary" />
      {t("widget.pageTitle")}
    </motion.h1>
  
    <motion.div variants={itemVariants}>
      <Card>
        <CardHeader>
          <CardTitle>{t("widget.generateWidget")}</CardTitle>
        </CardHeader>
        <CardContent className="space-y-4">
          <div className="space-y-2">
            <label htmlFor="product-select" className="text-sm font-medium">
              {t("widget.selectProduct")}
            </label>
            <Select
              onValueChange={setSelectedProduct}
              value={selectedProduct}
            >
              <SelectTrigger id="product-select">
                <SelectValue placeholder={t("widget.chooseProduct")} />
              </SelectTrigger>
              <SelectContent>
                {isLoading ? (
                  <SelectItem value="loading" disabled>
                    {t("widget.loadingProducts")}
                  </SelectItem>
                ) : (
                  Products?.length !== 0 &&
                  Products?.map((product) => (
                    <SelectItem key={product?.id} value={product?.id}>
                      {product?.name}
                    </SelectItem>
                  ))
                )}
              </SelectContent>
            </Select>
          </div>
          <Button onClick={handleGenerateCode} className="w-full">
            {t("widget.generateWidgetCode")}
          </Button>
          {generatedCode && (
            <div className="space-y-2">
              <label htmlFor="generated-code" className="text-sm font-medium">
                {t("widget.generatedCode")}
              </label>
              <div className="relative">
                <Input
                  id="generated-code"
                  value={generatedCode}
                  readOnly
                  className="pr-10 font-mono text-sm"
                />
                <Button
                  size="sm"
                  variant="ghost"
                  className="absolute right-0 top-0 h-full"
                  onClick={handleCopyCode}
                >
                  <Copy className="h-4 w-4" />
                </Button>
              </div>
            </div>
          )}
        </CardContent>
        <CardFooter className="flex justify-between items-center">
          <p className="text-sm text-muted-foreground">
            {t("widget.embedCodeMessage")}
          </p>
          <Button variant="outline" size="sm" asChild>
            <a
              href="https://docs.example.com/widget-integration"
              target="_blank"
              rel="noopener noreferrer"
            >
              <ExternalLink className="h-4 w-4 mr-2" />
              {t("widget.integrationGuide")}
            </a>
          </Button>
        </CardFooter>
      </Card>
    </motion.div>
  </motion.div>
  
  );
}
