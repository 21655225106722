import StudioEditor from "@grapesjs/studio-sdk/react";
import "@grapesjs/studio-sdk/style";
import AboutUs from "../About";

export default function Builder() {
  return (
    <StudioEditor
      options={{
        project: {
          type: "web",
          default: {
            pages: [
              { name: "Home", component: `<div >Home page</div>` },
              { name: "About", component: "<h1>About page</h1>" },
              { name: "Contact", component: "<h1>Contact Articl</h1>" },
            ],
          },
        },
      }}
    />
  );
}
