import { useState } from "react";
import { motion } from "framer-motion";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
} from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { Badge } from "@/components/ui/badge";
import {
  AlertCircle,
  Book,
  HelpCircle,
  MessageCircle,
  Phone,
  Send,
  Video,
  Youtube,
} from "lucide-react";
import { Link } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { useToast } from "@/components/ui/use-toast";
import Axios from "@/utils/Axios";
import { useTranslation } from "react-i18next";





export default function Support() {
  const [ticket, setTicket] = useState({
    Subject: "",
    Content: "",
  });
  const {t}=useTranslation()
  const { toast } = useToast();
  const faqs= [
    {
      question: t("support.faqs.faq1.question"),
      answer: t("support.faqs.faq1.answer"),
    },
    {
      question: t("support.faqs.faq2.question"),
      answer: t("support.faqs.faq2.answer"),
    },
    {
      question: t("support.faqs.faq3.question"),
      answer: t("support.faqs.faq3.answer"),
    },
   {
      question: t("support.faqs.faq4.question"),
      answer: t("support.faqs.faq4.answer"),
    },
    {
      question: t("support.faqs.faq5.question"),
      answer: t("support.faqs.faq5.answer"),
    },
  ]
  const tutorials=[
     {
      title: t("support.tutorials.tutorial1.title"),
      url: "https://youtube.com/@keeprify",
      icon: <Book className="h-4 w-4" />,
    },
    {
      title: t("support.tutorials.tutorial2.title"),
      url: "https://youtube.com/@keeprify",
      icon: <Video className="h-4 w-4" />,
    },
    {
      title: t("support.tutorials.tutorial3.title"),
      url: "https://youtube.com/@keeprify",
      icon: <HelpCircle className="h-4 w-4" />,
    },
  
  ]
  const sendMessageMutation = useMutation({
    mutationFn: (data) => Axios.post("/api/ticket/new", data),
    onSuccess: () => {
      setTicket({
        Subject: "",
        Content: "",
      });
      toast({ title: "Ticket Sent" });
    },
    onError: () => {
      sendMessageMutation.reset();
      toast({ title: "Error sending ticket" });
    },
  });
  const handleTicketSubmit = (e) => {
    e.preventDefault();
    sendMessageMutation.mutate(ticket);
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  return (
    <motion.div
      className="container mx-auto px-4 py-8 max-w-6xl"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
    

     
    <motion.h1 className="text-4xl font-bold text-center mb-8" variants={itemVariants}>
  {t("supportc.title")}
</motion.h1>

<Tabs defaultValue="faq" className="mb-12">
  <TabsList className="grid w-full grid-cols-4">
    <TabsTrigger value="faq">{t("supportc.tabs.faq")}</TabsTrigger>
    <TabsTrigger value="ticket">{t("supportc.tabs.ticket")}</TabsTrigger>
    <TabsTrigger value="tutorials">{t("supportc.tabs.tutorials")}</TabsTrigger>
    <TabsTrigger value="contact">{t("supportc.tabs.contact")}</TabsTrigger>
  </TabsList>
  
  <TabsContent value="faq">
    <Card>
      <CardHeader>
        <CardTitle>{t("supportc.faq.header")}</CardTitle>
        <CardDescription>{t("supportc.faq.description")}</CardDescription>
      </CardHeader>
      <CardContent>
        <Accordion type="single" collapsible className="w-full">
          {faqs.map((faq, index) => (
            <AccordionItem key={index} value={`item-${index}`}>
              <AccordionTrigger>{faq.question}</AccordionTrigger>
              <AccordionContent>{faq.answer}</AccordionContent>
            </AccordionItem>
          ))}
        </Accordion>
      </CardContent>
    </Card>
  </TabsContent>

  <TabsContent value="ticket">
    <Card>
      <CardHeader>
        <CardTitle>{t("supportc.ticket.header")}</CardTitle>
        <CardDescription>{t("supportc.ticket.description")}</CardDescription>
      </CardHeader>
      <CardContent>
        <form onSubmit={handleTicketSubmit} className="space-y-4">
          <div>
            <label htmlFor="subject" className="block text-sm font-medium mb-1">
              {t("supportc.ticket.subject")}
            </label>
            <Input
              id="subject"
              value={ticket.Subject}
              onChange={(e) => setTicket({ ...ticket, Subject: e.target.value })}
              required
              placeholder={t("supportc.ticket.subjectPlaceholder")}
            />
          </div>
          <div>
            <label htmlFor="content" className="block text-sm font-medium mb-1">
              {t("supportc.ticket.content")}
            </label>
            <Textarea
              id="content"
              value={ticket.Content}
              required
              onChange={(e) => setTicket({ ...ticket, Content: e.target.value })}
              placeholder={t("supportc.ticket.contentPlaceholder")}
              rows={5}
            />
          </div>
          <Button type="submit" className="w-full">
            <Send className="h-4 w-4 mr-2" />
            {t("supportc.ticket.submit")}
          </Button>
        </form>
      </CardContent>
    </Card>
  </TabsContent>

  <TabsContent value="tutorials">
    <Card>
      <CardHeader>
        <CardTitle>{t("supportc.tutorials.header")}</CardTitle>
        <CardDescription>{t("supportc.tutorials.description")}</CardDescription>
      </CardHeader>
      <CardContent>
        <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
          {tutorials.map((tutorial, index) => (
            <a
              key={index}
              href={tutorial.url}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center p-4 bg-secondary rounded-lg hover:bg-secondary/80 transition-colors"
            >
              {tutorial.icon}
              <span className="ml-2">{tutorial.title}</span>
            </a>
          ))}
        </div>
        <div className="mt-6">
          <h3 className="text-lg font-semibold mb-2">{t("supportc.tutorials.additionalResources")}</h3>
          <div className="flex flex-wrap gap-2">
            <Badge variant="outline" className="text-sm">
              <Youtube className="h-4 w-4 mr-1" />
              <Link target="_blank" to={"https://www.youtube.com/@keeprify"}>
                {t("supportc.tutorials.youtubeChannel")}
              </Link>
            </Badge>
            <Badge variant="outline" className="text-sm">
              <MessageCircle className="h-4 w-4 mr-1" />
              <Link target="_blank" to={"https://t.me/+FljXmzYXHJ1kYmE8"}>
                {t("supportc.tutorials.telegramCommunity")}
              </Link>
            </Badge>
          </div>
        </div>
      </CardContent>
    </Card>
  </TabsContent>

  <TabsContent value="contact">
    <Card>
      <CardHeader>
        <CardTitle>{t("supportc.contact.header")}</CardTitle>
        <CardDescription>{t("supportc.contact.description")}</CardDescription>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          <div className="flex items-center">
            <Phone className="h-5 w-5 mr-2" />
            <span>{t("supportc.contact.phone")}</span>
          </div>
          <div className="flex items-center">
            <MessageCircle className="h-5 w-5 mr-2" />
            <span>{t("supportc.contact.email")}</span>
          </div>
          <div className="flex items-center">
            <AlertCircle className="h-5 w-5 mr-2" />
            <span>
              {t("supportc.contact.urgent")}
              <a target="_blank" style={{ textDecoration: "underline" }} href={"https://t.me/+FljXmzYXHJ1kYmE8"}>
                {t("supportc.contact.telegram")}
              </a>
            </span>
          </div>
        </div>
      </CardContent>
    </Card>
  </TabsContent>
</Tabs>

<motion.div variants={itemVariants} className="text-center space-y-2">
  <p className="text-sm text-muted-foreground">
    {t("supportc.footer.terms")} 
    <a href="/terms" className="underline hover:text-primary">{t("supportc.footer.terms")}</a> 
    {t("supportc.footer.privacy")}
    <a href="/privacy" className="underline hover:text-primary">{t("supportc.footer.privacy")}</a>.
  </p>
  <p className="text-sm text-muted-foreground">
    {t("supportc.footer.copyright")}
  </p>
</motion.div>

    </motion.div>
  );
}
