import { AlertDialogHeader } from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Plus } from "lucide-react";
import React, { useState } from "react";

export default function NewLanding() {
  return (
    <div className="p-2 h-full ">
      <Card className="p-2 gap-2 h-full relative   flex flex-col space-y-2 lg:flex-row">
        <div className=" lg:flex-1 space-y-2 ">
          <Select>
            <SelectTrigger className="">
              <SelectValue placeholder="Select a Product" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectLabel>Fruits</SelectLabel>
                <SelectItem value="apple">Apple</SelectItem>
                <SelectItem value="banana">Banana</SelectItem>
                <SelectItem value="blueberry">Blueberry</SelectItem>
                <SelectItem value="grapes">Grapes</SelectItem>
                <SelectItem value="pineapple">Pineapple</SelectItem>
              </SelectGroup>
            </SelectContent>
          </Select>
          <div>
            <Input type="file"/>
          </div>
        </div>
        <div className="flex-1 overflow-y-scroll  bg-accent p-2 rounded-xl">
          <h1 className="text-center font-bold  text-xl">preview</h1>
          <div className="flex flex-col items-center justify-center">
            
          </div>
        </div>
      </Card>
    </div>
  );
}
