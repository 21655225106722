import React, { useState } from "react";
import { motion } from "framer-motion";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { ScrollArea } from "@/components/ui/scroll-area";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { ChevronUp, FileText, Mail, Phone } from "lucide-react";

export default function TermsOfService() {
  const [showBackToTop, setShowBackToTop] = useState(false);

  const sections = [
    {
      title: "1. Acceptance of Terms",
      content: `By accessing or using Keeprify's services, you agree to be bound by these Terms of Service ("Terms"). If you disagree with any part of the terms, you may not access the service.`,
    },
    {
      title: "2. Description of Service",
      content: `Keeprify provides an e-commerce platform that allows users to create online stores, process payments, and manage inventory ("Service"). The Service is subject to change at Keeprify's discretion.`,
    },
    {
      title: "3. User Accounts",
      content: `To use certain features of the Service, you must register for an account. You are responsible for maintaining the confidentiality of your account and password. You agree to accept responsibility for all activities that occur under your account.`,
    },
    {
      title: "4. User Content",
      content: `You retain all rights to any content you submit, post or display on or through the Service. By submitting, posting or displaying content, you grant Keeprify a worldwide, non-exclusive, royalty-free license to use, reproduce, adapt, publish, translate and distribute your content in any existing or future media.`,
    },
    {
      title: "5. Acceptable Use",
      content: `You agree not to use the Service to:
      a) Violate any laws or regulations
      b) Infringe on the rights of others
      c) Distribute malicious software or engage in malicious activities
      d) Interfere with or disrupt the integrity of the Service
      e) Collect or store personal data about other users without their express permission`,
    },
    {
      title: "6. Payment and Fees",
      content: `Certain aspects of the Service may be provided for a fee. You agree to pay all applicable fees for the Service you choose to use. Keeprify may change its fees at any time by posting the changes on the Site or in the Service.`,
    },
    {
      title: "7. Termination",
      content: `Keeprify reserves the right to terminate or suspend your account and access to the Service at any time, without prior notice or liability, for any reason, including without limitation if you breach the Terms.`,
    },
    {
      title: "8. Limitation of Liability",
      content: `In no event shall Keeprify, its officers, directors, employees, or agents, be liable to you for any direct, indirect, incidental, special, punitive, or consequential damages whatsoever resulting from any (i) errors, mistakes, or inaccuracies of content, (ii) personal injury or property damage, of any nature whatsoever, resulting from your access to and use of our service, (iii) any unauthorized access to or use of our secure servers and/or any and all personal information and/or financial information stored therein, (iv) any interruption or cessation of transmission to or from our service, (v) any bugs, viruses, trojan horses, or the like, which may be transmitted to or through our service by any third party, and/or (vi) any errors or omissions in any content or for any loss or damage of any kind incurred as a result of your use of any content posted, emailed, transmitted, or otherwise made available via the service, whether based on warranty, contract, tort, or any other legal theory, and whether or not the company is advised of the possibility of such damages.`,
    },
    {
      title: "9. Governing Law",
      content: `These Terms shall be governed and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of law provisions.`,
    },
    {
      title: "10. Changes to Terms",
      content: `Keeprify reserves the right, at its sole discretion, to modify or replace these Terms at any time. If a revision is material we will try to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.`,
    },
  ];

  const faqs = [
    {
      question: "How can I cancel my account?",
      answer:
        "You can cancel your account at any time by going to your account settings and selecting the 'Cancel Account' option. Please note that cancelling your account may result in the loss of your data and content.",
    },
    {
      question: "What happens to my data if I cancel my account?",
      answer:
        "Upon cancellation, your data will be deleted from our active databases. However, some information may be retained in our backups for a limited time. If you wish to have all your data permanently deleted, please contact our support team.",
    },
    {
      question: "Can I use Keeprify for illegal activities?",
      answer:
        "No, Keeprify strictly prohibits the use of our services for any illegal activities. Violation of this policy will result in immediate termination of your account and may be reported to law enforcement authorities.",
    },
    {
      question: "How do refunds work?",
      answer:
        "Refund policies are set by individual merchants using Keeprify. For issues with a specific purchase, please contact the merchant directly. For issues with Keeprify's services, please contact our support team.",
    },
    {
      question: "How does Keeprify handle disputes between buyers and sellers?",
      answer:
        "Keeprify provides tools for merchants to handle disputes directly with their customers. We encourage open communication between parties. In cases where resolution cannot be reached, Keeprify may intervene to mediate, but we are not responsible for the outcome of such disputes.",
    },
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement;
    setShowBackToTop(target.scrollTop > 300);
  };

  const scrollToTop = () => {
    const scrollArea = document.querySelector(".scroll-area");
    if (scrollArea) {
      scrollArea.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  return (
    <motion.div
      className="container mx-auto px-4 py-8 max-w-4xl"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <motion.h1
        className="text-4xl font-bold text-center mb-8"
        variants={itemVariants}
      >
        Keeprify Terms of Service
      </motion.h1>

      <motion.div variants={itemVariants} className="mb-8 text-center">
        <p className="text-lg text-muted-foreground">
          Last updated: {new Date().toLocaleDateString()}
        </p>
      </motion.div>

      <Card className="mb-8">
        <CardHeader>
          <CardTitle className="flex items-center">
            <FileText className="mr-2" />
            Please Read These Terms Carefully
          </CardTitle>
        </CardHeader>
        <CardContent>
          <p className="text-muted-foreground">
            These Terms of Service govern your use of Keeprify's platform and
            services. By using our services, you agree to these terms. If you do
            not agree to these terms, please do not use our services.
          </p>
        </CardContent>
      </Card>

      <Tabs defaultValue="full-terms" className="mb-8">
        <TabsList className="grid w-full grid-cols-3">
          <TabsTrigger value="full-terms">Full Terms</TabsTrigger>
          <TabsTrigger value="key-points">Key Points</TabsTrigger>
          <TabsTrigger value="faq">FAQ</TabsTrigger>
        </TabsList>
        <TabsContent value="full-terms">
          <Card>
            <CardContent>
              <ScrollArea
                className="h-[60vh] scroll-area"
                onScroll={handleScroll}
              >
                {sections.map((section, index) => (
                  <div key={index} className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">
                      {section.title}
                    </h2>
                    <p className="text-muted-foreground whitespace-pre-line">
                      {section.content}
                    </p>
                  </div>
                ))}
              </ScrollArea>
            </CardContent>
          </Card>
        </TabsContent>
        <TabsContent value="key-points">
          <Card>
            <CardContent>
              <ul className="list-disc list-inside space-y-2">
                <li>You must be at least 18 years old to use Keeprify</li>
                <li>
                  You are responsible for maintaining the security of your
                  account
                </li>
                <li>
                  You retain ownership of your content, but grant us a license
                  to use it
                </li>
                <li>
                  We may terminate your account for violations of these terms
                </li>
                <li>We are not responsible for the content posted by users</li>
                <li>We may change these terms at any time</li>
              </ul>
            </CardContent>
          </Card>
        </TabsContent>
        <TabsContent value="faq">
          <Card>
            <CardContent>
              <Accordion type="single" collapsible className="w-full">
                {faqs.map((faq, index) => (
                  <AccordionItem key={index} value={`item-${index}`}>
                    <AccordionTrigger>{faq.question}</AccordionTrigger>
                    <AccordionContent>{faq.answer}</AccordionContent>
                  </AccordionItem>
                ))}
              </Accordion>
            </CardContent>
          </Card>
        </TabsContent>
      </Tabs>

      <motion.div
        variants={itemVariants}
        className="flex justify-center space-x-4 mb-8"
      >
        <Button variant="outline" className="flex items-center">
          <Mail className="mr-2 h-4 w-4" />
          Contact Support
        </Button>
        <Button variant="outline" className="flex items-center">
          <Phone className="mr-2 h-4 w-4" />
          Call Us
        </Button>
      </motion.div>

      {showBackToTop && (
        <motion.div
          className="fixed bottom-8 right-8"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 20 }}
        >
          <Button onClick={scrollToTop} size="icon" variant="secondary">
            <ChevronUp className="h-4 w-4" />
          </Button>
        </motion.div>
      )}

      <motion.div
        variants={itemVariants}
        className="text-center text-sm text-muted-foreground"
      >
        <p>© {new Date().getFullYear()} Keeprify. All rights reserved.</p>
      </motion.div>
    </motion.div>
  );
}
