import LoaderCircle from "@/AppComponents/LoaderCircle";
import SaveChangesButton from "@/AppComponents/SaveChangesButton";
import { Card } from "@/components/ui/card";
import CopyableTextarea from "@/components/ui/googleScript";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { toast } from "@/components/ui/use-toast";
import { StoreContext } from "@/utils/StoreContext";
import { updateStore } from "@/utils/StoreUtils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

export default function GoogleSheets() {
  const { t } = useTranslation();

  const { storeData: Store, setStore } = useContext(StoreContext);
  const queryClient = useQueryClient();

  const updateStoreMutation = useMutation({
    mutationFn: () => updateStore(Store),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["store"] });
      toast({ description: "store data updated" });
    },
    onError: () => {
      updateStoreMutation.reset();
      toast({ description:t("alert.err_title"),variant:"destructive"});

    },
  });
  // if (!Store) return <LoaderCircle />;
  return (
    <Card className="max-w-4xl mx-auto p-4 space-y-8">
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
        <h2 className="text-2xl font-bold">
          {t("Settings.inside.google.label")}
        </h2>
        <SaveChangesButton
          isPending={updateStoreMutation.isPending}
          action={() => updateStoreMutation.mutate()}
          btnlabel={t("btns.save")}
        />
      </div>

      <div className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="store-name" className="text-lg font-medium">
            {t("Settings.inside.google.link")}
          </Label>
          <Input
            id="store-name"
            value={Store?.googleSheetUrl}
            onChange={(e) =>
              setStore({ ...Store, googleSheetUrl: e.target.value })
            }
            placeholder="Google Sheets ID"
            className="w-full"
          />
        </div>
        <div className="mt-8">
          <CopyableTextarea />
        </div>
        video frame will be added here
      </div>
    </Card>
  );
}
