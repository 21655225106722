import Axios from "./Axios";

export const fetchClients = async (page, pageSize) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await Axios.get("api/clients", {
        params: {
          page,
          pageSize,
        },
      });
      if (res.status === 200) {
        resolve(res.data);
      } else {
        throw new Error("couldnt fetch clients");
      }
    } catch (e) {
      reject();
    }
  });
};

export const addClient = async (client) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await Axios.post("api/client/create", {
        ...client,
      });
      if (res.status === 200) {
        resolve(res.data);
      } else {
        throw new Error("couldnt fetch clients");
      }
    } catch (e) {
      reject();
    }
  });
};
export const deleteClient = async (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await Axios.delete(`api/delete_client/${id}`);
      if (res.status === 200) {
        resolve(res);
      } else throw new Error(res.data.error);
    } catch (e) {
      reject(e);
    }
  });
};
