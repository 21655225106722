import Axios from "./Axios";

export const fetchPixels = async () => {
  return new Promise((resolve, reject) => {
    Axios.get("/api/pixel/get", { params: { page: 1, pageSize: 10 } }).then(
      (res) => {
        if (res.status === 200) {
          resolve(res.data.pixels);
        } else {
          reject();
        }
      },
    );
  });
};

export const updatePixels = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await Axios.post(`/api/pixel/edit`, {
        ...data,
      });
      if (res.status === 200) {
        resolve(res);
      } else throw new Error(res.data.error);
    } catch (e) {
      reject(e);
    }
  });
};
