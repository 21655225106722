import Axios from "./Axios";

export const fetchDomains = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await Axios.get("/api/domains");
      if (res.status === 200) {
        resolve(res.data);
      } else throw new Error(res.data.error);
    } catch (e) {
      reject(e);
    }
  });
};
