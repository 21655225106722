import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { LanguagesIcon } from "lucide-react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export default function LanguageToggle() {
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();
  const [Language, setLanguage] = useState(language);
  const handleChangeLanguage = (newlanguage) => {
    localStorage.setItem("language", newlanguage);
    setLanguage(newlanguage);
    changeLanguage(newlanguage);
  };
  // console.log(language)
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" size="icon">
          <div className="  flex items-center justify-center rounded-full font-bold ">
            <LanguagesIcon size="20" />
          </div>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="center" className="">
        <DropdownMenuLabel>Select Language</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          <DropdownMenuItem onClick={() => handleChangeLanguage("en")}>
            <div className="flex items-center gap-2">
              <span>English</span>
            </div>
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => handleChangeLanguage("ar")}>
            <div className="flex items-center gap-2">
              <span>العربية</span>
            </div>
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => handleChangeLanguage("cn")}>
            <div className="flex items-center gap-2">
              <span>中文 Chinese </span>
            </div>
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => handleChangeLanguage("fr")}>
            <div className="flex items-center gap-2">
              <span>French</span>
            </div>
          </DropdownMenuItem>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
