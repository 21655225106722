import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { Check, Sparkles } from "lucide-react";
import { useTranslation } from "react-i18next";
import Axios from "@/utils/Axios";

export default function Component() {
  const [selectedPlan, setSelectedPlan] = useState("");
  const [isYearly, setIsYearly] = useState(false);
  const [currentSubscription, setCurrentSubscription] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchSubscription = async () => {
      try {
        const response = await Axios.get("/api/subscription/get");
        setCurrentSubscription(response.data.subscription);
      } catch (error) {
        console.error("Error fetching subscription:", error);
      }
    };

    fetchSubscription();
  }, []);

  const calculateDaysRemaining = (endDate: string) => {
    const end = new Date(endDate);
    const now = new Date();
    const diffTime = end.getTime() - now.getTime();
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays > 0 ? diffDays : 0;
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  const plans = {
    plan1: {
      name: t("Paymentp.plans.plan1.name"),
      priceMonthly: t("Paymentp.plans.plan1.priceMonthly"),
      priceYearly: t("Paymentp.plans.plan1.priceYearly"),
      features: [
        t("Paymentp.plans.plan1.features.feature1"),
        t("Paymentp.plans.plan1.features.feature2"),
        t("Paymentp.plans.plan1.features.feature3"),
        t("Paymentp.plans.plan1.features.feature4"),
        t("Paymentp.plans.plan1.features.feature5"),
        t("Paymentp.plans.plan1.features.feature6"),
        t("Paymentp.plans.plan1.features.feature7"),
        t("Paymentp.plans.plan1.features.feature8"),
        t("Paymentp.plans.plan1.features.feature9"),
        t("Paymentp.plans.plan1.features.feature10"),
        t("Paymentp.plans.plan1.features.feature11"),
      ],
      color: "bg-blue-500",
      endpoint: isYearly ? "/api/create_checkout/2" : "/api/create_checkout/1",
    },
    plan2: {
      name: t("Paymentp.plans.plan2.name"),
      priceMonthly: t("Paymentp.plans.plan2.priceMonthly"),
      priceYearly: t("Paymentp.plans.plan2.priceYearly"),
      features: [
        t("Paymentp.plans.plan2.features.feature1"),
        t("Paymentp.plans.plan2.features.feature2"),
        t("Paymentp.plans.plan2.features.feature3"),
        t("Paymentp.plans.plan2.features.feature4"),
        t("Paymentp.plans.plan2.features.feature5"),
        t("Paymentp.plans.plan2.features.feature6"),
        t("Paymentp.plans.plan2.features.feature7"),
        t("Paymentp.plans.plan2.features.feature8"),
        t("Paymentp.plans.plan2.features.feature9"),
        t("Paymentp.plans.plan2.features.feature10"),
        t("Paymentp.plans.plan2.features.feature11"),
        t("Paymentp.plans.plan2.features.feature12"),
        t("Paymentp.plans.plan2.features.feature13"),
        t("Paymentp.plans.plan2.features.feature14"),
      ],
      color: "bg-purple-500",
      popular: true,
      endpoint: isYearly ? "/api/create_checkout/4" : "/api/create_checkout/3",
    },
    plan3: {
      name: t("Paymentp.plans.plan3.name"),
      priceMonthly: 2500,
      priceYearly: 24000,
      features: [
        t("Paymentp.plans.plan3.features.feature1"),
        t("Paymentp.plans.plan3.features.feature2"),
        t("Paymentp.plans.plan3.features.feature3"),
        t("Paymentp.plans.plan3.features.feature4"),
        t("Paymentp.plans.plan3.features.feature5"),
        t("Paymentp.plans.plan3.features.feature6"),
        t("Paymentp.plans.plan3.features.feature7"),
        t("Paymentp.plans.plan3.features.feature8"),
        t("Paymentp.plans.plan3.features.feature9"),
        t("Paymentp.plans.plan3.features.feature10"),
        t("Paymentp.plans.plan3.features.feature11"),
        t("Paymentp.plans.plan3.features.feature12"),
        t("Paymentp.plans.plan3.features.feature13"),
        t("Paymentp.plans.plan3.features.feature14"),
      ],
      color: "bg-green-500",
      endpoint: isYearly ? "/api/create_checkout/6" : "/api/create_checkout/5",
    },
  };

  const faqs = {
    faq1: {
      question: t("Paymentp.faqs.faq1.question"),
      answer: t("Paymentp.faqs.faq1.answer"),
    },
    faq2: {
      question: t("Paymentp.faqs.faq2.question"),
      answer: t("Paymentp.faqs.faq2.answer"),
    },
    faq3: {
      question: t("Paymentp.faqs.faq3.question"),
      answer: t("Paymentp.faqs.faq3.answer"),
    },
  };

  const handlePayment = async () => {
    const selectedPlanDetails = Object.values(plans).find(
      (plan) => plan.name === selectedPlan,
    );
    if (selectedPlanDetails) {
      try {
        const response = await Axios.get(selectedPlanDetails.endpoint);
        const data = await response.data;
        if (data.checkout.checkout_url) {
          window.location.href = data.checkout.checkout_url;
        } else {
          console.error("No URL returned from the server");
        }
      } catch (error) {
        console.error("Error processing payment:", error);
      }
    }
  };

  const formatDate = (date: string) => {
    const d = new Date(date);
    const day = d.getDate().toString().padStart(2, "0");
    const month = (d.getMonth() + 1).toString().padStart(2, "0");
    const year = d.getFullYear().toString().slice(-2);
    return `${day}/${month}/${year}`;
  };

  return (
    <motion.div
      className="container mx-auto px-4 py-2 max-w-6xl"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <motion.div
        className="bg-gradient-to-r text-white from-purple-900 to-purple-600 p-2 rounded-2xl mb-6 text-center shadow-2xl"
        variants={itemVariants}
      >
        {currentSubscription ? (
          <>
            <h2 className="text-3xl font-semibold mb-2 mt-1 ">
              {t("Paymentp.currentPlan")}: {currentSubscription?.plan}
            </h2>
            <p className="text-xl mb-2">
              {t("Paymentp.endDate")}:{" "}
              <span className="font-medium">
                {formatDate(currentSubscription.endDate)}
              </span>
            </p>
            <p className="text-lg">
              in {calculateDaysRemaining(currentSubscription.endDate)} days
            </p>
          </>
        ) : (
          <p className="text-xl">{t("Paymentp.noActiveSubscription")}</p>
        )}
      </motion.div>
      <h1 className="text-3xl font-bold text-center mb-4">
        {t("Paymentp.chooseYourPlan")}
      </h1>

      <motion.div
        className="flex items-center  justify-center mb-8"
        variants={itemVariants}
      >
        <Label htmlFor="billing-toggle" className="mr-4 text-xl">
          {t("Paymentp.monthly")}
        </Label>
        <Switch
          id="billing-toggle"
          checked={isYearly}
          onCheckedChange={setIsYearly}
          className="mx-4"
          
        />
        <Label htmlFor="billing-toggle" className="ml-4 text-xl">
          {t("Paymentp.yearly")}
        </Label>
        {isYearly && (
          <Badge variant="secondary" className="ml-4 text-lg px-3 py-1">
            {t("Paymentp.save20Percent")}
          </Badge>
        )}
      </motion.div>

      <motion.div
        className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16"
        variants={containerVariants}
      >
        {Object.values(plans).map((plan) => (
          <motion.div key={plan.name} variants={itemVariants}>
            <Card
              className={`${selectedPlan === plan.name ? "ring-4 ring-primary" : ""
                } hover:shadow-2xl transition-all duration-300 relative overflow-hidden transform hover:scale-105`}
            >
              {plan.popular && (
                <div className="absolute top-0 right-0 bg-yellow-400 text-yellow-900 px-4 py-2 rounded-bl-2xl font-semibold z-10 shadow-md">
                  <Sparkles className="inline-block mr-1" />
                  {t("Paymentp.popular")}
                </div>
              )}
              <div className={`h-3 ${plan.color}`} />
              <CardHeader>
                <CardTitle className="text-3xl mb-2">{plan.name}</CardTitle>
                <CardDescription className="text-2xl font-bold">
                  DA{isYearly ? plan.priceYearly : plan.priceMonthly}/
                  {isYearly ? t("Paymentp.year") : t("Paymentp.month")}
                </CardDescription>
              </CardHeader>
              <CardContent>
                <ul className="space-y-3">
                  {plan.features.map((feature, i) => (
                    <li key={i} className="flex items-center text-lg">
                      <Check className="mr-3 h-6 w-6 text-green-500 flex-shrink-0" />
                      <span>{feature}</span>
                    </li>
                  ))}
                </ul>
              </CardContent>
              <CardFooter>
                <Button
                  onClick={() => setSelectedPlan(plan.name)}
                  variant={selectedPlan === plan.name ? "default" : "outline"}
                  className="w-full text-lg py-6"
                >
                  {selectedPlan === plan.name ? (
                    <>
                      <Check className="mr-2 h-6 w-6" />{" "}
                      {t("Paymentp.selected")}
                    </>
                  ) : (
                    t("Paymentp.selectPlan")
                  )}
                </Button>
              </CardFooter>
            </Card>
          </motion.div>
        ))}
      </motion.div>

      <AnimatePresence>
        {selectedPlan && (
          <motion.div
            className="space-y-12 text-white"
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
          >
            <motion.div
              variants={itemVariants}
              className="bg-gradient-to-r from-purple-900 to-purple-600 p-8 rounded-2xl shadow-2xl"
            >
              <h2 className="text-3xl font-bold mb-8 text-center">
                {t("Paymentp.orderSummary")}
              </h2>
              <div className="flex justify-between mb-6 text-xl">
                <span>{t("Paymentp.plan")}:</span>
                <span className="font-semibold">{selectedPlan}</span>
              </div>
              <div className="flex justify-between mb-6 text-xl">
                <span>{t("Paymentp.price")}:</span>
                <span className="font-semibold">
                  DA
                  {isYearly
                    ? Object.values(plans).find((p) => p.name === selectedPlan)
                      ?.priceYearly
                    : Object.values(plans).find((p) => p.name === selectedPlan)
                      ?.priceMonthly}
                </span>
              </div>
              <div className="flex justify-between mb-8 text-xl">
                <span>{t("Paymentp.billing")}:</span>
                <span className="font-semibold">
                  {isYearly ? t("Paymentp.yearly") : t("Paymentp.monthly")}
                </span>
              </div>
              <Button
                onClick={handlePayment}
                className="w-full border text-xl py-6"
              >
                {t("Paymentp.pay")}
              </Button>
            </motion.div>

            <Accordion
              type="single"
              collapsible
              className="bg-secondary rounded-2xl p-6"
            >
              <h3 className="text-2xl font-semibold mb-4">
                {t("Paymentp.faqTitle")}
              </h3>
              {Object.values(faqs).map((faq, index) => (
                <AccordionItem
                  value={index.toString()}
                  key={faq.question}
                  className="border-b last:border-b-0"
                >
                  <AccordionTrigger className="text-lg">
                    {faq.question}
                  </AccordionTrigger>
                  <AccordionContent className="text-muted-foreground">
                    {faq.answer}
                  </AccordionContent>
                </AccordionItem>
              ))}
            </Accordion>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
}
