import Axios from "./Axios";

export const fetchUser = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await Axios.get("api/user");
      if (res.status === 200) {
        resolve(res.data);
      } else {
        throw new Error("couldnt fetch user");
      }
    } catch (e) {
      reject();
    }
  });
};
