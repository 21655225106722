import React, { useContext, useEffect, useState } from "react";
import { motion } from "framer-motion";
import ActionFooter from "@/AppComponents/ActionFooter";
import LoaderCircle from "@/AppComponents/LoaderCircle";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { Checkbox } from "@/components/ui/checkbox";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Separator } from "@/components/ui/separator";
import { toast } from "@/components/ui/use-toast";
import { addOrder } from "@/utils/OrderUtils";
import { fetchProducts } from "@/utils/ProductUtils";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Save } from "lucide-react";
import { getAllWilayas, getCommunesByWilayaCode } from "algerian-geo";
import { StoreContext } from "@/utils/StoreContext";
import { useTranslation } from "react-i18next";

export default function ResponsiveAddOrder() {
  const { storeData } = useContext(StoreContext);
  // console.log(storeData)
  const {t} = useTranslation()
  const [order, setOrder] = useState({
    productId: null,
    wilayaId: null,
    communeId: null,
    unit_price: 0,
    quantity: 0,
    total_price: 0,
    client_name: "",
    client_phone_number: "",
    description: "",
    strict_address: "",
    client_email: "",
    isHome:false,
    shipping_price:0
  });
  

  const { data: products } = useQuery({
    queryKey: ["products"],
    queryFn: () => fetchProducts(1, 40),
  });
  const recalculatePrice=()=>{
  
      if(storeData?.Shipping!=null){
       if(order.isHome){
        let sp=storeData?.Shipping?.rates[order?.wilayaId+1]?.home;
        let total=order.quantity*order.unit_price+sp;
       
        setOrder({...order,shipping_price:sp,total_price:total})
        
       }else{
        let sp=storeData?.Shipping?.rates[order?.wilayaId+1]?.desk;
        let total=order.quantity*order.unit_price+sp;
        
        setOrder({...order,shipping_price:sp,total_price:total})
        
        
      }
      }
     
    
    
  }
  const handleSelectProduct = (value) => {
    setOrder({ ...order, productId: value.id, unit_price: value.price });
  };

  useEffect(() => {
    recalculatePrice()
  }, [order.quantity,order.isHome, order.unit_price,order.wilayaId]);

  const addOrderMutation = useMutation({
    mutationFn: () => addOrder(order),
    onSuccess: () => {
      toast({ title: "Order added" });
      setOrder({
       ...order,
        
        quantity: 1,
        
        client_name: "",
        client_phone_number: "",
        description: "",
        strict_address: "",
        client_email: "",
        isHome:false,
        shipping_price:0
      });
      addOrderMutation.reset()
    },
    onError:()=>{
      toast({ description:t("alert.err_title"),variant:"destructive"});

      addOrderMutation.reset()
    }
  });
  const handleAddOrder = () => {
    if (
      order.productId === null ||
      order.wilayaId === null ||
      order.communeId === null ||
      order.quantity <0 ||
      order.quantity ==0 ||
      order.client_name == "" ||
      order.client_phone_number ==""

    ) {
      toast({
        title:t("err_title"),
        description:t("err_d"),
        variant: "destructive",
      });
    } else {
      addOrderMutation.mutate();
    }
  };
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };
  if(storeData?.Shipping==null) return <LoaderCircle/>
  return (
    <>
      <motion.div
        className="flex flex-col px-4 "
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <h1 className="text-2xl font-bold p-4">
          {t("Orders.inside.create.title")}
        </h1>

        <motion.div className="space-y-6" variants={itemVariants}>
          <Card className="p-4 space-y-4">
            <Select onValueChange={(value) => handleSelectProduct(value)}>
              <SelectTrigger>
                <SelectValue placeholder= {t("Orders.inside.create.sp")}
 />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectLabel>
                  {t("general.products")}
                  </SelectLabel>
                  {products &&
                    products?.products?.map((item, index) => (
                      <SelectItem key={item.id} value={item}>
                        {item.name}
                      </SelectItem>
                    ))}
                </SelectGroup>
              </SelectContent>
            </Select>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
              <div>
                <Label htmlFor="quantity" className="mb-2 block">
                {t("Orders.inside.create.qt")}
                </Label>
                <Input
                  id="quantity"
                  placeholder={t("Orders.inside.create.qt")}
                  type="number"
                  onChange={(e) =>
                    setOrder({ ...order, quantity: e.target.valueAsNumber })
                  }
                  value={order.quantity}
                />
              </div>
              <div>
                <Label htmlFor="price" className="mb-2 block">
                {t("Orders.inside.create.pr")}
                </Label>
                <Input
                  id="price"
                  value={order.unit_price}
                  onChange={(e) =>
                    setOrder({ ...order, unit_price: e.target.valueAsNumber })
                  }
                  placeholder={t("Orders.inside.create.pr")}
                  type="number"
                />
              </div>
              <div>
                <Label htmlFor="note" className="mb-2 block">
                {t("Orders.inside.create.nt")}
                </Label>
                <Input
                  id="note"
                  value={order.description}
                  onChange={(e) =>
                    setOrder({ ...order, description: e.target.value })
                  }
                  placeholder={t("Orders.inside.create.nt")}
                  type="text"
                />
              </div>
            </div>
          </Card>

          <Card className="p-4 space-y-4">
            <h2 className="text-xl font-semibold">
            {t("Orders.inside.create.ci")}
            </h2>
            <Separator />
            <div className="space-y-4">
              <div>
                <Label htmlFor="email" className="mb-2 block">
                {t("Orders.inside.create.em")}
                </Label>
                <Input
                  id="email"
                  value={order.client_email}
                  onChange={(e) =>
                    setOrder({ ...order, client_email: e.target.value })
                  }
                  placeholder={t("Orders.inside.create.em")}

                />
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                <div>
                  <Label htmlFor="name" className="mb-2 block">
                  {t("Orders.inside.create.n")}
                  </Label>
                  <Input
                    id="name"
                    value={order.client_name}
                    onChange={(e) =>
                      setOrder({ ...order, client_name: e.target.value })
                    }
                    placeholder={t("Orders.inside.create.n")}

                  />
                </div>
                <div>
                  <Label htmlFor="phone" className="mb-2 block">
                  {t("Orders.inside.create.pn")}

                  </Label>
                  <Input
                    id="phone"
                    value={order.client_phone_number}
                    onChange={(e) =>
                      setOrder({
                        ...order,
                        client_phone_number: e.target.value,
                      })
                    }
                    placeholder={t("Orders.inside.create.pn")}

                  />
                </div>
                <div>
                  <Label htmlFor="state" className="mb-2 block">
                  {t("Orders.inside.create.s")}

                  </Label>
                  <Select
                    onValueChange={(value) =>
                      setOrder({ ...order, wilayaId: parseInt(value) })
                    }
                  >
                    <SelectTrigger id="state">
                      <SelectValue placeholder={t("Orders.inside.create.s")}/>
                    </SelectTrigger>
                    <SelectContent className="h-[15rem]">
                      <SelectGroup>
                        <SelectLabel>{t("Orders.inside.create.s")}</SelectLabel>
                        {getAllWilayas()?.map((wilaya) => (
                          <SelectItem
                            key={wilaya.code}
                            value={wilaya.code.toString()}
                          >
                            {wilaya.code} {wilaya.name}
                          </SelectItem>
                        ))}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </div>
                <div>
                  <Label htmlFor="city" className="mb-2 block">
                  {t("Orders.inside.create.c")}
                  </Label>
                  <Select
                    onValueChange={(value) =>
                      setOrder({ ...order, communeId: parseInt(value) })
                    }
                  >
                    <SelectTrigger id="city">
                      <SelectValue placeholder={t("Orders.inside.create.c")}
 />
                    </SelectTrigger>
                    <SelectContent className="h-[15rem]">
                      <SelectGroup>
                        <SelectLabel>{t("Orders.inside.create.c")}
                        </SelectLabel>
                        {getCommunesByWilayaCode(order.wilayaId)?.map(
                          (commune) => (
                            <SelectItem
                              key={commune.id}
                              value={commune.id.toString()}
                            >
                              {commune.name}
                            </SelectItem>
                          ),
                        )}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </div>
                <div>
                  <Label htmlFor="address" className="mb-2 block">
                  {t("Orders.inside.create.ad")}
                  </Label>
                  <Input
                    id="address"
                    value={order.strict_address}
                    onChange={(e) =>
                      setOrder({ ...order, strict_address: e.target.value })
                    }
                    placeholder={t("Orders.inside.create.ad")}

                  />
                </div>
                <div>
                  <Label htmlFor="shipping" className="mb-2 block">
                  {t("Orders.inside.create.shp")}

                  </Label>
                  <Input
                  value={order.shipping_price}
                    id="shipping"
                    disabled
                    type="number"
                    placeholder={t("Orders.inside.create.shp")}

                  />
                </div>
                <div>
                  <Label htmlFor="total" className="mb-2 block">
                  {t("Orders.inside.create.tp")}

                  </Label>
                  <Input
                    id="total"
                    value={order.total_price}
                    disabled
                    type="number"
                    placeholder={t("Orders.inside.create.tp")}

                  />
                </div>
              </div>
              <div className="flex items-center space-x-2">
                <Checkbox className="mx-2" checked={order.isHome} onCheckedChange={(checked)=>setOrder({...order,isHome:checked})} id="exact-address" />
                <Label htmlFor="exact-address">
                {t("Orders.inside.create.ead")}
                  
                </Label>
              </div>
            </div>
          </Card>
        </motion.div>
      </motion.div>
      <ActionFooter
        text={t("Orders.inside.create.title")}
        icon={<Save />}
        action={handleAddOrder}
        isPending={addOrderMutation.isPending}
      />
    </>
  );
}
