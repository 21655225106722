import { useState } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import {
  LockIcon,
  Sparkles,
  CheckCircle,
  Crown,
  Zap,
  LockOpenIcon,
} from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";

export default function Component() {
  const [hoveredFeature, setHoveredFeature] = useState<number | null>(null);

  const features = [
    { icon: Crown, text: "Exclusive Content" },
    { icon: Zap, text: "Faster Performance" },
    { icon: CheckCircle, text: "Priority Support" },
  ];

  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className="min-h-screen w-full flex items-center justify-center bg-gradient-to-br from-blue-50 via-indigo-50 to-purple-50 dark:from-gray-900 dark:via-blue-900 dark:to-purple-900 p-4">
      <Card className="w-full max-w-4xl bg-white/80 dark:bg-gray-800/80 backdrop-blur-lg">
        <CardHeader className="text-center pb-2">
          <CardTitle className="text-3xl font-bold text-gray-800 dark:text-white">
            Please Subscribe to enjoy the platform
          </CardTitle>
        </CardHeader>
        <CardContent className="flex flex-col md:flex-row items-center justify-between space-y-6 md:space-y-0 md:space-x-6">
          <div className="w-full md:w-1/2 space-y-4">
            <p className="text-lg text-gray-600 dark:text-gray-300 text-center md:text-left">
              Elevate your journey with our premium subscription. Gain access to
              a world of exclusive features and content.
            </p>
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
              {features.map((feature, index) => (
                <motion.div
                  key={index}
                  className="flex flex-col items-center p-4 bg-white dark:bg-gray-700 rounded-lg shadow-md transition-all duration-300 ease-in-out"
                  whileHover={{
                    scale: 1.05,
                    boxShadow: "0 8px 30px rgba(0,0,0,0.12)",
                  }}
                  onHoverStart={() => setHoveredFeature(index)}
                  onHoverEnd={() => setHoveredFeature(null)}
                >
                  <feature.icon
                    className={`w-10 h-10 mb-2 transition-colors duration-300 ${
                      hoveredFeature === index
                        ? "text-purple-900 dark:text-blue-400"
                        : "text-gray-500 dark:text-gray-400"
                    }`}
                  />
                  <span className="text-sm font-medium text-gray-700 dark:text-gray-200 text-center">
                    {feature.text}
                  </span>
                </motion.div>
              ))}
            </div>
          </div>
          <div className="w-full md:w-1/2 flex justify-center">
            <CardContent className="flex flex-col items-center space-y-6">
              <motion.div
                className="relative"
                animate={{ rotate: isHovered ? 360 : 0 }}
                transition={{ duration: 0.5 }}
              >
                {isHovered ? (
                  <LockOpenIcon className="w-20 h-20 text-gray-400 dark:text-gray-600" />
                ) : (
                  <LockIcon className="w-20 h-20 text-gray-400 dark:text-gray-600" />
                )}
                <motion.div
                  className="absolute top-0 left-0 w-full h-full"
                  animate={{
                    scale: isHovered ? 1.2 : 1,
                    opacity: isHovered ? 1 : 0,
                  }}
                  transition={{ duration: 0.3 }}
                >
                  <Sparkles className="w-10 h-10 text-yellow-400" />
                </motion.div>
              </motion.div>
              <p className="text-center text-gray-600 dark:text-gray-300">
                Unlock premium features and content by subscribing to our
                service.
              </p>
            </CardContent>
          </div>
        </CardContent>
        <CardFooter className="flex justify-center pb-6">
          <Button
            size="lg"
            className="bg-gradient-to-r from-purple-800 to-purple-900 hover:from-purple-900 hover:to-purple-950 text-white font-semibold py-2 px-6 rounded-full transition-all duration-300 transform hover:scale-105"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <Link to="/dashboard/payment">Subscribe Now</Link>
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
}
