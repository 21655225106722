import {
  Card,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";

const Notifications = () => {
  const notifications = [
    {
      id: 1,
      title: "New Feature: Inventory!",
      description:
        "You can now manage stock of your products synced with your confirmed orders",
      timestamp: "2024-09-18 12:30:45",
    },
    {
      id: 2,
      title: "New Theme Released",
      description:
        "Check out the new 'Modern Minimal' theme in the theme store.",
      timestamp: "2024-09-17 10:15:30",
    },
    {
      id: 3,
      title: "New Feature: Discount Coupons",
      description:
        "You can now create and manage discount coupons for your customers.",
      timestamp: "2024-09-16 14:00:00",
    },
    // {
    //   id: 4,
    //   title: "Order #12344 Shipped",
    //   description:
    //     "Your order #12344 has been shipped and is on its way to the customer.",
    //   timestamp: "2024-09-15 09:45:22",
    // },
    {
      id: 5,
      title: "Store Performance Alert",
      description:
        "Your store's perfomance has increased by 30% with the new release!",
      timestamp: "2024-09-14 16:20:00",
    },
  ];
  return (
    <div className="text-xl ml-4 mt-4 container">
      {notifications.map((notification) => (
        <NotificationCard
          key={notification.id}
          title={notification.title}
          description={notification.description}
          timestamp={notification.timestamp}
        />
      ))}
    </div>
  );
};
export default Notifications;

const NotificationCard = ({ title, description, timestamp }) => (
  <Card className="mb-4">
    <CardHeader>
      <CardTitle>{title}</CardTitle>
      <CardDescription>{description}</CardDescription>
    </CardHeader>
    <p className="ml-4 mb-2">{new Date(timestamp).toLocaleString()}</p>
  </Card>
);
