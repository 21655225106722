import Axios from "./Axios";

export const fetchStore = async () => {
  return new Promise((resolve, reject) => {
    Axios.get("/api/storeinfo").then((res) => {
      if (res.status === 200) {
        resolve(res.data);
      } else {
        reject();
      }
    });
  });
};

export const updateStore = async (store, images) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await Axios.put(`api/update_store`, {
        ...store,
      });
      
      if (res.status === 200) {
        if(images?.length>0){
          const formData = new FormData();
        images.forEach((image) => {
          formData.append("files", image);
        });
        images.forEach((image) => {
          formData.append("fields", image.field);
        });
        formData.append("table", "store");
        console.log(formData.get("fields"));
        const result = await Axios.post("api/upload/image/general", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        if (result.status === 200) {
          resolve(res);
        }
        }else{
        resolve(res.data);
        }
      } else throw new Error(res.data.error);
    } catch (e) {
      reject(e);
    }
  });
};

export const uploadUrl = async (image) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append("file", image);
    Axios.post("/api/upload/url", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }).then((res) => {
      if (res.status === 200) {
        resolve(res.data);
      } else {
        reject();
      }
    });
  });
};
