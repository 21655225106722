import { useContext, useEffect, useState } from "react";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { cn } from "@/lib/utils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateStore } from "@/utils/StoreUtils";
import { toast } from "@/components/ui/use-toast";
import LoaderCircle from "@/AppComponents/LoaderCircle";
import { useTranslation } from "react-i18next";
import { StoreContext } from "@/utils/StoreContext";
import { motion } from "framer-motion";
import { Sparkles, Eye, Check } from "lucide-react";

const themes = [
  {
    id: 1,
    name: "Default template",
    preview:
      "/images/template1.png",
  },
  {
    id: 2,
    name: "Hanout Template",
    preview:
      "https://demo.ar-themes.com/hanout-welcome/wp-content/themes/hanout-show/img/hanout-ar.png",
  },
  {
    id:3,
    name:"Digital theme",
    preview:"https://www.ar-themes.com/wp-content/uploads/edd/2023/10/digital-feat-img-600x400.png.webp"
  },
  {
    id: 4,
    name: "Coming Soon",
    preview:
      "https://placehold.co/400x300/34495e/FFFFFF?text=Coming+Soon&font=Raleway",
    disabled: true,
  },
];

export const Themes = () => {
  const [selectedTheme, setSelectedTheme] = useState(null);
  const { t } = useTranslation();
  const [storeData, setStoreData] = useState({});
  const queryClient = useQueryClient();
  const { storeData: store, setStore } = useContext(StoreContext);

  const handlePreview = (themeId: string) => {
    alert(`Previewing ${themeId} theme`);
  };

  const updateStoreMutation = useMutation({
    mutationFn: () => updateStore(storeData),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["store"] });
      toast({ description:t("alert.confirm_title"),variant:"default"});

    },
    onError: () => {
      updateStoreMutation.reset();
      toast({ description:t("alert.err_title"),variant:"destructive"});

    },
  });

  useEffect(() => {
    setStoreData(store);
    setSelectedTheme(store?.templateId);
  }, [store]);

  return (
    <Card className="container mx-auto  p-8 ">
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-8"
      >
        <h1 className="text-3xl font-bold mb-4 sm:mb-0 text-primary">
          {t("Settings.inside.themes.label")}
        </h1>
        <Button
          onClick={() => updateStoreMutation.mutate()}
          disabled={updateStoreMutation.isPending}
          className="w-full sm:w-auto group"
        >
          {updateStoreMutation.isPending ? (
            <LoaderCircle />
          ) : (
            <>
              {t("btns.save")}
              <Sparkles className="ml-2 h-4 w-4 opacity-70 group-hover:opacity-100 transition-opacity" />
            </>
          )}
        </Button>
      </motion.div>

      {/* <motion.div */}
      {/*   initial={{ opacity: 0, scale: 0.95 }} */}
      {/*   animate={{ opacity: 1, scale: 1 }} */}
      {/*   transition={{ duration: 0.5, delay: 0.2 }} */}
      {/* > */}
      {/*   <Card className="mb-8 bg-card/50 backdrop-blur-sm border-primary/10"> */}
      {/*     <CardHeader> */}
      {/*       <CardTitle className="text-xl sm:text-2xl text-primary"> */}
      {/*         {t("Settings.inside.themes.selected")}:{" "} */}
      {/*         <span className="font-normal text-foreground"> */}
      {/*           {themes.find((theme) => theme.id === selectedTheme)?.name || */}
      {/*             "None"} */}
      {/*         </span> */}
      {/*       </CardTitle> */}
      {/*     </CardHeader> */}
      {/*   </Card> */}
      {/* </motion.div> */}

      <RadioGroup
        value={selectedTheme}
        onValueChange={(value) => {
          setSelectedTheme(value);
          setStoreData({ ...storeData, templateId: parseInt(value) });
        }}
        className="grid gap-6 sm:grid-cols-2 lg:grid-cols-3"
      >
        {themes.map((theme, index) => (
          <motion.div
            key={theme.id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
          >
            <Card
              className={cn(
                "relative transition-all duration-300 ease-in-out transform hover:scale-105",
                selectedTheme === theme.id &&
                  "ring-2 ring-primary shadow-lg scale-[1.02] bg-secondary",
                theme.disabled && "opacity-60 cursor-not-allowed",
              )}
            >
              <CardHeader>
                <CardTitle className="text-lg sm:text-xl">
                  {theme.name}
                </CardTitle>
              </CardHeader>
              <CardContent>
                <div className="aspect-video relative overflow-hidden rounded-md">
                  <img
                    src={theme.preview}
                    alt={`Preview of ${theme.name}`}
                    className="object-cover w-full h-full transition-transform duration-300 hover:scale-110"
                  />
                </div>
              </CardContent>
              <CardFooter className="flex flex-col gap-3">
                <RadioGroupItem
                  value={theme.id}
                  id={theme.id}
                  className="sr-only"
                  disabled={theme.disabled}
                />
                <Label
                  htmlFor={theme.id}
                  className={cn(
                    "w-full flex items-center justify-center rounded-md border-2 border-muted p-2 hover:bg-accent hover:text-accent-foreground cursor-pointer text-sm transition-colors",
                    selectedTheme === theme.id
                      ? "bg-primary text-primary-foreground hover:bg-primary/90"
                      : "bg-background",
                    theme.disabled && "cursor-not-allowed opacity-50",
                  )}
                >
                  {selectedTheme === theme.id ? (
                    <>
                      {t("Settings.inside.themes.selectedbtn")}
                      <Check className="ml-2 h-4 w-4" />
                    </>
                  ) : (
                    t("Settings.inside.themes.selectbtn")
                  )}
                </Label>
                <Button
                  onClick={() => handlePreview(theme.id)}
                  variant="outline"
                  className="w-full text-sm group"
                  disabled={theme.disabled}
                >
                  {t("Settings.inside.themes.preview")}
                  <Eye className="ml-2 h-4 w-4 opacity-70 group-hover:opacity-100 transition-opacity" />
                </Button>
              </CardFooter>
            </Card>
          </motion.div>
        ))}
      </RadioGroup>
    </Card>
  );
};
