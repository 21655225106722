import { Link, Outlet, useHref } from "react-router-dom";
import {
  Activity,
  ChartNoAxesColumnDecreasing,
  FacebookIcon,
  Send,
  Sheet,
  ShieldCheck,
  SquareMousePointer,
  Tag,
  Truck,
} from "lucide-react";
import { useTranslation } from "react-i18next";

export function IntegrationsPage() {
  const { t } = useTranslation();
  const pathname = useHref();
  const links = [
    {
      name: t("Settings.inside.telegram.title"),
      icon: <Send size="20" />,
      to: "/dashboard/plugins/telegram_notifications",
    },
    {
      name: t("Settings.inside.fp.title"),
      icon: <FacebookIcon size={20} />,
      to: "/dashboard/plugins/facebook_pixel",
    },
    {
      name: t("Settings.inside.delivery.title"),
      icon: <Truck size="20" />,
      to: "/dashboard/plugins/delivery",
    },
    {
      name: t("Settings.inside.google.title"),
      icon: <Sheet size="20" />,
      to: "/dashboard/plugins/google_sheets",
    },
    {
      name: "Google Tag",
      icon: <Tag size="20" />,
      to: "/dashboard/plugins/google_tag",
    },
    {
      name: "Google Analytics",
      icon: <ChartNoAxesColumnDecreasing size="20" />,
      to: "/dashboard/plugins/google_analytics",
    },
    {
      name: "Fake Orders",
      icon: <ShieldCheck size={20} />,
      to: "/dashboard/plugins/fake_orders",
    },
    {
      name: "Widget",
      icon: <SquareMousePointer size={20} />,
      to: "/dashboard/plugins/widget",
    },
    {
      name: "TikTok Pixel",
      icon: <Activity size={20} />,
      to: "/dashboard/plugins/tiktok_pixel",
    },
  ];
  return (
    <div className="flex lg:flex-row flex-col min-h-screen w-full  ">
    <div className=" lg:w-64 border-r bg-background p-2 sticky  md:z-0 z-30 md:block">
      <div className="flex lg:h-full  flex-col ">
        <nav className="flex lg:flex-col flex-row gap-2 overflow-auto  ">
          {links.map((link, index) => (
            <Link
              key={index}
              to={link.to}
              className={`${pathname === link.to ? "bg-accent" : null}  flex items-center gap-3 rounded-md px-3 py-2 transition-colors text-muted-foreground hover:bg-accent hover:text-accent-foreground `}
            >
              {link.icon}
              <span className="text-xs">{link.name}</span>
            </Link>
          ))}
        </nav>
      </div>
    </div>
    <div className="flex-1 p-6 md:p-8">
      <div className="">
        <Outlet />
      </div>
    </div>
  </div>
  );
}
