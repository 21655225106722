import { Button } from "@/components/ui/button";
import { AlignEndVertical, Menu, MenuIcon, X } from "lucide-react";
import { Link } from "react-router-dom";
import LanguageToggle from "./LanguageToggle";
import Themetoggle from "./Themetoggle";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

function HomeNavbar() {
  const { t,i18n:{language} } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header  className={`fixed w-full z-50 [transition:0.3s_all_ease] pt-3 md:mt-4 px-2.5 md:px-6  ${language=="ar"?"font-ar":"font-proxima-sb"}` }>
  <nav className="w-full backdrop-blur-md bg-background/30  max-w-[1216px] mx-auto px-4 py-3.5 md:px-6 md:py-5 flex justify-between items-center rounded-2xl ">
    <Link to="/" aria-label={t("landingheader.logo_alt")} className="flex" >
      <img
        alt={t("landingheader.logo_alt")}
        className="h-10 md:h-10 w-auto"
        src="/images/banner.svg"
      />
    </Link>
    <div className="flex items-center">
      <div className=" lg:hidden">
        <Themetoggle />
        <LanguageToggle />
      </div>
     
      <div className="relative z-50">
      {/* Hamburger menu button */}
      <Button onClick={()=>toggleMenu()} size="icon" variant="ghost" className="lg:hidden  rounded-md flex ">
        <AlignEndVertical />
      </Button>

      {/* Overlay */}
      {isMenuOpen && (
        <Button onClick={()=>toggleMenu()} size="icon" variant="ghost" className="lg:hidden top-0 absolute z-50 hover:bg-slate-100 rounded-md flex ">
        <X className="text-black" />
      </Button>
      )}

      {/* Menu items */}
      <nav
        className={`fixed top-0 left-1/2 -translate-x-1/2 w-full bg-white shadow-lg transition-all duration-300 ease-in-out ${
          isMenuOpen ? 'translate-y-0 opacity-100' : '-translate-y-full opacity-0'
        }`}
      >
        <ul className="py-4">
        
        <Link to={"/"} onClick={toggleMenu}>
            <p
              
              className="block px-4 py-2 text-center text-gray-800 hover:bg-gray-100"
            >
               {t("landingheader.home")}
            </p>
          </Link>
          <Link to={"/login"} onClick={toggleMenu}>
            <p
              
              className="block px-4 py-2 text-center text-gray-800 hover:bg-gray-100"
            >
               {t("landingheader.login")}
            </p>
          </Link>
          <Link to={"/register"} onClick={toggleMenu}>
            <p
              
              className="block px-4 py-2 text-center text-gray-800 hover:bg-gray-100"
            >
               {t("landingheader.register")}
            </p>
          </Link>
          <Link to="/about" onClick={toggleMenu}>
            <p
              
              className="block px-4 py-2 text-center text-gray-800 hover:bg-gray-100"
            >
               {t("landingheader.about_us")}
            </p>
          </Link>
          <Link to="/pricing" onClick={toggleMenu}>
            <p
              
              className="block px-4 py-2 text-center text-gray-800 hover:bg-gray-100"
            >
               {t("landingheader.pricing")}
            </p>
          </Link>
          <a href="/#templates" onClick={toggleMenu}>
            <p
              
              className="block px-4 py-2 text-center text-gray-800 hover:bg-gray-100"
            >
               {t("landingheader.templates")}
            </p>
          </a>
          <Link to={"/privacy"} onClick={toggleMenu}>
            <p
              
              className="block px-4 py-2 text-center text-gray-800 hover:bg-gray-100"
            >
               {t("landingheader.privacy")}
            </p>
          </Link>
          <Link to={"/tos"} onClick={toggleMenu}>
            <p
              
              className="block px-4 py-2 text-center text-gray-800 hover:bg-gray-100"
            >
               {t("landingheader.tos")}
            </p>
          </Link>
         
         
         
        </ul>
      </nav>
    </div>
    </div>
   
    <nav id="nav-list" className="hidden lg:w-full lg:flex lg:items-center lg:justify-center">
      <ul className="mx-auto flex  items-center justify-center px-5 gap-5">
        <Link to="/" className="list-item hover:bg-accent rounded-xl not:last-child">
          <p
            aria-label={t("landingheader.home_link_aria")}
            className="flex  items-center justify-center text-lg leading-[18px] font-semibold text-primary-dark-200 rounded-md p-3 cursor-pointer hover:bg-interaction-100 transition-all"
            >
            {t("landingheader.home")}
          </p>
        </Link>
        <li className="list-item hover:bg-accent rounded-xl not:last-child">
          <a
            aria-label={t("landingheader.templates_link_aria")}
            className="flex items-center justify-center text-lg leading-[18px] font-semibold text-primary-dark-200 rounded-md p-3 cursor-pointer hover:bg-interaction-100 transition-all"
            href="/#templates">
            {t("landingheader.templates")}
          </a>
        </li>
        <li className="list-item hover:bg-accent rounded-xl not:last-child">
          <Link
            aria-label={t("landingheader.pricing_link_aria")}
            className="flex items-center justify-center text-lg leading-[18px] font-semibold text-primary-dark-200 rounded-md p-3 cursor-pointer hover:bg-interaction-100 transition-all"
            to="/pricing">
            {t("landingheader.pricing")}
          </Link>
        </li>
        
        <Link to="/about"  className="list-item hover:bg-accent rounded-xl not:last-child">
          <p
            aria-label={t("landingheader.about_us_link_aria")}
            className="flex items-center justify-center text-lg leading-[18px] font-semibold text-primary-dark-200 rounded-md p-3 cursor-pointer hover:bg-interaction-100 transition-all"
            >
            {t("landingheader.about_us")}
          </p>
        </Link>
      </ul>
      <div className="px-4">
        <Themetoggle />
        <LanguageToggle />
      </div>
      <div id="nav-actions" className="flex items-center justify-end gap-3.5">
        <Link
          aria-label={t("landingheader.login_button_aria")}
          className="p-3 px-8 bg-accent rounded-xl hover:opacity-90"
          to="/login">
          {t("landingheader.login")}
        </Link>
        <div id="get-early-access" className="action-container">
          <Link
            aria-label={t("landingheader.get_started_button_aria")}
            className="bg-primary p-3 px-8 rounded-xl ring-2 hover:opacity-90 text-white"
            to="/register">
            {t("landingheader.get_started")}
          </Link>
        </div>
      </div>
    </nav>
  </nav>
</header>

  );
}

export default HomeNavbar;

