import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function HomeFooter() {
  const {t}=useTranslation()
  return (
    <footer className="flex flex-row justify-between gap-2 sm:flex-row py-6 w-full shrink-0 items-center px-4 md:px-6 border-t">
  <p className="text-xs text-gray-500 dark:text-gray-400">
    {t("footer.copyright")}
  </p>
  <nav className="flex gap-4 sm:gap-6">
    <Link
      className="text-xs hover:underline underline-offset-4"
      to="/tos"
    >
      {t("footer.links.termsOfService")}
    </Link>
    <Link
      className="text-xs hover:underline underline-offset-4"
      to="/privacy"
    >
      {t("footer.links.privacy")}
    </Link>
  </nav>
</footer>

  );
}
export default HomeFooter;
