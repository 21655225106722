import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import LoaderCircle from "@/AppComponents/LoaderCircle";
import SaveChangesButton from "@/AppComponents/SaveChangesButton";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { toast } from "@/components/ui/use-toast";
import { fetchPixels, updatePixels } from "@/utils/PixelUtils";

export default function TiktokPixel() {
  const [Pixels, setPixels] = useState({ old_pixels: [], pixels: [] });
  const { t } = useTranslation();
  const { data: fetchedPixels, isLoading } = useQuery({
    queryKey: ["pixels"],
    queryFn: () => fetchPixels(),
  });

  useEffect(() => {
    if (fetchedPixels) {
      setPixels({ old_pixels: fetchedPixels, pixels: [...fetchedPixels] });
    }
  }, [fetchedPixels]);

  const queryClient = useQueryClient();
  const updatePixelMutation = useMutation({
    mutationFn: () => updatePixels(Pixels),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["pixels"] });
      toast({ description:t("alert.confirm_title"),variant:"default"});

    },
    onError: () => {
      updatePixelMutation.reset();
      toast({ description:t("alert.err_title"),variant:"destructive"});

    },
  });

  // if (isLoading) return <LoaderCircle />;

  const handlePixelChange = (index, value) => {
    setPixels((prevPixels) => ({
      ...prevPixels,
      pixels: prevPixels.pixels.map((pixel, i) =>
        i === index ? value : pixel,
      ),
    }));
  };

  const addNewPixel = () => {
    setPixels((prevPixels) => ({
      ...prevPixels,
      pixels: [...prevPixels.pixels, ""],
    }));
  };

  const removePixel = (index) => {
    setPixels((prevPixels) => ({
      ...prevPixels,
      pixels: prevPixels.pixels.filter((_, i) => i !== index),
    }));
  };

  return (
    <div className="container mx-auto px-4">
      <SaveChangesButton
        btnlabel={t("btns.save")}
        isPending={updatePixelMutation.isPending}
        action={() => updatePixelMutation.mutate()}
        label={"Tiktok Pixel Settings"}
      />
      <div className="text-xl ml-4 mt-4">Coming Soon ... </div>
      {/* <h2 className="text-xl font-bold mt-6 mb-4">Current Pixels</h2> */}
      {/* {Pixels.pixels && Pixels.pixels.length > 0 ? ( */}
      {/*   Pixels.pixels.map((pixel, idx) => ( */}
      {/*     <div key={idx} className="grid gap-6 md:grid-cols-2 mb-4"> */}
      {/*       <div className="grid gap-2"> */}
      {/*         <Label htmlFor={`pixel-${idx}`}>Pixel {idx + 1}</Label> */}
      {/*         <div className="flex items-center"> */}
      {/*           <Input */}
      {/*             id={`pixel-${idx}`} */}
      {/*             onChange={(e) => handlePixelChange(idx, e.target.value)} */}
      {/*             value={pixel} */}
      {/*             className="flex-grow" */}
      {/*           /> */}
      {/*           <Button */}
      {/*             onClick={() => removePixel(idx)} */}
      {/*             className="ml-2" */}
      {/*             variant="destructive" */}
      {/*           > */}
      {/*             Remove */}
      {/*           </Button> */}
      {/*         </div> */}
      {/*       </div> */}
      {/*     </div> */}
      {/*   )) */}
      {/* ) : ( */}
      {/*   <p>No pixels found.</p> */}
      {/* )} */}

      {/* <Button onClick={addNewPixel} className="mt-4"> */}
      {/*   Add New Pixel */}
      {/* </Button> */}
    </div>
  );
}
