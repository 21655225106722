import { Button, buttonVariants } from "@/components/ui/button";
import { LoaderCircle, Plus } from "lucide-react";
import React from "react";
import { Link } from "react-router-dom";

export default function ActionFooter({
  text,
  action,
  type,
  to,
  icon,
  isPending,
}) {
  if (type === "link")
    return (
      <>
      <div className="p-5">

      </div>
        <div className="bg-background p-2 w-svw fixed bottom-0   border-t ">
        <Link
          to={to}
          className={buttonVariants({
            size: "lg",
            className: "gap-4 text-white ",
          })}
        >
          {text}
          <div>{icon}</div>
        </Link>
      </div>
      </>
    );

  return (
    <>
    <div className="p-5">

    </div>
    <div className="bg-background p-2 w-svw fixed bottom-0   border-t ">
      <Button onClick={action} size="lg" className="gap-4 text-white">
        {!isPending ? (
          <>
            {text}
            {icon}
          </>
        ) : (
          <LoaderCircle className="animate-spin" />
        )}
      </Button>
    </div>
    </>
  );
}
