import { useEffect, useState } from "react";

import ThreeTemplate from "./ThreeTemplate";
import { Link } from "react-router-dom";

export default function TemplateThreeHomePage({ store,mainColor,styling,selectedSection,setselectedSection }) {
  
  return (
    <ThreeTemplate store={store} styling={styling} isLoading={false} selectedSection={selectedSection} setselectedSection={setselectedSection}>
      {/* adds space */}

      {/* Navbar */}

      {/* Hero Section */}
      <section className="flex items-center text-center ">
        <div className="pt-4 w-full">
          <h1 className="text-3xl font-bold text-gray-800">
            {store?.storeAdOne}
          </h1>
          <p className="text-gray-600 mt-2">{store?.storeAdTwo}</p>
        </div>
      </section>
      {/* Product Card */}
      <div className="gap-4 px-4 xl:mx-20 mt-5 grid grid-cols-2  md:grid-cols-3 lg:grid-cols-5">
        
          <div className="bg-white shadow flex flex-col border-1 border-transparent hover:border-blue-600">
          <div className="w-full h-40 lg:h-50 xl:h-60">
            <img
              alt={"lt"}
              className="object-cover w-full h-full"
              src={store?.logo}
            />
          </div>
          <div className="flex flex-1 p-2  flex-col  text-center ">
            <div className="flex-1 ">
              <h1 className="font-bold text-xl">اسم المنتج</h1>
              <div className="flex py-2 text-center justify-center font-bold">
                <p className="text-blue-600 px-4">
                  1000
                  {store?.Currency}
                </p>
              </div>
            </div>
            <Link
              style={{backgroundColor:styling.mainColor}}
              className="w-full   focus:ring rounded-md text-center p-4 font-bold hover:ring "
              to={`/`}>
              اشتري الان
            </Link>
          </div>
        </div>
       
      </div>
      <section className="flex items-center text-center pt-5 ">
        <div className="pt-4 w-full">
          <h1 className="text-3xl font-bold text-gray-800">أقسام المتجر</h1>
        </div>
      </section>
      <section className="px-4 sm:mx-40 md:mx-20 grid grid-cols-1 md:grid-cols-2 gap-4 pt-8">
        <div className="shrink relative">
          <img
            className="w-full h-full object-cover relative rounded-md "
            src="https://demo.ar-themes.com/hanout-ar/wp-content/uploads/sites/77/2024/04/iE6B55t8RFaueJzlFpuA-910x510.png-1-910x510.png"
          />
          <div className="absolute bottom-20 px-4">
            <h1 className="text-2xl text-white font-bold">معدات التصوير</h1>
          </div>
          <a
            href=""
            className="px-4 p-2 bg-blue-600 absolute bottom-4 right-4 font-bold text-white hover:ring rounded-xl">
            تسوق الأن
          </a>
        </div>
        <div className="shrink relative">
          <img
            className="w-full h-full object-cover relative rounded-md "
            src="https://demo.ar-themes.com/hanout-ar/wp-content/uploads/sites/77/2024/04/iE6B55t8RFaueJzlFpuA-910x510.png-1-910x510.png"
          />
          <div className="absolute bottom-20 px-4">
            <h1 className="text-2xl text-white font-bold">معدات التصوير</h1>
          </div>
          <a
            href=""
            className="px-4 p-2 bg-blue-600 absolute bottom-4 right-4 font-bold text-white hover:ring rounded-xl">
            تسوق الأن
          </a>
        </div>
        <div className="shrink relative">
          <img
            className="w-full h-full object-cover relative rounded-md "
            src="https://demo.ar-themes.com/hanout-ar/wp-content/uploads/sites/77/2024/04/iE6B55t8RFaueJzlFpuA-910x510.png-1-910x510.png"
          />
          <div className="absolute bottom-20 px-4">
            <h1 className="text-2xl text-white font-bold">معدات التصوير</h1>
          </div>
          <a
            href=""
            className="px-4 p-2 bg-blue-600 absolute bottom-4 right-4 font-bold text-white hover:ring rounded-xl">
            تسوق الأن
          </a>
        </div>
        <div className="shrink relative">
          <img
            className="w-full h-full object-cover relative rounded-md "
            src="https://demo.ar-themes.com/hanout-ar/wp-content/uploads/sites/77/2024/04/iE6B55t8RFaueJzlFpuA-910x510.png-1-910x510.png"
          />
          <div className="absolute bottom-20 px-4">
            <h1 className="text-2xl text-white font-bold">معدات التصوير</h1>
          </div>
          <a
            href=""
            className="px-4 p-2 bg-blue-600 absolute bottom-4 right-4 font-bold text-white hover:ring rounded-xl">
            تسوق الأن
          </a>
        </div>
      </section>
    </ThreeTemplate>
  );
}
