import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Axios from "@/utils/Axios";

const LoginAuthProvider = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const res = await Axios.get("/auth/checkauth");
        const data = await res.data;

        if (data.isAuthenticated) {
          navigate("/dashboard");
        }
      } catch (error) {
        console.error("Error checking authentication:", error);
      }
    };

    checkAuth();
  }, [navigate]);
  return <>{children}</>;
};

export default LoginAuthProvider;
