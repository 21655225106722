import { format, isToday, parseISO } from "date-fns";
import Axios from "./Axios";

export const fetchClientsStats = async () => {
  return new Promise((resolve, reject) => {
    Axios.get("api/stats/clients").then((res) => {
      if (res.status === 200) {
        resolve({ data: res.data });
      } else {
        reject();
      }
    });
  });
};

export const fetchOrdersStats = async () => {
  return new Promise((resolve, reject) => {
    Axios.get("api/stats/orders").then((res) => {
      if (res.status === 200) {
        const orderData = Object.entries(res.data.data).map(
          ([date, orders]) => {
            const parsedDate = parseISO(date);

            // Format the date as "dd/MM" or "Today" if it's the current day
            const formattedDate = isToday(parsedDate)
              ? "Today"
              : format(parsedDate, "dd/MM");

            return { name: formattedDate, orders };
          },
        );

        // console.log("fromatted Dates are: ", orderData);

        resolve({ data: orderData });
      } else {
        reject();
      }
    });
  });
};

export const fetchOrdersDiff = async () => {
  return new Promise((resolve, reject) => {
    Axios.get("api/stats/orders/diff").then((res) => {
      if (res.status === 200) {
        resolve({ data: res.data });
      } else {
        reject();
      }
    });
  });
};

export const fetchSales = async () => {
  return new Promise((resolve, reject) => {
    Axios.get("api/stats/sales").then((res) => {
      if (res.status === 200) {
        const formattedSales = Object.entries(res.data.data.salesPerDay).map(
          ([name, sales]) => ({
            name,
            sales,
          }),
        );
        resolve({ data: { total: res.data.data.total, formattedSales } });
      } else {
        reject();
      }
    });
  });
};

export const fetchSalesDiff = async () => {
  return new Promise((resolve, reject) => {
    Axios.get("api/stats/sales/diff").then((res) => {
      if (res.status === 200) {
        resolve(res.data);
      } else {
        reject();
      }
    });
  });
};

export const moreStats=async()=>{
  return new Promise((resolve, reject) => {
    Axios.get("api/stats/total").then((res) => {
      if (res.status === 200) {
       
        resolve(res.data);
      } else {
        reject();
      }
    });
  });
}
