import React, { useState } from "react";
import { motion } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useMutation } from "@tanstack/react-query";
import Axios from "@/utils/Axios";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useToast } from "@/components/ui/use-toast";
import { Loader2, Eye, EyeOff, Lock, ArrowBigLeft, ArrowLeft, ArrowRight } from "lucide-react";
import { cn } from "@/lib/utils";
import { useTranslation } from "react-i18next";
import HomeNavbar from "@/AppComponents/homeNavbar";
import LanguageToggle from "@/AppComponents/LanguageToggle";
import ThemeToggle from "@/AppComponents/Themetoggle";

const FormSchema = z.object({
  email: z.string().trim().email({ message: "Please enter a valid email" }),
  password: z.string().min(6, {
    message: "Password must be at least 6 characters.",
  }),
});

export default function Login() {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [showPassword, setShowPassword] = useState(false);
  const {t,i18n:{language}}=useTranslation()
  const form = useForm({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const loginMutation = useMutation({
    mutationFn: (data) => Axios.post("/auth/login", { data }),
    onSuccess: (res) => {
      toast({ title: res.data.message });
      form.reset();
      if (res.data.success) {
        navigate("/dashboard");
      }
    },
    onError: (error) => {
      console.error("Login Failed:", error);
      toast({
        title: "Login Failed",
        description: error.message,
        variant: "destructive",
      });
    },
  });

  const onSubmit = (data) => {
    loginMutation.mutate(data);
  };

  return (
    <div className="flex min-h-screen ">
       
      <div className="flex flex-col   justify-center items-center w-full ">
     
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="w-full max-w-md relative  rounded-lg  p-8"
        >
          <Button onClick={()=>navigate("/")} className="rounded-full absolute top-10" size="icon" variant="ghost">
            {language==="ar"?<ArrowRight/>:<ArrowLeft/>}
          </Button>
          <div className="text-center mb-8 ">
            <Link to="/" className="flex justify-center">
            <img
              src="/images/banner.svg"
              alt="Logo"
              className="p-2 w-1/2"
            />
            
            </Link>
           
            <h2 className="text-3xl mt-10 font-bold ">{t("Login.title")}</h2>
            <p className="text-accent-foreground mt-2">{t("Login.description")}</p>
          </div>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t("Login.emailLabel")}</FormLabel>
                    <FormControl>
                      <Input placeholder={t("Login.emailPlaceholder")} {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="password"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t("Login.passwordLabel")}</FormLabel>
                    <FormControl>
                      <div className="relative">
                        <Input
                          type={showPassword ? "text" : "password"}
                          placeholder={t("Login.passwordPlaceholder")}
                          {...field}
                          className="pr-10"
                        />
                        <button
                          type="button"
                          onClick={() => setShowPassword(!showPassword)}
                          className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400 hover:text-gray-600"
                        >
                          {showPassword ? (
                            <EyeOff className="h-5 w-5" />
                          ) : (
                            <Eye className="h-5 w-5" />
                          )}
                        </button>
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Button
                type="submit"
                className={cn(
                  "w-full text-white hover:ring",
                  loginMutation.isPending && "opacity-50 cursor-not-allowed",
                )}
                disabled={loginMutation.isPending}
              >
                {loginMutation.isPending ? (
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                ) : (
                  t("Login.signIn")
                )}
              </Button>
            </form>
          </Form>
          <div className="mt-6 text-center">
            <Link
              to="/password-forgot"
              className="text-sm text-purple-800 hover:underline"
            >
              {t("Login.forgotPassword")}
            </Link>
          </div>
          <div className="mt-4 text-center">
            <p className="text-gray-600">
              
              <Link
                to="/register"
                className="text-purple-900 hover:underline font-semibold"
              >
                {t("Login.createAccount")}
              </Link>
            </p>
          </div>
        </motion.div>
      </div>
    </div>
  );
}
