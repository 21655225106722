import ActionFooter from "@/AppComponents/ActionFooter";
import { AlertDialogC } from "@/AppComponents/AlertDialogC";
import CSVExportButton from "@/AppComponents/CSVExportButton";
import DataTable from "@/AppComponents/DataTable";
import EmptyTableComponent from "@/AppComponents/EmptyTableComponent";
import LoaderCircle from "@/AppComponents/LoaderCircle";
import PaginationComponent from "@/AppComponents/PaginationComponent";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { Checkbox } from "@/components/ui/checkbox";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Separator } from "@/components/ui/separator";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Textarea } from "@/components/ui/textarea";
import { toast } from "@/components/ui/use-toast";
import Axios from "@/utils/Axios";
import { deleteClient, fetchClients } from "@/utils/ClientUtils";
import { prepareCustomers } from "@/utils/csv/prepare";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Plus, Save, Trash } from "lucide-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function Clients() {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(8);
  const [clients, setClients] = useState([]);
  const [filter, setFilter] = useState("");
  const [selectedClients, setSelectedClients] = useState([]);

  const { data: Clients, isLoading } = useQuery({
    queryKey: ["clients", page, pageSize],
    queryFn: () => fetchClients(page, pageSize),
  });

  const queryClient = useQueryClient();

  const deleteClientMutation = useMutation({
    mutationFn: (id) => deleteClient(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["clients"] });
      toast({ description: t("alert.confirm_title"), variant: "default" });
    },
    onError: () => {
      deleteClientMutation.reset();
      toast({ description: t("alert.err_title"), variant: "destructive" });
    },
  });

  const deleteMultipleClientsMutation = useMutation({
    mutationFn: (ids) =>
      Axios.delete("/api/client/delete/multi", { data: { ids } }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["clients"] });
      toast({ title: "Selected clients deleted" });
      setSelectedClients([]);
    },
    onError: () => {
      deleteMultipleClientsMutation.reset();
      toast({ title: "Error deleting clients, please try again" });
    },
  });

  useEffect(() => {
    if (Clients) {
      setClients(Clients.data);
    }
  }, [Clients]);

  useEffect(() => {
    if (Array.isArray(clients)) {
      const filteredItems = Clients?.data?.filter(
        (item) =>
          item?.fullname.toLowerCase().includes(filter.toLowerCase()) ||
          item?.phone_number.includes(filter.toLowerCase()) ||
          item?.email?.includes(filter.toLowerCase()),
      );
      setClients(filteredItems);
    }
  }, [filter]);

  const handleSelectClient = (clientId) => {
    setSelectedClients((prev) =>
      prev.includes(clientId)
        ? prev.filter((id) => id !== clientId)
        : [...prev, clientId],
    );
  };

  const handleSelectAll = () => {
    if (
      clients &&
      selectedClients &&
      clients?.length &&
      selectedClients?.length
    ) {
      if (selectedClients?.length === clients?.length) {
        setSelectedClients([]);
      } else {
        setSelectedClients(clients.map((client) => client.id));
      }
    }
  };

  const handleDeleteSelected = () => {
    if (selectedClients?.length > 0) {
      deleteMultipleClientsMutation.mutate(selectedClients);
    }
  };

  const pages = Math.ceil(Clients?.count / pageSize);

  // if (
  //   deleteClientMutation.isPending ||
  //   deleteMultipleClientsMutation.isPending ||
  //   isLoading
  // )
  //   return <LoaderCircle />;
  const { t } = useTranslation();
  const columns = [
    t("btns.select"),
    t("Orders.inside.table.c"),
    t("Orders.inside.table.p"),
    t("Orders.inside.create.em"),
    t("btns.date"),
    t("general.orders"),
    "Segment",
    t("Orders.inside.table.r"),
  ];
  if (Clients?.count === 0)
    return <EmptyTableComponent to="/dashboard/clients/new" />;
  console.log(Clients);

  return (
    <>
      <div className="flex-1">
        <div className="p-2">
          <Card className="p-2 my-2">
            <div className="flex flex-col space-y-4">
              <div className="flex space-x-4">
                <Input
                  onChange={(e) => setFilter(e.target.value)}
                  className="font-bold "
                  color="none"
                  placeholder={t("Orders.inside.search")}
                />
                <Select onValueChange={(value) => setPageSize(value * 1)}>
                  <SelectTrigger className="w-fit">
                    <SelectValue placeholder={t("btns.items")} />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectLabel>Categories</SelectLabel>
                      <SelectItem value={8}>8</SelectItem>
                      <SelectItem value={16}>16</SelectItem>
                      <SelectItem value={24}>24</SelectItem>
                      <SelectItem value={50}>50</SelectItem>
                      <SelectItem value={100}>100</SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>

                <CSVExportButton
                  data={clients}
                  filename="customers.csv"
                  prepareData={prepareCustomers}
                />
              </div>
              <div className="flex justify-between items-center">
                <Button
                  onClick={handleDeleteSelected}
                  disabled={selectedClients?.length === 0}
                  variant="destructive"
                >
                  {t("btns.ds")} ({selectedClients?.length})
                </Button>
                <div className="flex items-center space-x-2">
                  <Checkbox
                    id="select-all"
                    className="mx-2"
                    checked={selectedClients?.length === clients?.length}
                    onCheckedChange={handleSelectAll}
                  />
                  <label htmlFor="select-all">{t("btns.sa")}</label>
                </div>
              </div>
              {isLoading ? (
                <LoaderCircle />
              ) : (
                <div className="border rounded-md">
                <Table>
                  <TableHeader>
                    <TableRow className="text-start">
                      {columns &&
                        columns.map((col, index) => (
                          <TableHead key={index} className="text-start">
                            {col}
                          </TableHead>
                        ))}
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {clients?.length &&
                      clients.map((client, i) => (
                        <TableRow key={i}>
                          <TableCell>
                            <Checkbox
                              checked={selectedClients.includes(client.id)}
                              onCheckedChange={() =>
                                handleSelectClient(client.id)
                              }
                            />
                          </TableCell>
                          <TableCell>{client.fullname}</TableCell>
                          <TableCell>{client.phone_number}</TableCell>
                          <TableCell>{client.email}</TableCell>
                          <TableCell>
                            {new Date(client.createdAt).toDateString()}
                          </TableCell>
                          <TableCell>{client?.demands?.length}</TableCell>
                          <TableCell>
                            <Badge>{client?.segment}</Badge>
                          </TableCell>
                          <TableCell>
                            <AlertDialogC
                              action={() =>
                                deleteClientMutation.mutate(client.id)
                              }
                            >
                              <Trash size="20" />
                            </AlertDialogC>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
                </div>
              )}
              <PaginationComponent
                pages={pages}
                setpage={setPage}
                currentpage={page}
              />
            </div>
          </Card>
        </div>
      </div>
      <ActionFooter
        text={t("btns.add_cstmrs")}
        icon={<Plus />}
        type="link"
        to={"/dashboard/clients/new"}
      />
    </>
  );
}
