import ActionFooter from "@/AppComponents/ActionFooter";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Separator } from "@/components/ui/separator";
import { toast } from "@/components/ui/use-toast";
import Axios from "@/utils/Axios";
import { addCategory } from "@/utils/CategoryUtils";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Save } from "lucide-react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export default function AddCategory() {
  const [category, setcategory] = useState({
    name: "",
  });
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const addcategorymutation = useMutation({
    mutationFn: () => addCategory(category),
    onSuccess: () => {
      toast({ description: t("alert.confirm_title") });
      queryClient.invalidateQueries({ queryKey: ["categories"] });
      setcategory({ name: "" });
    },
  });
  const handleAddCategory = () => {
    if (category.name == "") {
      toast({
        title: t("alert.err_title"),
        description: t("alert.err_d"),
        variant: "destructive",
      });
    } else {
      addcategorymutation.mutate();
    }
  };

  return (
    <>
      <div className="container">
        <h1 className="text-xl p-2">{t("Categories.add")}</h1>

        <Card className="p-2 space-y-2">
          <Input
            value={category.name}
            onChange={(e) => setcategory({ ...category, name: e.target.value })}
            placeholder={t("Categories.name")}
          />
        </Card>
      </div>
      <ActionFooter
        text={t("btns.save")}
        icon={<Save />}
        action={handleAddCategory}
        isPending={addcategorymutation.isPending}
      />
    </>
  );
}
