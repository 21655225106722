"use client";

import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { Check, Copy } from "lucide-react";

export default function CopyableTextarea() {
  const [isCopied, setIsCopied] = useState(false);

  const content = `
var wbook = SpreadsheetApp.openByUrl('https://docs.google.com/spreadsheets/d/1x0MQbCSb7K3K5HRddzAZRhQ3j7hczYsd-LP75sry_bU/edit')
var sheet = wbook.getSheetByName('Sheet1'); 
// step 2 
function doPost(e) { 
  return addUser(e);
} 
// step 3 
function addUser(e) { 
  var user = JSON.parse(e.postData.contents) 
  sheet.appendRow([new Date, user.product, user.name, user.phone, user.state, user.commune, user.address, user.qty, user.price, user.deleveryPrice, user.total, user.options]) 
  // step 4 
  return ContentService.createTextOutput("Success").setMimeType(ContentService.MimeType.TEXT);
}`;
  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(content);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  return (
    <div className="w-full max-w-2xl mx-auto space-y-4 p-4">
      <Textarea
        value={content}
        readOnly
        className="min-h-[200px] resize-none"
      />
      <Button onClick={copyToClipboard} className="w-full sm:w-auto">
        {isCopied ? (
          <>
            <Check className="mr-2 h-4 w-4" /> Copied
          </>
        ) : (
          <>
            <Copy className="mr-2 h-4 w-4" /> Copy Script
          </>
        )}
      </Button>
    </div>
  );
}
