import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Switch } from "@/components/ui/switch";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import {
  Check,
  Copy,
  CreditCard,
  Lock,
  AlertTriangle,
  Zap,
} from "lucide-react";
import { Badge } from "@/components/ui/badge";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { useTranslation } from "react-i18next";

export default function Pricing() {
  const [selectedPlan, setSelectedPlan] = useState("");
  const [copiedCCP, setCopiedCCP] = useState(false);
  const [copiedPayseera, setCopiedPayseera] = useState(false);
  const [isYearly, setIsYearly] = useState(false);
  const [promoCode, setPromoCode] = useState("");
  const { t } = useTranslation();

  const currentPlan = {
    name: "Basic",
    endDate: new Date("2024-12-31"),
  };

  const ccpNumber = "123456789";
  const payseeraAddress = "payseera@example.com";

  const copyToClipboard = (text, setCopied) => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  const plans = {
    plan1: {
      name: t("Paymentp.plans.plan1.name"),
      priceMonthly: t("Paymentp.plans.plan1.priceMonthly"),
      priceYearly: t("Paymentp.plans.plan1.priceYearly"),
      features: [
        t("Paymentp.plans.plan1.features.feature1"),
        t("Paymentp.plans.plan1.features.feature2"),
        t("Paymentp.plans.plan1.features.feature3"),
        t("Paymentp.plans.plan1.features.feature4"),
        t("Paymentp.plans.plan1.features.feature5"),
        t("Paymentp.plans.plan1.features.feature6"),
        t("Paymentp.plans.plan1.features.feature7"),
        t("Paymentp.plans.plan1.features.feature8"),
        t("Paymentp.plans.plan1.features.feature9"),
        t("Paymentp.plans.plan1.features.feature10"),
        t("Paymentp.plans.plan1.features.feature11"),
      ],
      color: "bg-blue-500",
    },
    plan2: {
      name: t("Paymentp.plans.plan2.name"),
      priceMonthly: t("Paymentp.plans.plan2.priceMonthly"),
      priceYearly: t("Paymentp.plans.plan2.priceYearly"),
      features: [
        t("Paymentp.plans.plan2.features.feature1"),
        t("Paymentp.plans.plan2.features.feature2"),
        t("Paymentp.plans.plan2.features.feature3"),
        t("Paymentp.plans.plan2.features.feature4"),
        t("Paymentp.plans.plan2.features.feature5"),
        t("Paymentp.plans.plan2.features.feature6"),
        t("Paymentp.plans.plan2.features.feature7"),
        t("Paymentp.plans.plan2.features.feature8"),
        t("Paymentp.plans.plan2.features.feature9"),
        t("Paymentp.plans.plan2.features.feature10"),
        t("Paymentp.plans.plan2.features.feature11"),
        t("Paymentp.plans.plan2.features.feature12"),
        t("Paymentp.plans.plan2.features.feature13"),
        t("Paymentp.plans.plan2.features.feature14"),
      ],
      color: "bg-purple-900",
      popular: true,
    },
    plan3: {
      name: t("Paymentp.plans.plan3.name"),
      priceMonthly: t("Paymentp.plans.plan3.priceMonthly"),
      priceYearly: t("Paymentp.plans.plan3.priceYearly"),
      features: [
        t("Paymentp.plans.plan3.features.feature1"),
        t("Paymentp.plans.plan3.features.feature2"),
        t("Paymentp.plans.plan3.features.feature3"),
        t("Paymentp.plans.plan3.features.feature4"),
        t("Paymentp.plans.plan3.features.feature5"),
        t("Paymentp.plans.plan3.features.feature6"),
        t("Paymentp.plans.plan3.features.feature7"),
        t("Paymentp.plans.plan3.features.feature8"),
        t("Paymentp.plans.plan3.features.feature9"),
        t("Paymentp.plans.plan3.features.feature10"),
        t("Paymentp.plans.plan3.features.feature11"),
        t("Paymentp.plans.plan3.features.feature12"),
        t("Paymentp.plans.plan3.features.feature13"),
        t("Paymentp.plans.plan3.features.feature14"),
      ],
      color: "bg-green-500",
    },
  };

  const faqs = {
    faq1: {
      question: t("Paymentp.faqs.faq1.question"),
      answer: t("Paymentp.faqs.faq1.answer"),
    },
    faq2: {
      question: t("Paymentp.faqs.faq2.question"),
      answer: t("Paymentp.faqs.faq2.answer"),
    },
    faq3: {
      question: t("Paymentp.faqs.faq3.question"),
      answer: t("Paymentp.faqs.faq3.answer"),
    },
  };

  return (
    <motion.div
      className="container px-20 pt-32"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <motion.div
        className="flex items-center justify-center mb-12"
        variants={itemVariants}
      >
        <Label htmlFor="billing-toggle" className="mx-4 text-lg">
          {t("Paymentp.yearly")}
        </Label>
        <Switch
          dir="ltr"
          id="billing-toggle"
          checked={isYearly}
          onCheckedChange={setIsYearly}
        />
        <Label htmlFor="billing-toggle" className="mx-4 text-lg">
          {t("Paymentp.monthly")}
        </Label>
        {isYearly && (
          <Badge variant="secondary" className="ml-2">
            {t("Paymentp.save20Percent")}
          </Badge>
        )}
      </motion.div>

      <motion.div
        className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16"
        variants={containerVariants}
      >
        {Object.values(plans).map((plan) => (
          <motion.div key={plan.name} variants={itemVariants}>
            <Card
              className={` h-full ${
                selectedPlan === plan.name ? "border-primary border-2" : ""
              } hover:shadow-xl transition-shadow duration-300 relative overflow-hidden`}
            >
              {plan.popular && (
                <div className="absolute top-0 right-0 bg-yellow-400 text-yellow-900 px-3 py-1 rounded-bl-lg font-semibold z-10">
                  {t("Paymentp.popular")}
                </div>
              )}
              <div className={`h-2 ${plan.color}`} />
              <CardHeader>
                <CardTitle className="text-2xl">{plan.name}</CardTitle>
                <CardDescription className="text-xl font-semibold">
                  DA{isYearly ? plan.priceYearly : plan.priceMonthly}/
                  {isYearly ? t("Paymentp.year") : t("Paymentp.month")}
                </CardDescription>
              </CardHeader>
              <CardContent>
                <ul className="space-y-2">
                  {plan.features.map((feature, i) => (
                    <li key={i} className="flex items-center">
                      <Check className="mr-2 h-5 w-5 text-green-500" />
                      {feature}
                    </li>
                  ))}
                </ul>
              </CardContent>
            </Card>
          </motion.div>
        ))}
      </motion.div>
    </motion.div>
  );
}
