import LoaderCircle from "@/AppComponents/LoaderCircle";
import { AlertDialogHeader } from "@/components/ui/alert-dialog";
import { Badge } from "@/components/ui/badge";
import { Button, buttonVariants } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";

import { ScrollArea } from "@/components/ui/scroll-area";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Separator } from "@/components/ui/separator";
import { toast } from "@/components/ui/use-toast";
import { cn } from "@/lib/utils";
import { fetchCategories } from "@/utils/CategoryUtils";
import { updateProduct } from "@/utils/ProductUtils";
import { StoreContext } from "@/utils/StoreContext";
import { uploadUrl } from "@/utils/StoreUtils";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { Check, Image, Pen, Plus, Trash, Upload, Video, X } from "lucide-react";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill";
import { Link } from "react-router-dom";

const modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "video"], // Image picker is included here
    ["clean"],
  ],
};

export default function EditProduct({ currentProduct }) {
  const [variantOptions, setvariantOptions] = useState([]);
  const { t } = useTranslation();
  const [tempvariant, setVariant] = useState("");
  const [offers, setOffers] = useState([]);
  const [showValidation, setShowValidation] = useState(false);
  const [images, setImages] = useState([]);
  const [mainImageIndex, setMainImageIndex] = useState<number | null>(null);
  const [imagesToAdd, setImagesToAdd] = useState([]);
  const [imagesToDelete, setImagesToDelete] = useState([]);
  const quillRef = useRef(null);
  const [tempvariants, settempVariant] = useState("");
  const [tempPrice,settempPrice]=useState(0)
  const [product, setProduct] = useState({
    name: "",
    description: "",
    visibility: true,
    categoryId: null,
    price: null,
    freeshiping: false,
    ShowDeskDelivery: true,
    useInventory: false,
    Imageurl: null,
    HomePage: false,
  });
  const { data: categories, isLoading } = useQuery({
    queryKey: ["categories"],
    queryFn: () => fetchCategories(1, 50),
  });
  const queryClient = useQueryClient();
  const updateProductMutation = useMutation({
    mutationFn: () =>
      updateProduct(
        currentProduct.id,
        product,
        variantOptions,
        offers,
        images,
        imagesToAdd,
        imagesToDelete,
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["products"] });
      toast({ description: t("alert.confirm_title"), variant: "default" });

      setopen(false);
      setImagesToAdd([]);
      setImagesToDelete([]);
    },
    onError: () => {
      toast({ description: t("alert.err_title"), variant: "destructive" });
    },
  });
  useEffect(() => {
    if (currentProduct) {
      setProduct({
        name: currentProduct.name,
        price: currentProduct.price,
        description: currentProduct.description,
        categoryId: currentProduct.categoryId,
        visibility: currentProduct.visibility,
        freeshiping: currentProduct.freeshiping,
        ShowDeskDelivery: currentProduct.ShowDeskDelivery,
        useInventory: currentProduct.useInventory,
        Imageurl: currentProduct.Imageurl,
        HomePage: currentProduct.HomePage,
      });
      setImages(currentProduct.images);
      if (currentProduct?.attributes !== null) {
        setvariantOptions(currentProduct.attributes);
      }
      if (currentProduct?.offers !== null) {
        setOffers(currentProduct.offers);
      }
    }
  }, [currentProduct, updateProductMutation.isSuccess]);
  const handleImageUpload = async (e) => {
    const file = e.target.files?.[0];
    // Replace with your server upload endpoint
    const response = await uploadUrl(file);

    // Assuming the response contains the URL of the uploaded image
    try {
      const quill = quillRef.current.getEditor();

      const range = quill.getSelection();
      quill.insertEmbed(range?.index, "image", response.url);
    } catch (error) {
      console.error("Error inserting image:", error);
    }
  };
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "4" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "video"],

      ["clean"],
    ],
  };
  const handleUpdateProduct = () => {
    setShowValidation(true);
    if (
      product.name === "" ||
      product.price == null ||
      product.categoryId == null
    ) {
      toast({
        title: t("alert.err_title"),
        description: t("alert.err_d"),
        variant: "destructive",
      });
    } else {
      updateProductMutation.mutate();
    }
  };
  const handleAddImages = async (e) => {
    const file = e.target.files?.[0];
    const response = await uploadUrl(file);
    if (product?.Imageurl?.startsWith("https://placehold.co")) {
      setProduct({ ...product, Imageurl: response.url });
    } else {
      setImagesToAdd((val) => [...val, response.url]);
    }
  };
  const [open, setopen] = useState(false);

  const { storeData: store } = useContext(StoreContext);

  return (
    <Dialog onOpenChange={setopen} open={open}>
      <DialogTrigger asChild>
        <Button size="icon" variant="ghost">
          <Pen width={20} />
        </Button>
      </DialogTrigger>
      <DialogContent className=" h-[80%] overflow-y-auto min-w-[70%] ">
        <AlertDialogHeader>
          <DialogTitle>
            {t("Products.inside.edit.title")}
          </DialogTitle>
          <DialogDescription>
          {t("Products.inside.edit.d")}
          </DialogDescription>
        </AlertDialogHeader>
        <div className="flex flex-col">
          <div className="flex gap-2 p-2">
            <div className="flex flex-col flex-1 gap-1">
              <Card className="p-4">
                <Input
                  value={product.name}
                  onChange={(e) =>
                    setProduct({ ...product, name: e.target.value })
                  }
                  className=""
                  placeholder={t("Products.inside.create.name")}
                />
              </Card>
              <Card>
                <div className=" p-4 space-y-2 ">
                  <h1 className="font-bold  text-xl p-2">
                    {t("Products.inside.create.im")}
                  </h1>
                  <Input
                    type="file"
                    onChange={handleAddImages}
                    accept="image/*"
                    multiple
                    className="hidden"
                    id="image-upload"
                  />
                  <div className="max-w-4xl mx-auto p-4">
                    <div className="mb-4">
                      <Label htmlFor="image-upload" className="cursor-pointer">
                        <div className="border-2 border-dashed border-gray-300 rounded-lg p-6 text-center hover:border-gray-400 transition-colors">
                          <Upload className="mx-auto h-12 w-12 text-gray-400" />
                          <p className="mt-2 text-sm text-gray-600">
                            {t("Products.inside.create.uib")}
                          </p>
                        </div>
                      </Label>
                    </div>

                    {product.Imageurl && (
                      <div className="grid md:grid-cols-3 lg:grid-cols-4 sm:grid-cols-2 grid-cols-2 gap-4 mb-4">
                        <>
                          <div className="relative">
                            <img
                              src={product.Imageurl}
                              alt={`Uploaded image ${product.name}`}
                              width={200}
                              height={200}
                              className={
                                "w-full h-40 object-cover rounded-lg ring-2 ring-primary ring-offset-2"
                              }
                            />

                            <span className="absolute  top-2 left-2 bg-primary text-primary-foreground text-xs px-2 py-1 rounded-full">
                              Main
                            </span>
                          </div>
                          {images?.map((image, index) => (
                            <div
                              key={index}
                              className={`relative group ${imagesToDelete.includes(image?.id) ? "opacity-20" : ""}`}
                            >
                              <img
                                src={image.imageUrl}
                                alt={`Uploaded image ${index + 1}`}
                                width={200}
                                height={200}
                                className={cn(
                                  "w-full h-40 object-cover rounded-lg",
                                  mainImageIndex === index &&
                                  "ring-2 ring-primary ring-offset-2",
                                )}
                              />
                              <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity rounded-lg flex items-center justify-center">
                                {!imagesToDelete.includes(image.id) && (
                                  <Button
                                    variant="secondary"
                                    size="sm"
                                    onClick={() => {
                                      const temp = product.Imageurl;
                                      setProduct({
                                        ...product,
                                        Imageurl: image.imageUrl,
                                      });

                                      image.imageUrl = temp;
                                      setImages((val) => [...val]);
                                    }}
                                    className="mr-2"
                                  >
                                    Set as Main
                                  </Button>
                                )}
                              </div>
                              {!imagesToDelete.includes(image?.id) ? (
                                <Button
                                  onClick={() =>
                                    setImagesToDelete((val) => [
                                      ...val,
                                      image.id,
                                    ])
                                  }
                                  variant="ghost"
                                  size="icon"
                                  className="bg-red-700 w-8 h-8  absolute top-2 right-2"
                                >
                                  <X />
                                </Button>
                              ) : (
                                <Button
                                  onClick={() =>
                                    setImagesToDelete(
                                      imagesToDelete.filter(
                                        (id) => id !== image.id,
                                      ),
                                    )
                                  }
                                  variant="ghost"
                                  size="icon"
                                  className="bg-green-700 w-8 h-8  absolute top-2 right-2"
                                >
                                  <Check />
                                </Button>
                              )}
                            </div>
                          ))}
                        </>
                      </div>
                    )}
                    <Separator />
                    <div className="grid pt-5 md:grid-cols-3 lg:grid-cols-4 sm:grid-cols-2 grid-cols-2 gap-4 mb-4">
                      {imagesToAdd.map((image, index) => (
                        <img
                          src={image}
                          alt={`Uploaded image ${index}`}
                          width={200}
                          height={200}
                          key={index}
                          className={
                            "w-full h-40 object-cover rounded-lg ring-2 ring-primary ring-offset-2"
                          }
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </Card>
              <Card className="space-y-2 p-4">
                <h1 className="p-2 font-bold text-xl">
                  {t("Products.inside.create.pri")}{" "}
                </h1>
                <span className="text-sm text-muted-foreground p-2">
                  {t("Products.inside.create.ops")}
                </span>

                <div className="flex flex-col lg:flex-row gap-2 lg:items-center lg:justify-between">
                  <div className="flex gap-4 items-center ">
                    <Label>{t("Products.inside.create.pr")} </Label>

                    <Input
                      value={product.price}
                      onChange={(e) =>
                        setProduct({
                          ...product,
                          price: e.target.valueAsNumber,
                        })
                      }
                      className=" "
                      type="number"
                      placeholder="Price"
                    />
                    {store?.Currency}
                  </div>
                  {/* <div className="flex  items-center "> */}
                  {/*   <Label>{t("Products.inside.create.opr")} </Label> */}
                  {/*   <Input */}
                  {/*     value={product.oldprice} */}
                  {/*     onChange={(e) => */}
                  {/*       setProduct({ */}
                  {/*         ...product, */}
                  {/*         oldprice: e.target.valueAsNumber, */}
                  {/*       }) */}
                  {/*     } */}
                  {/*     className=" " */}
                  {/*     type="number" */}
                  {/*     placeholder="Price" */}
                  {/*   /> */}
                  {/* </div> */}
                </div>

                <div className="flex items-center gap-2 pt-2">
                  <Checkbox
                    checked={product?.ShowDeskDelivery}
                    onCheckedChange={(checked) =>
                      setProduct({ ...product, ShowDeskDelivery: checked })
                    }
                    className="w-6 h-6"
                  />
                  <p className="font-bold text-gray-500">
                    {t("Products.inside.create.show_desk_delivery")}
                  </p>
                </div>
                <div className="flex items-center gap-2 pt-2">
                  <Checkbox
                    checked={product?.useInventory}
                    onCheckedChange={(checked) =>
                      setProduct({ ...product, useInventory: checked })
                    }
                    className="w-6 h-6"
                  />
                  <p className="font-bold text-gray-500">
                    {t("Products.inside.create.use_inventory")}
                  </p>
                </div>
              </Card>
              <Card className="p-4  grid grid-cols-1 sm:grid-cols-1 gap-4">
                <Button
                  onClick={() =>
                    setProduct({
                      ...product,
                      freeshiping: !product.freeshiping,
                    })
                  }
                  variant="secondary"
                  className={`${product.freeshiping ? "ring ring-blue-200" : ""
                    }  `}
                >
                  <p className="text-muted-foreground ">
                    {t("Products.inside.create.fs")}
                  </p>
                </Button>
                <Button
                  onClick={() =>
                    setProduct({ ...product, HomePage: !product.HomePage })
                  }
                  variant="secondary"
                  className={`${product.HomePage ? "ring ring-blue-200" : ""} `}
                >
                  <p className="text-muted-foreground">
                    {t("Products.inside.create.sahs")}
                  </p>
                </Button>

                <Button
                  onClick={() =>
                    setProduct({
                      ...product,
                      require_client_email: !product.require_client_email,
                    })
                  }
                  variant="secondary"
                  className={`${product.require_client_email ? "ring ring-blue-200" : ""
                    } `}
                >
                  <p className="text-muted-foreground">
                    {t("Products.inside.create.rqe")}
                  </p>
                </Button>
                <Button
                  onClick={() =>
                    setProduct({ ...product, visibility: !product.visibility })
                  }
                  variant="secondary"
                  className={`${product.visibility ? "ring ring-blue-200" : ""
                    }  `}
                >
                  <p className="font-bold text-gray-500">
                    {t("Products.inside.create.os")}
                  </p>
                </Button>
              </Card>
              <div className=" flex flex-col gap-2   ">
                <Card className="p-4 space-y-2">
                  <h1 className="text-xl">
                    {t("Products.inside.create.ct")}{" "}
                    <span className="text-red-500">*</span>
                  </h1>

                  <Select
                    onValueChange={(value) =>
                      setProduct({ ...product, categoryId: value })
                    }
                  >
                    <SelectTrigger
                      className={
                        showValidation && !product.categoryId
                          ? "border-red-500"
                          : ""
                      }
                    >
                      <SelectValue placeholder={currentProduct?.category?.name} />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        <SelectLabel>
                          {t("Products.inside.create.cts")}{" "}
                        </SelectLabel>

                        {categories &&
                          categories?.data?.categories?.map(
                            (category, index) => (
                              <SelectItem key={category.id} value={category.id}>
                                {category.name}
                              </SelectItem>
                            ),
                          )}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                  {showValidation && !product.categoryId && (
                    <p className="text-red-500 text-sm">Category is required</p>
                  )}
                  <Link
                    to="/dashboard/categories/new"
                    className={buttonVariants({ variant: "link" })}
                  >
                    {t("Products.inside.create.ac")}
                  </Link>
                </Card>
              </div>
              <Card className="p-4">
                <h1 className="text-xl">Description</h1>
                <Input
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleImageUpload(e)}
                  className="p-2 my-2  text-accent cursor-pointer hover:ring"
                />
                <ReactQuill
                  ref={quillRef}
                  theme="snow"
                  value={product.description}
                  defaultValue={"type something ..."}
                  onChange={(value) =>
                    setProduct({ ...product, description: value })
                  }
                  modules={modules}
                />
              </Card>
              <Card className="space-y-2 p-4">
                <h1 className="p-2 font-bold text-xl">
                  {t("Products.inside.create.vr")}{" "}
                </h1>
                <span className="text-xs p-2 text-muted-foreground">
                  {t("Products.inside.create.option_d")}
                </span>
                <div className="space-y-2">
                  <Button
                    onClick={() =>
                      setvariantOptions((val) => [
                        ...val,
                        { option: "", value: [] },
                      ])
                    }
                    size="lg"
                    className="w-full"
                  >
                    {t("Products.inside.create.ao")}
                  </Button>
                  {variantOptions?.map((variant, index) => (
                  <div key={index} className="flex flex-col border rounded-lg">
                    <div className="flex p-2">
                      <Checkbox
                        value={variant.usePrice}
                        onCheckedChange={(checked) => {
                          variant.usePrice = checked;
                          setvariantOptions((val) => [...val]);
                        }}
                        className=""
                      />
                      <p className="px-4 text-muted-foreground">
                      {t("Products.inside.create.use_prices")}
                      </p>
                    </div>
                    <div className="flex w-full  flex-row   justify-start ">
                      <div className="flex flex-1 flex-col items-start p-2 gap-1">
                        <p className="text-sm p-2">
                          {t("Products.inside.create.opt")}
                        </p>

                        <Input
                          value={variant.option}
                          onChange={(e) => {
                            variant.option = e.target.value;
                            setvariantOptions((val) => [...val]);
                          }}
                          className=""
                          placeholder={t("Products.inside.create.opt_items")}
                        />
                      </div>
                      <div className="flex flex-1 flex-col items-start p-2 gap-1">
                        <p className="text-sm p-2">
                          {t("Products.inside.create.type")}
                        </p>

                        <Select
                          onValueChange={(value) => {
                            variant.type = value;
                            setvariantOptions((val) => [...val]);
                          }}>
                          <SelectTrigger className="">
                            <SelectValue
                              placeholder={t("Products.inside.create.type")}
                            />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectGroup>
                              <SelectLabel>
                                {t("Products.inside.create.type")}
                              </SelectLabel>
                              <SelectItem value="color">
                                {t("Products.inside.create.type_one")}
                              </SelectItem>
                              <SelectItem value="other">
                                {t("Products.inside.create.type_two")}
                              </SelectItem>
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                      </div>
                      <div className="flex flex-1 flex-col  p-2 gap-1">
                        <p className="text-sm p-2">
                          {t("Products.inside.create.value")}
                        </p>
                        {variant.type == "color" ? (
                          <div className="flex flex-row ">
                            <input
                              className="mx-2  p-0"
                              onChange={(e) => setVariant(e.target.value)}
                              type="color"
                            />
                            <Input
                              onChange={(e) => settempVariant(e.target.value)}
                              placeholder={t("Products.inside.create.cname")}
                            />
                            
                          </div>
                        ) : (
                          <Input
                            value={tempvariant}
                            onChange={(e) => setVariant(e.target.value)}
                            className=""
                            placeholder={t(
                              "Products.inside.create.value_items"
                            )}
                          />
                        )}
                        {variant.usePrice ? (
                                <Input
                                value={tempPrice}
                                type="number"
                            onChange={(e) => settempPrice(e.target.valueAsNumber)}
                                placeholder="price" />
                              ) : (
                                ""
                              )}
                      </div>
                      <Button
                        onClick={() => {
                          if (tempvariant == "" || variant.option == "") {
                            toast({
                              description:
                              t("Products.inside.create.add_v_error"),
                              variant: "destructive",
                            });
                          } else {
                            if (variant.type == "color" ) {
                              if(variant.usePrice){
                                variant.value.push({
                                  value: tempvariant,
                                  name: tempvariants,
                                  price:tempPrice
                                });
                              }else{
                                variant.value.push({
                                  value: tempvariant,
                                  name: tempvariants,
                                });
                              }
                              
                            } else{
                              if(variant.usePrice){
                              variant.value.push({
                                name:tempvariant,
                                price:tempPrice
                              });

                              }else{
                                variant.value.push({
                                  name:tempvariant
                                })
                              }
                      
                            }
                            setvariantOptions((val) => [...val]);
                            setVariant("");
                          }
                        }}
                        className="self-end mb-2"
                        size="icon"
                        variant={"ghost"}>
                        <Plus />
                      </Button>
                      <Button
                        className="self-end mb-2"
                        onClick={() =>
                          setvariantOptions(
                            variantOptions.filter((item) => item !== variant)
                          )
                        }
                        size={"icon"}
                        variant={"ghost"}>
                        <Trash />
                      </Button>
                    </div>
                    <div className="flex gap-2 p-2 relative">
                      {variant.value.map((val, i) => (
                        <div
                          key={i}
                          className="flex bg-muted  items-center gap-4  p-2 rounded-xl">
                          {variant.type == "color" ? (
                            <div className="">
                              <div className="flex items-center ">
                                <div
                                  className="w-8 h-8 ring rounded-full "
                                  style={{ backgroundColor: val.value }}></div>
                                <p className="px-2">{val.name}</p>
                              </div>
                              {variant.usePrice ? (
                               <Badge className="absolute bottom-0 place-self-start">{val.price}</Badge>
                              ) : (
                                ""
                              )}
                            </div>
                          ) : (
                           <div className="">
                            <h1>{val.name}</h1>
                            {variant.usePrice ? (
                               <Badge className="absolute bottom-0 place-self-start">{val.price}</Badge>
                              ) : (
                                ""
                              )}
                           </div>
                          )}
                          <Button
                            onClick={() => {
                              const item = variant.value.find(
                                (toremove) => toremove === val
                              );
                              const index = variant.value.indexOf(item);
                              variant.value.splice(index, 1);

                              setvariantOptions((val) => [...val]);
                            }}
                            variant="ghost"
                            size="icon"
                            className="w-5">
                            <Trash />
                          </Button>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
                </div>
              </Card>
              <Card className="p-4 space-y-2">
                <h1 className="p-2 font-bold text-xl">
                  {t("Products.inside.create.of")}{" "}
                </h1>

                <div>
                  <Button
                    onClick={() =>
                      setOffers((val) => [
                        ...val,
                        { title: "", quantity: 0, price: 0 },
                      ])
                    }
                    size="lg"
                    className="w-full"
                  >
                    {t("Products.inside.create.aof")}
                  </Button>
                </div>
                <div className="">
                  {offers.map((offer, index) => (
                    <div key={index} className="flex gap-2 p-2 items-end">
                      <div className="flex flex-col items-start ">
                        <p className="text-sm p-2">
                          {t("Products.inside.create.o_d")}
                        </p>
                        <Input
                          value={offer.title}
                          onChange={(e) => {
                            offer.title = e.target.value;
                            setOffers((val) => [...val]);
                          }}
                          placeholder={t("Products.inside.create.o_d_d")}
                        />
                      </div>
                      <div className="flex flex-col items-start ">
                        <p className="text-sm p-2">
                          {t("Products.inside.create.o_q")}
                        </p>
                        <Input
                          type="number"
                          value={offer.quantity}
                          onChange={(e) => {
                            offer.quantity = e.target.valueAsNumber;
                            setOffers((val) => [...val]);
                          }}
                          placeholder="quantity"
                        />
                      </div>
                      <div className="flex flex-col items-start ">
                        <p className="text-sm p-2">
                          {t("Products.inside.create.otp")}{" "}
                        </p>
                        <Input
                          value={offer.price}
                          type="number"
                          onChange={(e) => {
                            offer.price = e.target.valueAsNumber;
                            setOffers((val) => [...val]);
                          }}
                          placeholder="price"
                        />
                      </div>
                      <Button
                        onClick={() => {
                          setOffers(
                            offers.filter((item) => item.title !== offer.title),
                          );
                        }}
                        variant="ghost"
                        size="icon"
                      >
                        <Trash className="w-5" />
                      </Button>
                    </div>
                  ))}
                </div>
              </Card>
            </div>
          </div>
        </div>
        <DialogFooter>
          <Button onClick={() => handleUpdateProduct()}>
            {updateProductMutation.isPending ? (
              <LoaderCircle />
            ) : (
              t("btns.save")
            )}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
