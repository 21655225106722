import React, { useState } from "react";
import { motion } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useMutation } from "@tanstack/react-query";
import Axios from "@/utils/Axios";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useToast } from "@/components/ui/use-toast";
import { Loader2, Eye, EyeOff, UserPlus, ArrowBigLeft, ArrowLeft, ArrowRight } from "lucide-react";
import { cn } from "@/lib/utils";
import { Checkbox } from "@/components/ui/checkbox";
import { useTranslation } from "react-i18next";
const reservedWords = ["admin", "support", "example","api","ns1","ns2","api","contact"]; // Add more as needed

const formSchema = z.object({
  email: z.string().email({ message: "Please enter a valid email" }),
  password: z
    .string()
    .min(6, { message: "Password must be at least 6 characters" }),
  fullname: z
    .string()
    .min(2, { message: "Name must be at least 2 characters" }),
  domain: z
  .string()
  .min(4)
  .max(253)
  .regex(/^[a-z0-9-]+(\.[a-z0-9-]+)*$/, {
    message: "Domain must only contain lowercase letters, numbers, hyphens, and dots.",
  })
  .refine((value) => !value.includes(".."), {
    message: "Domain name cannot have consecutive dots.",
  })
  .refine((value) => {
    // Ensure no dots between two words (e.g., 'word1.word2' is invalid)
    const labels = value.split(".");
    return labels.length === 1 || !labels.some((label, idx) => idx > 0 && labels[idx - 1].length > 0 && label.length > 0);
  }, {
    message: "Dots must only separate valid domain parts, not be between words.",
  })
  .refine((value) => {
    return value
      .split(".")
      .every(
        (label) =>
          label.length >= 2 && // At least 2 characters per label
          label.length <= 63 && // No more than 63 characters per label
          !/^-|-$/.test(label) // No hyphen at the start or end of any label
      );
  }, {
    message: "Each domain label must be 2-63 characters, and must not start or end with a hyphen.",
  })
  .refine((value) => {
    // Ensure the domain is not a reserved word
    const firstLabel = value.split(".")[0];
    return !reservedWords.includes(firstLabel);
  }, {
    message: "This domain name is reserved and cannot be used.",
  })
  .transform((value) => value.toLowerCase()),
  acceptTerms: z.boolean().refine((value) => value === true, {
    message: "You must accept the terms and conditions",
  }),
});

export default function Register() {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [showPassword, setShowPassword] = useState(false);
  const { t,i18n:{language} } = useTranslation();
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      password: "",
      fullname: "",
      domain: "",
      acceptTerms: false,
    },
  });

  const registerMutation = useMutation({
    mutationFn: (data) => Axios.post("/auth/register", { data }),
    onSuccess: (res) => {
      toast({ title: res.data.message });
      form.reset();
      navigate("/login");
    },
    onError: (error) => {
      console.error("Registration Failed:", error);
      toast({
        title: "Registration Failed",
        description: error.message,
        variant: "destructive",
      });
    },
  });

  const onSubmit = (data) => {
    registerMutation.mutate(data);
  };

  return (
    <div className="flex min-h-screen  ">

      <div className="flex flex-col   justify-center items-center w-full  p-8 ">
        <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
          className="w-full max-w-md    p-8 rounded-lg  backdrop-blur-sm">
           <Button onClick={()=>navigate("/")} className="absolute rounded-full top-10" size="icon" variant="ghost">
           {language==="ar"?<ArrowRight/>:<ArrowLeft/>}
          </Button>
          <div className="flex justify-center pb-10">
            <img src="/images/banner.svg" alt="Logo" className="w-1/2" />
          </div>
          <h2 className="text-3xl  font-bold text-center mb-8 ">
            {t("Register.create")}
          </h2>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
              <FormField
                control={form.control}
                name="fullname"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-current">
                      {t("Register.fullNameLabel")}
                    </FormLabel>
                    <FormControl>
                      <Input
                       className="text-current"
                        placeholder={t("Register.fullNamePlaceholder")}
                        {...field}
                        
                      />
                    </FormControl>
                    <FormMessage className="text-red-300" />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-current">
                      {t("Register.emailLabel")}
                    </FormLabel>
                    <FormControl>
                      <Input
                      className="text-current"
                        placeholder={t("Register.emailPlaceholder")}
                        {...field}
                      
                      />
                    </FormControl>
                    <FormMessage className="text-red-300" />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="password"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-current">
                      {t("Register.passwordLabel")}
                    </FormLabel>
                    <FormControl>
                      <div className="relative ">
                        <Input
                         className="text-current"
                          type={showPassword ? "text" : "password"}
                          placeholder={t("Register.passwordPlaceholder")}
                          {...field}
                          
                        />
                        <button
                          type="button"
                          onClick={() => setShowPassword(!showPassword)}
                          className={`absolute inset-y-0 ${language==="ar"?"left-2 pr-3 ":"right-2 "} flex items-center text-purple-900`}>
                          {showPassword ? (
                            <EyeOff className="h-5 w-5" />
                          ) : (
                            <Eye className="h-5 w-5" />
                          )}
                        </button>
                      </div>
                    </FormControl>
                    <FormMessage className="text-red-300" />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="domain"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-current">
                      {t("Register.domainLabel")}
                    </FormLabel>
                    <FormControl dir="ltr">
                      <div className="flex px-2  items-center bg-accent rounded-md ">
                        <Input
                         className="text-current font-mono border-none"
                          {...field}
                          placeholder={t("Register.domainPlaceholder")}
                        
                        />
                        
                      </div>
                    </FormControl>
                    <FormMessage className="text-red-300" />
                    <FormDescription className="flex  bg-accent items-center justify-center">
                    {!form.getValues('domain')&&<div className=" text-lg text-green-500 font-mono text-current  inline-block ">
                      {t("Register.domain_d")}
                    </div>}
                    <div className=" text-lg text-green-500 font-mono text-current  inline-block ">{form.getValues("domain")&&form.getValues('domain')+".keeprify.com"}</div>

                    </FormDescription>
                  </FormItem>
                )}
              />
             
              <FormField
                control={form.control}
                name="acceptTerms"
                render={({ field }) => (
                  <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                    <FormControl>
                      <Checkbox
                        checked={field.value}
                        onCheckedChange={field.onChange}
                        className="border"
                      />
                    </FormControl>
                    <div className="space-y-1 leading-none">
                      <FormLabel className="text-sm text-current ">
                        {t("Register.acceptTerms")}
                      </FormLabel>
                      <FormMessage className="text-red-300" />
                    </div>
                  </FormItem>
                )}
              />

              <Button
                type="submit"
                className={cn(
                  "w-full bg-purple-900 hover:bg-purple-800 ",
                  registerMutation.isPending && "opacity-50 cursor-not-allowed"
                )}
                disabled={registerMutation.isPending}>
                {registerMutation.isPending ? (
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                ) : (
                  t("Register.signUp")
                )}
              </Button>
            </form>
          </Form>

          <div className="mt-6 text-center">
            <p className="text-sm text-current">
              {t("Register.alreadyHaveAccount")}{" "}
              <Link
                to="/login"
                className="text-purple-900 hover:underline font-semibold">
                {t("Register.signIn")}
              </Link>
            </p>
          </div>
        </motion.div>
      </div>
    </div>
  );
}
