import { Avatar, AvatarImage } from '@/components/ui/avatar'
import { Badge } from '@/components/ui/badge'
import { Button, buttonVariants } from '@/components/ui/button'
import { Card } from '@/components/ui/card'
import { Input } from '@/components/ui/input'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import { Plus, Settings, SlidersVertical, Trash } from 'lucide-react'
import React from 'react'
import NewLanding from './NewLanding'
import { Link } from 'react-router-dom'

export default function LandingMain() {
  if(true) return <h1>soon</h1>
  return (
    <div className='container p-8 '>
        <Card className='p-4'>
          <div className='text-xl'>
            Landing pages
          </div>
          <p className='text-xs py-2 text-muted-foreground'>Here's a list of your tasks for this month!</p>
          <div className='flex justify-between gap-2'>
            <div className='flex flex-wrap md:flex-nowrap gap-2'>
              <Input className='' placeholder='filter by product name'/>
              <Link to="/dashboard/landingpages/new" className={buttonVariants({variant:"link"})}>
                create a new landing page
              </Link>            
            </div>
            <div>
              <Button variant="outline">
                <SlidersVertical/>
                View
              </Button>
            </div>
          </div>
          <div className='rounded-md border mt-5'>
          <Table>
            <TableHeader>
              <TableRow>
              <TableHead></TableHead>

              <TableHead>Product</TableHead>
              <TableHead>Price</TableHead>
              <TableHead>visits</TableHead>
              <TableHead>details</TableHead>
              <TableHead>remove</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              <TableRow>
              <TableCell>
                <Avatar>
                <AvatarImage src='/bigee.png'/>
                </Avatar>
              </TableCell>
              <TableCell>column two</TableCell>
              <TableCell>column three</TableCell>
              <TableCell>
                <Badge>10</Badge>
              </TableCell>
              <TableCell>column one</TableCell>
              <TableCell>
                <Button size="icon" variant="ghost">
                  <Trash/>
                </Button>
              </TableCell>
              
              </TableRow>
            </TableBody>
          </Table>
          </div>
          <div className="space-x-2 mt-2 flex justify-end">
          <Button
            variant="outline"
            size="sm"
           
          >
            Previous
          </Button>
          <Button
            variant="outline"
            size="sm"
            
          >
            Next
          </Button>
        </div>
        </Card>
    </div>
  )
}
