import React, { useState } from "react";
import { motion } from "framer-motion";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { ScrollArea } from "@/components/ui/scroll-area";
import { ChevronUp, Lock, Shield, Eye, Book } from "lucide-react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function PrivacyPolicy() {
  const [showBackToTop, setShowBackToTop] = useState(false);
  const {t}=useTranslation()
  const sections = [
   
    {
      title: t("terms.sections.introduction.title"),
      content: t("terms.sections.introduction.content")
    },
    {
      title: t("terms.sections.accountTerms.title"),
      content: t("terms.sections.accountTerms.content")
    },
    {
      title: t("terms.sections.keeprifyRights.title"),
      content: t("terms.sections.keeprifyRights.content")
    },
    {
      title: t("terms.sections.yourResponsibilities.title"),
      content: t("terms.sections.yourResponsibilities.content")
    },
    {
      title: t("terms.sections.limitationOfLiability.title"),
      content: t("terms.sections.limitationOfLiability.content")
    },
    {
      title: t("terms.sections.intellectualProperty.title"),
      content: t("terms.sections.intellectualProperty.content")
    }
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement;
    setShowBackToTop(target.scrollTop > 300);
  };

  const scrollToTop = () => {
    const scrollArea = document.querySelector(".scroll-area");
    if (scrollArea) {
      scrollArea.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  return (
    <motion.div
  className="container mx-auto px-4 py-8 max-w-4xl"
  variants={containerVariants}
  initial="hidden"
  animate="visible"
>
  <motion.h1
    className="text-4xl font-bold text-center mb-8"
    variants={itemVariants}
  >
    {t("privacyPolicy.header")}
  </motion.h1>

  <motion.div variants={itemVariants} className="mb-8 text-center">
    <p className="text-lg text-muted-foreground">
      {t("privacyPolicy.lastUpdated", { date: new Date().toLocaleDateString() })}
    </p>
  </motion.div>

  <Card className="mb-8">
    <CardHeader>
      <CardTitle className="flex items-center">
        <Lock className="mr-2" />
        {t("privacyPolicy.sections.title")}
      </CardTitle>
    </CardHeader>
    <CardContent>
      <p className="text-muted-foreground">
        {t("privacyPolicy.sections.description")}
      </p>
    </CardContent>
  </Card>

  <Tabs defaultValue="full-policy" className="mb-8">
    <TabsList className="grid w-full grid-cols-2">
      <TabsTrigger value="full-policy">
        {t("privacyPolicy.tabs.fullPolicy")}
      </TabsTrigger>
      <TabsTrigger value="key-points">
        {t("privacyPolicy.tabs.keyPoints")}
      </TabsTrigger>
    </TabsList>
    <TabsContent value="full-policy">
      <Card>
        <CardContent>
          <ScrollArea
            className="h-[60vh] scroll-area"
            onScroll={handleScroll}
          >
            {sections.map((section, index) => (
              <div key={index} className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">
                  {section.title}
                </h2>
                <p className="text-muted-foreground whitespace-pre-line">
                  {section.content}
                </p>
              </div>
            ))}
          </ScrollArea>
        </CardContent>
      </Card>
    </TabsContent>
    <TabsContent value="key-points">
      <Card>
        <CardContent>
          <ul className="list-disc list-inside space-y-2">
            <li>{t("privacyPolicy.keyPoints.point1")}</li>
            <li>{t("privacyPolicy.keyPoints.point2")}</li>
            <li>{t("privacyPolicy.keyPoints.point3")}</li>
            <li>{t("privacyPolicy.keyPoints.point4")}</li>
            <li>{t("privacyPolicy.keyPoints.point5")}</li>
            <li>{t("privacyPolicy.keyPoints.point6")}</li>
          </ul>
        </CardContent>
      </Card>
    </TabsContent>
  </Tabs>

  <motion.div
    variants={itemVariants}
    className="flex justify-center space-x-4 mb-8"
  >
    <Button variant="outline" className="flex items-center">
      <Eye className="mr-2 h-4 w-4" />
      {t("privacyPolicy.buttons.cookieSettings")}
    </Button>
    <Button variant="outline" className="flex items-center">
      <Shield className="mr-2 h-4 w-4" />
      {t("privacyPolicy.buttons.dataRequest")}
    </Button>
    <Button variant="outline" className="flex items-center">
      <Book className="mr-2 h-4 w-4" />
      <Link to={"/tos"}>{t("privacyPolicy.buttons.termsOfService")}</Link>
    </Button>
  </motion.div>

  {showBackToTop && (
    <motion.div
      className="fixed bottom-8 right-8"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 20 }}
    >
      <Button onClick={scrollToTop} size="icon" variant="secondary">
        <ChevronUp className="h-4 w-4" />
      </Button>
    </motion.div>
  )}
</motion.div>

  );
}
