export const prepareCoupons = (data) => {
  return data?.map((coupon) => ({
    title: coupon.Title,
    amount: coupon.value,
    type: coupon.Type,
    active: coupon.IsActive,
    validUntil: coupon.validUntil,
  }));
};

export const prepareStock = (data) => {
  return data?.map((product) => ({
    name: product.name,
    price: product.price,
    stock_level: product.stock_level,
    Threshold: product.Threshold,
    freeshipping: product.freeshiping, // Note: corrected spelling from 'freeshiping' to 'freeshipping'
  }));
};

export const prepareCustomers = (data) => {
  return data?.map((customer) => ({
    fullname: customer.fullname,
    phone_number: customer.phone_number,
    emeail: customer.email || "",
    orders: customer.demands ? customer.demands.length : 0,
  }));
};

export const prepareProducts = (data) => {
  return data?.map((product) => ({
    name: product.name,
    price: product.price,
    freeshipping: product.freeshiping, // Note: original data has a typo 'freeshiping'
    visibility: product.visibility,
    require_client_email: product.require_client_email,
    stock_level: product.stock_level,
    Threshold: product.Threshold,
  }));
};

export const prepareOrders = (data) => {
  return data?.map((order) => ({
    client_name: order.client_name,
    client_phone_number: order.client_phone_number,
    strict_address: order.strict_address,
    product_name: order.product ? order.product.name : null,
    wilaya: order.wilaya ? order.wilaya.name : null,
    commune: order.commune ? order.commune.name : null,
    status: order.status,
    sale_date: order.sale_date,
    total_price: order.total_price,
    couponApplied: order.couponApplied,
    requestIP: order.requestIP,
  }));
};
