import { CloudDownloadIcon, Server } from "lucide-react";

import "./App.css";
import { Link } from "react-router-dom";
import LanguageToggle from "./AppComponents/LanguageToggle";
import Themetoggle from "./AppComponents/Themetoggle";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { LockClosedIcon } from "@radix-ui/react-icons";

function App() {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const templates=[
    {name:"Default",image:"/images/template1.png",preview:""},
    {name:"hanout",image:"/images/template2.png",preview:""},
    {name:"digital",image:"/images/template3.png",preview:""}

  ]
  return (
    <main
      className={` ${
        language == "ar" ? "font-ar" : ""
      } w-full  select-none bg-background`}>
      <section id="hero-section" className="w-full h-fit  ">
        <div className="w-[calc(100%-32px)]  pt-[120px] px-4 md:w-[calc(100%-48px)] bg-ghost-white rounded-3xl max-w-[1600px] md:pt-[200px] md:px-6 flex flex-col items-center my-6 mx-auto">
          <div className="flex flex-col items-center w-full">
            <div className="w-full max-w-[862px] flex flex-col items-center mb-[80px]">
              <h1
                className={`text-[32px] ${
                  language == "ar" ? "font-ar" : ""
                }  md:text-[64px] mb-6 text-center font-semibold`}>
                <span className="text-primary ">
                  {t("mainsection1.hero_title_part1")} <span></span>
                </span>
                {t("mainsection1.hero_title_part2")}
              </h1>
              <p
                id="hero-caption"
                className="text-center  text-gray-600 max-w-[640px] text-base leading-[150%] md:text-xl text-grey-900 font-normal md:leading-[30px] font-proxima">
                {t("mainsection1.hero_caption")}
              </p>
              <Link
                to="/register"
                className="ring   mt-10 hover:opacity-85 bg-primary text-white font-bold px-16 py-4 rounded-2xl">
                {t("mainsection1.start_now_button")}
              </Link>
            </div>
            <div className="relative w-full max-w-[1260px] flex items-center justify-center">
              <div className="max-w-[100%] mx-[7.7568%]">
                <img
                  loading="lazy"
                  decoding="async"
                  data-nimg={1}
                  className="rounded-xl mx-auto h-auto w-full shadow-[0_18px_52px_rgba(0,74,136,.1),0_0_0_0.8px_rgba(239,_241,_244,0.5),0_0_0_3px_#0085ff;]"
                  style={{ color: "transparent" }}
                  src="/dash_image.png"
                />
              </div>
              <div className="absolute flex w-auto h-auto top-[32%] right-0 left-[77.6%] max-w-[260px] drop-shadow-[0_6px_40px_rgba(25,30,36,0.2)]">
                <img
                  alt={t("mainsection1.hero_image_alt")}
                  loading="lazy"
                  width={260}
                  height={518}
                  decoding="async"
                  data-nimg={1}
                  style={{ color: "transparent" }}
                  src="/dash_phone.png"
                />
              </div>
              <div className="absolute flex w-auto h-auto top-[32%] left-0 right-[81.035%] max-w-[220px] shadow-[0_18px_40px_rgba(0,74,136,0.2),0_0_0_1px_rgba(230,234,239,1),0_0_0_0px_#0085ff] rounded-md overflow-hidden">
                <img
                  alt={t("mainsection1.hero_cart_image_alt")}
                  loading="lazy"
                  width={220}
                  height={274}
                  decoding="async"
                  data-nimg={1}
                  style={{ color: "transparent" }}
                  src="/dash_phone.png"
                />
              </div>
            </div>
            <div className="pt-20"></div>
          </div>
        </div>
      </section>

      <section className="w-full ">
        <div className="max-w-[1320px] flex flex-col items-stretch mx-auto px-6 py-[84px]">
          <div className="flex flex-col items-center">
            <div className="max-w-[700px] text-center flex flex-col items-center mb-[260px]">
              <p className="mb-[18px]  uppercase text-[13px] font-medium leading-5 tracking-[0.04em] text-neutral-grey-900">
                {t("mainsection2.how_it_works")}
              </p>
              <h2 className="text-primary-dark-300 text-[34px] md:text-[52px] font-bold mb-5 leading-[130%] ">
                {t("mainsection2.start_selling")}
              </h2>
              <p className="max-w-[460px] text-base leading-6 text-neutral-grey-900">
                {t("mainsection2.setup_description")}
              </p>
            </div>
          </div>
          <div className="w-full pb-[100px] px-6 bg-ghost-white rounded-3xl flex justify-center">
            <div className="max-w-[1120px] flex flex-col md:flex-row gap-8 -mt-[200px]">
              <div className="flex flex-1 flex-col items-center text-center p-6 rounded-3xl shadow-[0_2px_16px_rgba(90,108,129,.1)]">
                <img
                  className="w-full mb-6 h-[190px] shadow-[0_0_0_1px_rgba(230,234,239,0.8)] rounded-lg"
                 src="/images/1.svg"
                  alt="first"
                  width={288}
                  height={180}
                />
                <h5 className="mb-2.5 ">{t("mainsection2.step1_title")}</h5>
                <p className="caption caption-medium">
                  {t("mainsection2.step1_description")}
                </p>
              </div>
              <div className="flex flex-1 flex-col items-center text-center p-6 rounded-3xl shadow-[0_2px_16px_rgba(90,108,129,.1)]">
                <img
                  className="w-full mb-6 h-[190px] shadow-[0_0_0_1px_rgba(230,234,239,0.8)] rounded-lg"
                 src="/images/2.svg"
                  alt="second"
                  width={288}
                  height={180}
                />
                <h5 className="mb-2.5 ">
                  {t("mainsection2.step2_title")}
                </h5>
                <p className="caption caption-medium">
                  {t("mainsection2.step2_description")}
                </p>
              </div>
              <div className="flex flex-1 flex-col items-center text-center p-6 rounded-3xl  shadow-[0_2px_16px_rgba(90,108,129,.1)]">
                <img
                  className="w-full mb-6 h-[190px] shadow-[0_0_0_1px_rgba(230,234,239,0.8)] rounded-lg"
                  src="/images/3.svg"
                  alt="third"
                  width={288}
                  height={180}
                />
                <h5 className="mb-2.5 ">
                  {t("mainsection2.step3_title")}
                </h5>
                <p className="caption caption-medium">
                  {t("mainsection2.step3_description")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="w-full">
        <div className="flex flex-col items-stretch w-full max-w-[calc(100%-32px)] md:max-w-[1168px] mx-auto md:px-6 py-[84px]">
          <div className="flex items-center flex-col">
            <div className="flex items-center flex-col mb-10 max-w-[700px]">
              <p className="tagline mb-4.5">
                {t("mainsection3.overview_title")}
              </p>
            </div>
            <div className="flex flex-col justify-center items-center">
              <div className="mb-10"></div>
              <div className="max-w-[100%] flex items-center justify-center mb-6">
                <div className="p-5 rounded-3xl shadow-[0_0_0_1px_rgba(230,234,239,0.5),0_2px_16px_rgba(89,107,129,0.1)] hidden linearImage !block">
                  <div className="mb-6">
                    <img
                      alt=""
                      loading="lazy"
                      width={1080}
                      height={424}
                      decoding="async"
                      data-nimg={1}
                      className="rounded-2xl w-full h-auto"
                      style={{ color: "transparent" }}
                      src="/dash_customize.png"
                    />
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-3">
                    <div className="p-6 flex flex-col items-center justify-center">
                      <div className="flex items-center justify-center gap-2 mb-4">
                        <img
                          alt="fourth"
                          loading="lazy"
                          width={24}
                          height={24}
                          decoding="async"
                          data-nimg={1}
                          src="/images/4.webp"
                        />
                        <h4 className="text-[18px] md:text-xl ">
                          {t("mainsection3.feature1_title")}
                        </h4>
                      </div>
                      <p className="caption caption-medium text-center">
                        {t("mainsection3.feature1_caption")}
                      </p>
                    </div>
                    <div className="p-6 flex flex-col items-center justify-center">
                      <div className="flex items-center justify-center gap-2 mb-4">
                        <img
                          alt=""
                          loading="lazy"
                          width={24}
                          height={24}
                          decoding="async"
                          data-nimg={1}
                          src="/images/4.webp"
                        />
                        <h4 className="text-[18px] md:text-xl ">
                          {t("mainsection3.feature2_title")}
                        </h4>
                      </div>
                      <p className="caption caption-medium text-center">
                        {t("mainsection3.feature2_caption")}
                      </p>
                    </div>
                    <div className="p-6 flex flex-col items-center justify-center">
                      <div className="flex items-center justify-center gap-2 mb-4">
                        <img
                          alt=""
                          loading="lazy"
                          width={24}
                          height={24}
                          decoding="async"
                          data-nimg={1}
                          src="https://www.lightfunnels.com/icons/key-features-1/star.svg"
                        />
                        <h4 className="text-[18px] md:text-xl ">
                          {t("mainsection3.feature3_title")}
                        </h4>
                      </div>
                      <p className="caption caption-medium text-center">
                        {t("mainsection3.feature3_caption")}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="p-5 rounded-3xl shadow-[0_0_0_1px_rgba(230,234,239,0.5),0_2px_16px_rgba(89,107,129,0.1)] hidden">
                  <div className="mb-6">
                    <img
                      alt=""
                      loading="lazy"
                      width={1080}
                      height={424}
                      decoding="async"
                      data-nimg={1}
                      className="rounded-2xl w-full h-auto"
                      style={{ color: "transparent" }}
                      src="https://www.lightfunnels.com/_next/image?url=%2Fimages%2Fsections%2Fhome%2Fwhats-inside%2Ffunnel-builder.png&w=1080&q=100 1x, /_next/image?url=%2Fimages%2Fsections%2Fhome%2Fwhats-inside%2Ffunnel-builder.png&w=3840&q=100 2x"
                    />
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-3">
                    <div className="p-6 flex flex-col items-center justify-center">
                      <div className="flex items-center justify-center gap-2 mb-4">
                        <img
                          alt=""
                          loading="lazy"
                          width={24}
                          height={24}
                          decoding="async"
                          data-nimg={1}
                          src="https://www.lightfunnels.com/icons/key-features-1/cursor.svg"
                        />
                        <h4 className="text-[18px] md:text-xl ">
                          {t("mainsection3.feature4_title")}
                        </h4>
                      </div>
                      <p className="caption caption-medium text-center">
                        {t("mainsection3.feature4_caption")}
                      </p>
                    </div>
                    <div className="p-6 flex flex-col items-center justify-center">
                      <div className="flex items-center justify-center gap-2 mb-4">
                        <img
                          alt=""
                          loading="lazy"
                          width={24}
                          height={24}
                          decoding="async"
                          data-nimg={1}
                          src="https://www.lightfunnels.com/icons/key-features-1/voltage.svg"
                        />
                        <h4 className="text-[18px] md:text-xl ">
                          {t("mainsection3.feature5_title")}
                        </h4>
                      </div>
                      <p className="caption caption-medium text-center">
                        {t("mainsection3.feature5_caption")}
                      </p>
                    </div>
                    <div className="p-6 flex flex-col items-center justify-center">
                      <div className="flex items-center justify-center gap-2 mb-4">
                        <img
                          alt=""
                          loading="lazy"
                          width={24}
                          height={24}
                          decoding="async"
                          data-nimg={1}
                          src="https://www.lightfunnels.com/icons/key-features-1/babdge.svg"
                        />
                        <h4 className="text-[18px] md:text-xl ">
                          {t("mainsection3.feature6_title")}
                        </h4>
                      </div>
                      <p className="caption caption-medium text-center">
                        {t("mainsection3.feature6_caption")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <Link
                to="/register"
                className="ring  mt-10 hover:opacity-85 bg-primary text-white font-bold px-16 py-4 rounded-2xl">
                {t("mainsection3.cta_button_text")}
              </Link>
            </div>
          </div>
        </div>
      </section>

      

      <section className="w-full" id="prod_stores">
        <div className="min-h-full pt-[80px] w-[calc(100%-32px)] rounded-3xl max-w-[1224px] flex flex-col items-stretch md:w-full mx-auto md:px-6 py-[84px]">
          <div className="flex items-center justify-center flex-col md:flex-row w-full">
            <div className="w-full flex flex-col items-center text-center md:mr-[60px] max-w-[464px]">
              <img
                alt={t("mainsection10.title")}
                loading="lazy"
                width={32}
                height={32}
                decoding="async"
                data-nimg={1}
                className="mb-3.5"
                style={{ color: "transparent" }}
               src="/icons/1.svg"
               />
              <h2 className="text-[36px] max-w-[380px] mb-5 ">
                {t("mainsection10.title")}
              </h2>
              <p className="caption max-w-[440px] text-base">
                {t("mainsection10.caption")}
              </p>
              <Link
                to="/register"
                className="ring mt-10 hover:opacity-85 bg-primary text-white font-bold px-16 py-4 rounded-2xl">
                {t("mainsection10.cta_button_text")}
                <i className="icon-arrow-right-inline" />
              </Link>
            </div>
            <div className="section-image">
              <img
                alt={t("mainsection10.title")}
                loading="lazy"
                width={598}
                height={598}
                decoding="async"
                data-nimg={1}
                style={{ color: "transparent" }}
                src={t("mainsection10.image")}
              />
            </div>
          </div>
        </div>
      </section>

      

      <section id="templates" className="overflow-hidden my-[80px] w-full">
        <div className="flex flex-col items-center mx-auto py-[84px] px-6 [background:linear-gradient(180deg,rgba(153,176,193,0),rgba(153,176,193,.06))] w-[calc(100%-32px)] md:w-[calc(100%-48px)] rounded-3xl">
          <div className="max-w-[1272px] flex flex-col md:flex-row md:justify-between md:items-center w-full">
            <div className="flex text-center  mb-10 md:mb-15 flex-col gap-4.5 w-full">
              <p className="tagline">
                {t("templates.title")}
              </p>
              <h2 className="text-[32px] md:text-[52px] ">
              {t("templates.title_d")}
              </h2>
              <p className="caption caption-medium">
              {t("templates.d")}
              </p>
            </div>
            
          </div>
          <div className="w-full max-w-[1272px] self-center relative">
            <div className="swiperAndBtnsContainer">
              <div className="swiperContainer disabledFadingInFeaturedApps">
                <div className="swiper swiperComponent swiper-initialized swiper-horizontal swiper-backface-hidden">
                  <div
                    className="container grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4"
                   >
                    {templates.map((template,id)=>(
                      <div
                      key={id}
                      className="w-full"
                      >
                      <div className="p-4 bg-white shadow-xl rounded-xl">
                        <a  />
                        <div className="relative rounded-lg overflow-hidden flex flex-col items-stretch w-full group">
                          <a>
                            <img
                              className="z-[1] w-full max-h-[264px] object-cover object-top"
                              loading="lazy"
                              src={template.image}
                              width="265px"
                              height={300}
                              alt=""
                            />
                          </a>
                          <div className="z-10 absolute w-full h-full inset-0 bg-primary-dark-100/25 [animation:overlayShow_.1s_cubic-bezier(.47,0,.745,.715)] hidden flex-col items-center justify-center group-hover:flex">
                            <a href={template.preview} />
                            <a
                              className="absolute top-3.5 right-3.5 rounded-md flex items-center p-1.5 text-white bg-primary-dark-100/60 hover:bg-primary-dark-100/80"
                              href="">
                              <i className="icon-external-link text-[22px] " />
                            </a>
                            <button className="button_btn__Lt_MH button_btnLarge__nzodn  !px-3 !h-[32px] !rounded-lg !text-sm  ">
                              {t("templates.view")}
                            </button>
                          </div>
                        </div>
                        <div className="mt-5 px-[2px] flex flex-col items-stretch">
                          <div className="flex justify-between mb-4.5">
                            <div className="flex items-center gap-1.5">
                              <svg
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                  fill="#4B5A6C"
                                  d="M4.5 8C6.433 8 8 6.433 8 4.5C8 2.567 6.433 1 4.5 1C2.567 1 1 2.567 1 4.5C1 6.433 2.567 8 4.5 8Z"
                                />
                                <path
                                  fill="#4B5A6C"
                                  d="M19.5 23C21.433 23 23 21.433 23 19.5C23 17.567 21.433 16 19.5 16C17.567 16 16 17.567 16 19.5C16 21.433 17.567 23 19.5 23Z"
                                />
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M9.55038 4.00001C9.55038 3.27702 10.1365 2.69092 10.8595 2.69092H16.7145C20.3118 2.69092 21.6686 7.38945 18.6431 9.31798L6.76491 16.8898L6.76489 16.8898C5.9385 17.4166 6.31783 18.6909 7.28628 18.6909H13.1413C13.8643 18.6909 14.4504 19.277 14.4504 20C14.4504 20.723 13.8643 21.3091 13.1413 21.3091H7.28628C3.68907 21.3091 2.3321 16.6106 5.35756 14.6821C5.35757 14.6821 5.35757 14.6821 5.35758 14.6821L17.2358 7.11021L17.2358 7.1102C18.0623 6.58334 17.6828 5.3091 16.7145 5.3091H10.8595C10.1365 5.3091 9.55038 4.723 9.55038 4.00001Z"
                                  fill="#ABB7C4"
                                />
                              </svg>
                              <h6 className="w-full max-w-[160px] overflow-hidden text-ellipsis text-dark-blue">
                                {template.name}
                              </h6>
                            </div>
                            <h6 className="text-[#7486A8] text-right">
                              {t("templates.free")}
                            </h6>
                          </div>
                          
                        </div>
                      </div>
                    </div>
                    ))}
                  </div>
                </div>
              </div>
              <i className="icon-Arrow-Left leftIcon SwiperIcon swipe-prev swiper-button-disabled" />
              <i className="icon-Arrow-Right rightIcon SwiperIcon swipe-next" />
            </div>
          </div>
        </div>
      </section>

      <section
        id="final-cta"
        className="w-full ![background-size:auto_220px] [background:url('/images/sections/final-cta-bg.svg')_bottom_no-repeat] md:![background-size:auto]">
        <div className="py-[180px] flex flex-col items-stretch w-full max-w-[calc(100%-32px)] md:max-w-[1168px] mx-auto px-6">
          <div className="flex flex-col items-center w-full">
            <div className="max-w-[700px] text-center flex flex-col items-center w-full mb-7.5">
              <img
                alt=""
                loading="lazy"
                className="w-1/6"
                decoding="async"
                data-nimg={1}
                style={{ color: "transparent" }}
                src="/fullkeeprifyprimarycolor.png"
              />
              <h2 className="text-[34px] md:text-[52px] ">
                {t("started.title")}
              </h2>
            </div>
            <Link
              to="/register"
              className=" ring mt-10 hover:opacity-85  bg-primary text-white font-bold px-16 py-4 rounded-2xl">
                {t("mainsection10.cta_button_text")}
              
              <i className="icon-arrow-right-inline " />
            </Link>
            <div className="mt-8 flex md:flex-row flex-col items-center justify-center flex-wrap w-full max-w-[800px] gap-5">
              <div className="flex items-center gap-3">
                <i className="icon-check-2 text-blue-100 rounded-full p-1.5 [background:linear-gradient(0deg,rgba(0,133,255,.1),rgba(0,133,255,.1)),#fff]" />
                <p className="font-semibold">
                {t("started.days")}
                </p>
              </div>
              <div className="flex items-center gap-3">
                <i className="icon-check-2 text-blue-100 rounded-full p-1.5 [background:linear-gradient(0deg,rgba(0,133,255,.1),rgba(0,133,255,.1)),#fff]" />
                <p className="font-semibold">
                {t("started.credit")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default App;
