import React, { useState, useRef, useEffect } from "react";
import { Send, Bot, User, X, MessageCircle } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { ScrollArea } from "@/components/ui/scroll-area";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import axios from "axios";
import Axios from "@/utils/Axios";

type Message = {
  text: string;
  isUser: boolean;
};

export default function Chatbot() {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState<Message[]>([
    {
      text: "Hello Dear Keeprify User! How can I assist you today?",
      isUser: false,
    },
  ]);
  const [input, setInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const scrollAreaRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isOpen) {
      scrollToBottom();
    }
  }, [messages, isOpen]);

  const scrollToBottom = () => {
    if (scrollAreaRef.current) {
      const scrollContainer = scrollAreaRef.current.querySelector(
        "[data-radix-scroll-area-viewport]",
      );
      if (scrollContainer) {
        scrollContainer.scrollTop = scrollContainer.scrollHeight;
      }
    }
  };

  const sendMessage = async () => {
    if (input.trim() === "" || isLoading) return;

    const newMessage = { text: input, isUser: true };
    setMessages((prev) => [...prev, newMessage]);
    setIsLoading(true);
    const userInput = input;
    setInput("");

    try {
      const { data } = await Axios.post("/api/chat", {
        message: userInput,
        conversationHistory: messages.slice(1),
      });

      setMessages((prev) => [...prev, { text: data.message, isUser: false }]);
    } catch (error) {
      console.error("Error:", error);
      let errorMessage = "Sorry, I encountered an error. Please try again.";

      if (axios.isAxiosError(error)) {
        if (error.response?.status === 429) {
          errorMessage =
            "Too many requests. Please wait a moment and try again.";
        } else if (error.response?.status === 500) {
          errorMessage = "Not available right now. Please try again later.";
        }
      }

      setMessages((prev) => [
        ...prev,
        {
          text: errorMessage,
          isUser: false,
        },
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isOpen ? (
        <Card className="fixed bottom-4 right-4 w-full max-w-[350px] h-[80vh] max-h-[500px] flex flex-col shadow-lg bg-background border-primary sm:w-[350px] overflow-hidden">
          <CardHeader className="p-2 flex-shrink-0">
            <CardTitle className="text-lg font-bold flex items-center justify-between">
              <span className="flex items-center">
                <Bot className="mr-2" /> Keeprify Assistant
              </span>
              <Button
                size="icon"
                variant="ghost"
                className="text-foreground hover:bg-secondary"
                onClick={() => setIsOpen(false)}
              >
                <X className="h-4 w-4" />
                <span className="sr-only">Close</span>
              </Button>
            </CardTitle>
          </CardHeader>
          <CardContent className="p-2 flex-grow overflow-hidden">
            <ScrollArea className="h-full pr-4" ref={scrollAreaRef}>
              <div className="flex flex-col space-y-4">
                {messages.map((message, index) => (
                  <div
                    key={index}
                    className={`flex ${message.isUser ? "justify-end" : "justify-start"}`}
                  >
                    <div
                      className={`flex items-end space-x-2 ${
                        message.isUser ? "flex-row-reverse" : "flex-row"
                      }`}
                    >
                      <div
                        className={`w-8 h-8 rounded-full ml-1 flex items-center justify-center ${
                          message.isUser ? "bg-primary" : "bg-secondary"
                        }`}
                      >
                        {message.isUser ? (
                          <User className="h-4 w-4 text-primary-foreground" />
                        ) : (
                          <Bot className="h-4 w-4 text-secondary-foreground" />
                        )}
                      </div>
                      <div
                        className={`max-w-[70%] p-3 rounded-lg ${
                          message.isUser
                            ? "bg-primary text-primary-foreground"
                            : "bg-secondary text-secondary-foreground"
                        }`}
                      >
                        <p className="text-sm">{message.text}</p>
                      </div>
                    </div>
                  </div>
                ))}
                {isLoading && (
                  <div className="flex justify-start">
                    <div className="flex items-end space-x-2">
                      <div className="w-8 h-8 rounded-full bg-secondary flex items-center justify-center">
                        <Bot className="h-4 w-4 text-secondary-foreground" />
                      </div>
                      <div className="bg-secondary text-secondary-foreground p-1 rounded-lg">
                        <TypingIndicator />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </ScrollArea>
          </CardContent>
          <CardFooter>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                sendMessage();
              }}
              className="flex space-x-2 w-full"
            >
              <Input
                type="text"
                placeholder="Type your message..."
                value={input}
                onChange={(e) => setInput(e.target.value)}
                className="flex-grow border-purple-900 focus-visible:ring-purple-900"
                disabled={isLoading}
                ref={inputRef}
              />
              <Button type="submit" size="icon" disabled={isLoading}>
                <Send className="h-4 w-4" />
                <span className="sr-only">Send</span>
              </Button>
            </form>
          </CardFooter>
        </Card>
      ) : (
        <Button
          className="fixed bottom-4 right-4 rounded-full w-14 h-14 shadow-lg"
          onClick={() => setIsOpen(true)}
        >
          <MessageCircle className="h-6 w-6" />
          <span className="sr-only">Open chat</span>
        </Button>
      )}
    </>
  );
}

function TypingIndicator() {
  return (
    <div className="flex space-x-1">
      <div className="w-2 h-2 rounded-full bg-primary animate-pulse"></div>
      <div
        className="w-2 h-2 rounded-full bg-primary animate-pulse"
        style={{ animationDelay: "150ms" }}
      ></div>
      <div
        className="w-2 h-2 rounded-full bg-primary animate-pulse"
        style={{ animationDelay: "300ms" }}
      ></div>
    </div>
  );
}
