import React, { useContext, useEffect, useState } from "react";

import { Separator } from "./ui/separator.tsx";
import { SidebarTrigger } from "./ui/sidebar.tsx";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "./ui/breadcrumb.tsx";
import { BreadContext } from "@/utils/BreadContext.ts";
import { useHref } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LanguageToggle from "@/AppComponents/LanguageToggle.tsx";
import ThemeToggle from "@/AppComponents/Themetoggle.tsx";
import { Badge } from "./ui/badge.tsx";
import { t } from "i18next";
import { Button } from "./ui/button.tsx";
import Axios from "@/utils/Axios.ts";

export default function Bread() {
  
  const [currentSubscription, setCurrentSubscription] = useState(null);

  
  const {
    i18n: { language },
  } = useTranslation();
  const planDetails = {
    name: "BASIC",
    endDate: "2024-12-31",
  };
  useEffect(() => {
    const fetchSubscription = async () => {
      try {
        const response = await Axios.get("/api/subscription/get");
        setCurrentSubscription(response.data.subscription);
      } catch (error) {
        console.error("Error fetching subscription:", error);
      }
    };

    fetchSubscription();
  }, []);
  const formatDate = (date: string) => {
    const d = new Date(date);
    const day = d.getDate().toString().padStart(2, "0");
    const month = (d.getMonth() + 1).toString().padStart(2, "0");
    const year = d.getFullYear().toString().slice(-2);
    return `${day}/${month}/${year}`;
  };
  return (
    <header className="flex sticky top-0 bg-background z-50 justify-between h-16 items-center gap-2  border-b px-4 ">
      <div className="flex items-center">
        <SidebarTrigger className="mx-2" />
       
      </div>
      <div className="flex items-center space-x-4 ">
        <div className="flex flex-col items-end">
          <span className="font-semibold text-sm">
            {t("btns.plan")}: <Badge>{currentSubscription?.plan}</Badge>
          </span>
          <span className="text-xs text-muted-foreground">
          {t("btns.expires")}: {formatDate(currentSubscription?.endDate)}
          </span>
        </div>
        <div className="flex gap-2 px-4">
        <LanguageToggle />
        <ThemeToggle />
        </div>
      </div>
    </header>
  );
}
