import React, { useContext, useEffect, useState } from "react";
import Page from "./template1/Page";
import { StoreContext } from "@/utils/StoreContext";
import LoaderCircle from "@/AppComponents/LoaderCircle";
import { Button } from "@/components/ui/button";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateStore } from "@/utils/StoreUtils";
import { toast } from "@/components/ui/use-toast";
import { queryClient } from "@/main";
import { Input } from "@/components/ui/input";
import TemplateThreeHomePage from "./hanoutTemplate/TemplateThreeHomePage";
import ResponsiveView from "./ResponsiveShowcase";
import ResponsiveShowcase from "./ResponsiveShowcase";
import EnhancedResponsiveShowcase from "./ResponsiveShowcase";
import ResponsivePreview from "./ResponsiveShowcase";

export default function Editor() {
  const { storeData: store } = useContext(StoreContext);
  const [Store, setStore] = useState({});
  const queryClient = useQueryClient();
  const [mainColor, setMainColor] = useState("blue");
  const [styling,setStyling]=useState({
    mainColor:'blue',
    font:"Cairo"
  })
  const [selectedSection,setselectedSection]=useState('')
  const updateStoreMutation = useMutation({
    mutationFn: () => updateStore(Store),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["store"] });
      toast({ title: "store data updated" });
    },
    onError: () => {
      updateStoreMutation.reset();
      toast({ title: "error,please try again" });
    },
  });
  useEffect(() => {
    if (store) {
      setStore(store);
    }
  }, [store]);
  if (!store) return <LoaderCircle />;
  return (
    <div className="w-full max-h-svh flex overflow-hidden flex-col bg-inherit container">
      <div className="flex relative">
        <div className="bg-white lg:w-4/5  overflow-y-scroll h-4/6">
          <TemplateThreeHomePage store={Store} styling={styling} selectedSection={selectedSection} setselectedSection={setselectedSection}/>
        </div>

        <div className="lg:w-1/5 p-2 hidden lg:flex ">
          <div className="space-y-2 ">
            <div className="space-y-2">
            {selectedSection=="store_ad_three"&&<Input
                value={Store?.storeAdThree}
                onChange={(e) => setStore({ ...Store, storeAdThree: e.target.value })}
              />}
             { selectedSection=="store_title"&&<Input
                value={Store?.title}
                onChange={(e) => setStore({ ...Store, title: e.target.value })}
              />}
             { selectedSection=="store_ad_one"&&<Input
                value={Store?.storeAdOne}
                onChange={(e) =>
                  setStore({ ...Store, storeAdOne: e.target.value })
                }
              />}
              {selectedSection&&<Input
              type="color"
              className="p-0"
                
                
                value={styling.mainColor}
                onChange={(e) => setStyling({...styling,mainColor:e.target.value})}
              />}
            </div>
            <div className=" ">
              {selectedSection&&<Button onClick={() => updateStoreMutation.mutate()}>
                {updateStoreMutation.isPending ? <LoaderCircle /> : "Save"}
              </Button>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
